import { useContext } from 'react';
import UserStore from './store';
import { axiosClient, FetchJSON } from 'utils/Fetch';
import { UserStateContext } from './context';


/**
 * @returns {UserStore}
 */
export function useUser(): UserStore {
  const user = useContext(UserStateContext);
  if (user === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return user;
}

export async function createUserFromContext(context) {
  if (context === undefined) {
    throw new Error('getUserFromContext must be used with a context');
  }

  const Cookies = eval("require('cookies')");
  const cookies = new Cookies(context.req, context.res);
  let ult = null;


  const forwarded = context.req.headers["x-forwarded-for"];
  const xff = forwarded ? forwarded : context.req.connection.remoteAddress;
  
  // set default axios headers
  axiosClient.defaults.headers.common['X-Forwarded-For'] = xff;

  // ULT in de header gaat voor op cookie
  // Let op, alle headers worden als lowercase doorgegeven!
  if(context.req.headers?.maauthorization)
  {
    ult = context.req.headers?.maauthorization.split(' ')[1];
    let options = {
      httpOnly: true,
      secure: true,
      domain: context.req.hostname,
    }

    cookies.set('ult', ult, options);
  }else
  {
    ult = cookies.get('ult') || null;
  } 

  const data = await getUserDataWithUlt(ult);
  const user = new UserStore(data);

  return user;
}

export async function createUserFromUlt(ult) {
  const data = await getUserDataWithUlt(ult);
  const user = new UserStore(data);

  return user;
}


async function getUserDataWithUlt(ult) {
  const meUrl = process.env.API_URL + "/member/me";
  const featuresUrl = process.env.API_URL + "/member/features";

  try
  {
    const token = await requestToken(ult);
    if(token) {
      const [meData, features] = await Promise.all([meUrl, featuresUrl].map(async url => {
        const [p] = FetchJSON(url, {token});
        const {data} = await p;

        return data;
      }));

      return {
        ...meData,
        isLoggedIn: true,
        features,
        ult,
        token,
      } as LoggedInUserDataInput;
    }else
    {
      const [p] = FetchJSON(featuresUrl);
      const {data} = await p;

      return {
        isLoggedIn: false,
        features: data,
      } as UserDataInput;
    }
  }
  catch(e)
  {
    return {
      isLoggedIn: false,
      features: {},
    } as UserDataInput;
  }
}


function requestToken(ult):Promise<Token> {
  const refreshEndpoint = process.env.API_URL + "/member/token";

  return new Promise((resolve) => {
    const [promise] = FetchJSON(refreshEndpoint, {
      method: "POST",
      data: {
        refreshToken: ult
      }
    });
    
    promise.then(({data}) => {
      resolve(data);
    })
    .catch(e => {
      resolve(undefined);
    })
  });
}