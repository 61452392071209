export const MB = 1024 * 1024;
export const GB = MB * 1024;
export const URL_INIT = `${process.env.API_URL}/mediaprocessor/init`
export const URL_STATUS = `${process.env.API_URL}/mediaprocessor/status`
export const MAX_CHUNKS = 10000; 
export const STALE_TIMEOUT = 1000 * 60; // 1 minute
export const STALE_RETRY_DELAY = 1000;
export const STALE_RETRIES = 5;
export const CHECK_PROCESS_INTERVAL = 1000;
export const CHUNCK_CONCURRENCY = 2;
export const QUEUE_CONCURRENCY = 2;