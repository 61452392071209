import { debounce } from 'throttle-debounce';

class OverscrollHandler
{
  constructor(el, config = {}) {
    this.el = el;
    this.config = config;

    this.nativeSupport = window.CSS && window.CSS.supports('overscroll-behavior', 'contain');
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onScrollEnd = debounce(10, this.onScrollEnd.bind(this));

    this.el.classList.add('overscroll-container');
    if(this.nativeSupport)
    {
      // Nieuwste Chrome, FF en Edge
      this.el.style.overscrollBehavior = 'contain';

      // Niet zomaar verwijderen, eerst testen wat dit doet!
      // Ook al is er nativeSupport dan betekent dit niet dat alles fris werkt
      // als er geen overscroll is bv dan wordt alsnog de onderliggende pagina gescrollt
      // bij een touchmove of wheel event
      this.el.addEventListener('touchmove', this.onTouchMove);
      this.el.addEventListener('wheel', this.onWheel);
    }else
    {
      this.el.addEventListener('mouseover', this.onHover);
      this.el.addEventListener('mouseleave', this.onLeave);

      this.el.addEventListener('touchstart', this.onTouchStart);
      this.el.addEventListener('touchmove', this.onTouchMove);
      this.el.addEventListener('scroll', this.onScroll);

      this.el.style.WebkitOverflowScrolling = 'auto';

      if(this.config.direction==='horizontal')
      {
        this.el.style.touchAction = 'none pan-left pan-right';
      }
    }
  }

  destroy() {
    this.el.removeEventListener('wheel', this.onWheel);
    this.el.removeEventListener('touchstart', this.onTouchStart);
    this.el.removeEventListener('touchmove', this.onTouchMove);
    this.el.removeEventListener('scroll', this.onScroll);
  }

  onHover = () => {
    if(this.hasWheelEvent)
      return;

    this.hasWheelEvent = true;
    this.el.addEventListener('wheel', this.onWheel);
  }
  onLeave = () => {
    this.hasWheelEvent = false;
    this.el.removeEventListener('wheel', this.onWheel);
  }

  onWheel = (e) => {
    if(this.nativeSupport)
    {
      e.stopPropagation();
      return;
    }

    this.startEvent = 'wheel';
    let element = this.el;

    if(this.config.allowHorizontalScroll || this.config.direction==='horizontal')
    {
      if(element.scrollLeft<=0 && e.deltaX<0)
      {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }else if(e.deltaX>0 && element.scrollWidth-element.scrollLeft === element.clientWidth)
      {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }

      e.stopPropagation();
      e.stopImmediatePropagation();

      return;
    }

    if(e.deltaY <= 0)
    {
      // Scroll up
      if(element.scrollTop === 0)
      {
        // Cancel event en prevent bubbeling when scroll is already at top
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }

      if(element.scrollHeight>element.clientHeight)
      {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    }else {
      // Scroll down
      if(element.scrollHeight - element.scrollTop === element.clientHeight)
      {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }

      if(element.scrollHeight>element.clientHeight)
      {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    }
  }

  onTouchStart(e) {
    this.startEvent = 'touch';
    let element = this.el;

    if(element.scrollTop<=0)
    {
      e.stopPropagation();
      //e.preventDefault();
      element.scrollTop = 1;
    }else if(element.scrollHeight - element.scrollTop <= element.clientHeight)
    {
      e.stopPropagation();
      //e.preventDefault();
      element.scrollTop = element.scrollHeight - element.clientHeight - 1;
    }
  }

  onTouchMove(e) {
    let element = this.el;
    if(element.scrollHeight===element.clientHeight && element.scrollWidth===element.clientWidth)
    {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onScroll(e) {
    if(this.startEvent!=='touch')
      return;

    this.onScrollEnd(e);
  }

  onScrollEnd() {
    let element = this.el;
    if(!element || this.config.direction==='horizontal')
      return;

    if(element.scrollTop===0)
    {
      element.scrollTop = 1;
    }else if(element.scrollHeight - element.scrollTop === element.clientHeight)
    {
      element.scrollTop = element.scrollHeight - element.clientHeight - 1;
    }
  }
}

export default OverscrollHandler;