import { useState, useEffect, useCallback, useRef } from "react";

// Get size from component
const getSize = ref =>
{
  if(!ref.current)
  {
    return {
      width: 0,
      height: 0
    };
  }

  return {
    width: ref.current.offsetWidth,
    height: ref.current.offsetHeight
  };
}

/**
 * Get size of a component
 * @param {React.Ref} ref 
 */
export default function useComponentSize(ref)
{
  const isMounted = useRef();
  const [size, setSize] = useState(getSize(ref));

  const onResize = useCallback(() =>
  {
    if(ref.current)
    {
      const frame = requestAnimationFrame(() => {
        if(isMounted.current)
          setSize(getSize(ref))
      });

      return () =>
      {
        cancelAnimationFrame(frame);
      }
    }
  }, [ref])

  useEffect(() => {
    isMounted.current = true;

    return function() {
      isMounted.current = false;
    }
  }, []);

  // Use effect when ref.current changes
  useEffect(() =>
  {
    // Ref not available yet
    if(!ref.current)
      return;
    const currentRef = ref.current;

    // Get initial size
    onResize();

    if(window.ResizeObserver)
    {
      let resizeObserver = new window.ResizeObserver(entries => {
        const entry = entries.shift();
        requestAnimationFrame(() =>
        {
          if(isMounted.current)
          {
            setSize({
              width: entry.contentRect.width,
              height: entry.contentRect.height
            });
          }
        })
      });
      resizeObserver.observe(currentRef);

      return () =>
      {
        resizeObserver.disconnect(currentRef);
        resizeObserver = null;
      }
    }
    else
    {
      window.addEventListener('resize', onResize);
      return () =>
      {
        window.removeEventListener('resize', onResize);
      }
    }
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  // Return size
  return size;
}