import { useState, useEffect } from 'react';
import { computed, observable, action, runInAction, reaction, makeObservable } from "mobx";
import JSCookies from 'js-cookie';
import isDev from 'utils/isDev';
import removeMd from 'remove-markdown';
const isServerSide = (typeof window === "undefined");

class TranslationStore
{
  language = null;
  translations = {};

  constructor() {
    makeObservable(this, {
      language: observable,
      setLanguage: action,
      hydrate: action,
    });
  }

  hydrate(language, translations)
  {
    if(!isServerSide && this.didHydrate)
      return;

    if(!translations || translations.length===0)
      return;

    this.didHydrate = true;
    this.language = language;
    this.addTranslations(language, translations);
  }

  addTranslations(language, translations)
  {
    this.translations[language] = translations;
  }

  setLanguage(language = "en")
  {
    if(this.language===language)
      return Promise.resolve(this.translations[this.language]);

    return new Promise(resolve =>
    {
      language = language.toLowerCase().substring(0, 2);
      // laad talen bij
      this.loadLanguage(language)
        .then(() => {
          runInAction(() => {
            this.language = language;
            resolve(this.translations[this.language]);
          });
        })
    });
  }

  loadLanguage(language) {
    return new Promise((resolve) => {
      if(isDev && language === "dev")
      {
        this.language = language;
        return;
      }

      let fileName = '';
      switch(language)
      {
        case 'nl':
          fileName = 'nl-NL';
        break;
        case "de":
          fileName = 'de-DE';
        break;
        case "es":
          fileName = 'es-ES';
        break;
        case 'fr':
          fileName = 'fr-FR';
        break;
        default:
          fileName = 'en-US';
        break;
      }

      import("../locale-data/"+fileName+".json").then(data => {
        const translations = data.default;
        this.addTranslations(language, translations);
        resolve();
      });
    });
  }

  getLanguage()
  {
    return this.language;
  }

  /**
   * @param {String} key - Statement key
   * @param {Object} [params] - Params used in the statement
   * @param {Object} [config] - Config
   * @param {Boolean} [config.markdown] - Wether to parse markdown, disabled by default
   * @param {Boolean} [config.style] - Style to be applied to the markdown test, only when markdown is enabled
   * 
   * @return {string}
  */
  translate(key, params = {}, config = {})
  {
    return computed(() =>
    {
      if (params !== undefined && typeof params.count !== "undefined")
      {
        params.count = parseInt(params.count, 10);
        key += params.count === 0 ? ".zero" : params.count === 1 ? ".one" : ".other";
      }

      let translated = this.getTranslation(key, this.language);
      if(translated === undefined)
      {
        if(isDev)
          translated = key;
        else
          translated = "";
      }

      if (params !== undefined)
      {
        for (var param in params)
        {
          translated = translated.replace(
            new RegExp("\\{\\{" + param + "\\}\\}", "g"),
            params[param]
          );
        }

        return this.parseMarkdown(translated, config);
      }
      else
        return this.parseMarkdown(translated, config);
    }, {
      name: 'translate',
      keepAlive: !isServerSide, //Never keep alive on server side, memory leaks
    }).get();
  }

  translationExists(key, language)
  {
    if(!language)
      language = this.language;

    let translation = this.translations[language];
    if(translation!==undefined && translation[key] !== undefined)
      return true;

    return false;
  }

  parseMarkdown(str, config)
  {
    if(config.markdown)
      return <MarkdownTranslation style={config.style} str={str} />
    return str;
  }

  getTranslation(key, language)
  {
    let translation = this.translations[language];
    if(translation!==undefined && translation[key] !== undefined)
      return translation[key];

    return key;
  }
}


const translationStore = new TranslationStore();
if(typeof window !== 'undefined' && isDev)
{
  window.translationStore = translationStore;
}

if(typeof window !== "undefined")
{
  reaction(
    () => translationStore.language,
    language =>
    {
      JSCookies.set("language2", language, {
        path: "/",
        expires: 365,
        secure: true,
        sameSite: 'Lax',
      });
    }
  );
}

function MarkdownTranslation(props) {
  const [html, setHtml] = useState(undefined);
  const str = props.str;

  const markdownStyle = {
    margin: 0,
    padding: 0,
    fontFamily: "Roboto Slab"
  }

  useEffect(() => {
    import("markdown-it").then(result => {
      const MarkdownIt = result.default;

      const markdownit = MarkdownIt('zero', {
        typographer: false,
      });

      markdownit.enable([
        'list',
        'link',
        'emphasis',
      ]);

      const html = markdownit.render(str);
      setHtml(html);
    });
  }, [props.str]);  // eslint-disable-line react-hooks/exhaustive-deps

  if(html) {
    return <div style={{...markdownStyle, ...props.style}} dangerouslySetInnerHTML={{__html: html}} />;
  }

  return removeMd(str);
}

export default translationStore;
