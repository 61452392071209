import { action, observable, reaction, runInAction, makeObservable } from "mobx";
const isSSR = (typeof window === "undefined")

const GB = 1073741824;

/**
 * @callback promiseCallback
 * @returns {Promise}
 */
export default class UploadStore
{
  STATE_ERROR = -1;
  STATE_WAITING = 0;
  STATE_IN_PROGRESS = 1;
  STATE_PROCESSING = 2
  STATE_COMPLETE = 3;

  user;

  /** @private */
  resolve;

  /** @private */
  reject;

  /**
   * @private
   */
  _cancelled = false;

  /**
   * @private
   */
  _paused = false;

  /**
   * @private
   */
  _onProgress;

  /**
   * @private
   */
  _onComplete;

  /**
   * @private
   */
  _onStatus;

  /**
   * @private
   */
  consecutiveFails = 0;

  /**
   * @private
   */
  maxConsecutiveFails = 5;

  /** @type {Set<UploadFile>} */
  files = new Set();

  /** @private */
  uploadUrl;

  /** @private */
  uploadParams = new Map();

  /**
   * @type {Set<UploadFile>}
   */
  skippedFiles = new Set();

  /** @private */
  _data = new Map();

  /** @private */
  _debugInfo = [];

  state = this.STATE_WAITING;

  constructor() {
    makeObservable(this, {
      state: observable,
      files: observable,
      addFiles: action,
      start: action,
      cancel: action,
      clear: action,
    });

    if(!isSSR)
    {
      reaction(
        () => this.state,
        () => {
          // https://web.dev/bfcache/
          // unload events beinvloeden back/forward cache, dus alleen dit event toevoegen
          // als het ook echt nodig is
          if(this.state === this.STATE_IN_PROGRESS || this.state === this.STATE_ERROR)
          {
            window.addEventListener('beforeunload', this.watchForPageLeave, {capture: true});
          }else
          {
            window.removeEventListener('beforeunload', this.watchForPageLeave, {capture: true});
          }
        }
      )
    }
  }

  watchForPageLeave = (e) => {
    // Notify when leaving while upload (with error) is active
    if(this.state === this.STATE_IN_PROGRESS || this.state === this.STATE_ERROR)
    {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  }

  setUser(user)
  {
    this.user = user;
  }

  /**
   * Set the url to upload to
   * @param {String} url
   */
  setUrl(url)
  {
    this.uploadUrl = url;
  }

  /**
   * Add files to the queue
   * @param {FileList} files
   */
  addFiles(files)
  {
    if(this.state === this.STATE_WAITING)
    {
      Array.from(files).forEach(file => this.files.add(new UploadFile(file)));
    }else
      throw new Error(`UploadStore is not ready, current state: ${this.state}`);
  }

  /**
   * Set a post param. Currently only supports 'uploadkey'
   * @param {'uploadkey'} key
   * @param {String} value
   */
  addParam(key, value)
  {
    this.uploadParams.set(key, value);
  }

  /**
   * Get a post param. Currently only supports 'uploadkey'
   * @param {'uploadkey'} key
   * @return {String}
   */
  getParam(key)
  {
    return this.uploadParams.get(key);
  }

  /**
   * Return if the upload is still active
   * @returns {Boolean}
   */
  isActive()
  {
    return ((this.state === this.STATE_IN_PROGRESS) || (this.state === this.STATE_PROCESSING));
  }

  /**
   * Return if the upload has completed
   * @returns {Boolean}
   */
  isCompleted()
  {
    return ((this.state === this.STATE_COMPLETE) || (this.state === this.STATE_ERROR));
  }

  /**
   * Start the upload. Returns a promise that resolves when completed and rejects on any error.
   * @returns {Promise}
   */
  start()
  {
    this._paused = false;
    this._cancelled = false;
    this.setState(this.STATE_IN_PROGRESS);

    return new Promise((resolve, reject) =>
    {
      if(!this.user)
        return reject("User not set");
      if(this.files.length < 1)
        return reject("No files selected");

      new Promise((startResolve, startReject) =>
      {
        this.resolve = startResolve;
        this.reject = startReject;

        this.initUpload()
        .then(() =>
        {
          if(!this._cancelled)
            this.uploadNextFile();
        })
        .catch(startReject);
      })
      .then(() =>
      {
        if(!this._cancelled && !this._paused)
        {
          runInAction(() =>
          {
            if(!this._cancelled)
              this.setState(this.STATE_PROCESSING);
          });

          if(this.skippedFiles.size === this.files.size) {
            reject({type: "ALL_SKIPPED"});
            return;
          }

          this.uploadComplete().then(() => {
            this.uploadStatus().then(() => {
              runInAction(() =>
              {
                if(!this._cancelled)
                  this.setState(this.STATE_COMPLETE);
              });

              if(!this._cancelled)
                resolve();
            })
            .catch(reject);
          })
          .catch(reject);
        }
      })
      .catch((e) =>
      {
        if(!this._cancelled)
        {
          this.cancel();
          this.setState(this.STATE_ERROR);
          reject(e);
        }
      });
    })
  }

  pause()
  {
    this._paused = true;
  }

  resume()
  {
    this._paused = false;
    this.uploadNextFile();
  }

  /**
   * Cancels the upload, cancels all files, resets all callback functions and resets the state to the initial state (STATE_WAITING)
   */
  cancel()
  {
    this._cancelled = true;
    this.files.forEach(file => file.cancel());
    this.clear();
  }

  /**
   * Clears the files and resets the state to the initial state (STATE_WAITING)
   */
  clear()
  {
    this.files.clear();
    this.onInitUpload(null);
    this.onProgress(null);
    this.onComplete(null);
    this.onStatus(null);

    this.consecutiveFails = 0;
    this.skippedFiles.clear();
    this.uploadParams.clear();
    this.setState(this.STATE_WAITING);
  }

  /**
   * Set data for later use
   * @param {string} key
   * @param {any} value
   */
  setData(key, value)
  {
    this._data.set(key, value);
  }

  /**
   * Returns the data when set otherwise returns undefined
   * @param {string} key
   */
  getData(key)
  {
    return this._data.get(key);
  }

  /**
   * Set the onInitUpload callback
   * @param {promiseCallback} callback
   */
  onInitUpload(callback)
  {
    this._onInitUpload = callback;
  }

  /**
   * Set the onProgress callback
   * @param {Function} callback
   */
  onProgress(callback)
  {
    this._onProgress = callback;
  }

  /**
   * Set the onComplete callback
   * @param {promiseCallback} callback
   */
  onComplete(callback)
  {
    this._onComplete = callback;
  }

  /**
   * Set the onComplete callback
   * @param {promiseCallback} callback
   */
  onStatus(callback)
  {
    this._onStatus = callback;
  }

  /**
   * Emits a propgress event when set
   * @private
   */
  emitProgress()
  {
    if(this._onProgress)
      this._onProgress();
  }

  /**
   * Set the upload state
   * @private
   */
  setState(state)
  {
    runInAction(() => {
      this.state = state;
    })
  }

  /**
   * Triggers the initUpload callback when set
   * @private
   */
  initUpload()
  {
    return new Promise((resolve, reject) =>
    {
      if(this._onInitUpload)
        this._onInitUpload().then(resolve).catch(reject);
      else
        resolve();
    });
  }

  /**
   * Triggers the next file to upload. When there are no more remaining files to upload will resolve the 'start' promise
   * @private
   */
  uploadNextFile()
  {
    if(this._paused)
      return;

    const file = Array.from(this.files).find(file => file.state === this.STATE_WAITING);
    if(file)
    {
      const size = file.getSize();
      if(size > 1 * GB) {
        file.skip();
        this.skippedFiles.add(file);
        this.uploadNextFile();

        return;
      }

      file.setParams(this.uploadParams);

      file.onProgress(() =>
      {
        this.emitProgress();
      });

      file.onRefreshToken((callback) =>
      {
        this.user.refreshToken()
        .then(callback)
        .catch(() =>
        {
          this.cancel();
          this.setState(this.STATE_ERROR);
          this.reject({error: "Unauthorized"});
        });
      });

      file.onRetry(e => {
        this._debugInfo.push({
          file: file.file,
          time: new Date(),
          state: "FILE_RETRY",
          data: e
        });
      });

      this._debugInfo.push({
        file: file.file,
        time: new Date(),
        state: "UPLOAD_START"
      });

      file.upload(this.uploadUrl, this.user)
      .then(() =>
      {
        this._debugInfo.push({
          file: file.file,
          time: new Date(),
          state: "UPLOAD_COMPLETE"
        });

        this.consecutiveFails = 0;
        this.uploadNextFile();
      })
      .catch((e) =>
      {
        if(!this._cancelled)
        {
          if(this.consecutiveFails < this.maxConsecutiveFails)
          {
            this._debugInfo.push({
              file: file.file,
              time: new Date(),
              state: "UPLOAD_SKIP",
              data: e
            });

            file.skip();

            this.consecutiveFails++;
            this.skippedFiles.add(file);

            this.uploadNextFile();
          } else {
            this._debugInfo.push({
              file: file.file,
              time: new Date(),
              state: "UPLOAD_ERROR",
              data: e
            });

            this.reject({...e, ...{type: "consecutivefails"}}, file);
          }
        }
      })
    }
    else
    {
      this.resolve();
    }
  }

  /**
   * Trigger the onComplete callback when set
   * @private
   */
  uploadComplete()
  {
    return new Promise((resolve, reject) =>
    {
      if(this._onComplete)
        this._onComplete().then(resolve).catch(reject);
      else
        resolve();
    });
  }

  /**
   * Trigger the onStatus callback when set
   * @private
   */
  uploadStatus()
  {
    return new Promise((resolve, reject) => {
      if(this._onStatus)
        this._onStatus().then(resolve).catch(reject);
      else
        resolve();
    });
  }

  /**
   * Get the current progress
   * @returns {{files: Number, complete: Number, percentage: Number}}
   */
  get progress()
  {
    let bytesTotal = 0;
    let bytesLoaded = 0;

    let complete = 0;

    this.files.forEach(file =>
    {
      switch(file.state)
      {
        case file.STATE_ERROR: // Mark same as complete for progress
        case file.STATE_SKIPPED:
        case file.STATE_COMPLETE:
          complete++;
          bytesTotal += file.getSize();
          bytesLoaded += file.getSize();
        break;
        case file.STATE_WAITING:
          bytesTotal += file.getSize();
        break;
        case file.STATE_IN_PROGRESS:
          bytesTotal += file.getSize();
          bytesLoaded += file.loaded;
        break;
        default:
          console.warn("Invalid upload file state", file.state, file)
      }
    });

    return {
      files: this.files.size,
      complete,
      percentage: (bytesLoaded > 0) ? (bytesLoaded / bytesTotal * 100) : 0
    };
  }

  get debug() {
    return JSON.stringify(this._debugInfo);
  }
}

class UploadFile
{
  STATE_SKIPPED = -2;
  STATE_ERROR = -1;
  STATE_WAITING = 0;
  STATE_IN_PROGRESS = 1;
  STATE_COMPLETE = 2;

  /**
   * @type {File}
   */
  file;

  /**
   * @private
   * @type {Function}
   */
  _onProgress;

  /**
   * @private
   * @type {Function}
   */
  _onRefreshToken;

  /**
   * @private
   * @type {Function}
   */
  _onRetry;

  /**
   * @private
   * @type {AbortController}
   */
  _abortController = new AbortController();

  /**
   * @type {number}
   */
  state = this.STATE_WAITING;

  /**
   * @type {number}
   */
  loaded = 0;

  /**
   * @type {number}
   */
  total;

  /**
   * @type {Map<String, String>}
   */
  params = new Map();

  /**
   * Create an `UploadFile` from a file
   * @param {File} file
   */
  constructor(file)
  {
    this.file = file;
  }

  /***
   *  Calculate the body size when total size is not set from a progress event
   * @returns {Number}
   */
  getSize()
  {
    if(this.total)
      return this.total;

    // Try to calculate the size as close as possible. Doesn't need to be precise, as it will be overriden on the first progress event, but this prevents a jumpy progressbar
    const unknownLength = 12; // Some random value?
    const uploadKeyKeyLength = "uploadkey".length; // FormData 'uploadkey' key length
    const uploadKeyValueLength = 10; // FormData 'uploadkey' value length
    const uploadFileKeyLength = "Filedata".length;
    const fieldFileLength = 73 + uploadFileKeyLength + this.file.name.length + this.file.type.length; // Upload file FormData value
    const fieldKeyLength = 42 + uploadKeyKeyLength + uploadKeyValueLength; // uploadKey FormData
    const boundaryLength = 40; // Boundary length, different per browser? (webkit uses a length of 40)

    return boundaryLength + fieldFileLength + boundaryLength + fieldKeyLength + boundaryLength + this.file.size + unknownLength;
  }

  upload(url, user)
  {
    this.state = this.STATE_IN_PROGRESS;

    return new Promise((resolve, reject) =>
    {
      runInAction(() => {
        const upload = new UploadRequest(url, this);
        upload.setParams(this.params);
        upload.setAuthorizationHeader(`Bearer ${user.token.token}`);
        upload.onRefreshToken(this._onRefreshToken);
  
        upload.onProgress((bytesLoaded, bytesTotal) =>
        {
          this.loaded = bytesLoaded;
          this.total = bytesTotal;
          this.emitProgress();
        });

        upload.onRetry(e => {
          this.emitRetry(e);
        });
  
        upload.upload(url)
        .then(() =>
        {
          this.state = this.STATE_COMPLETE;
          resolve();
        })
        .catch(reject);
  
        this._abortController.signal.addEventListener("abort", () =>
        {
          this.state = this.STATE_ERROR;
          upload.cancel();
        });
      });
    });
  }

  /**
   * Cancel the upload, also cancels the `UploadRequest`
   */
  cancel()
  {
    this._abortController.abort();
  }

  /**
   * Skip this file
   */
  skip()
  {
    this.state = this.STATE_SKIPPED;
  }

  /**
   * Set post params
   * @param {Map<String, String>} params
   */
  setParams(params)
  {
    this.params = params;
  }

  /**
   * Add a progress event callback
   * @param {Function} callback
   */
  onProgress(callback)
  {
    this._onProgress = callback;
  }

  /**
   * Add a refresh token event callback
   * @param {Function} callback
   */
  onRefreshToken(callback)
  {
    this._onRefreshToken = callback;
  }

  /**
   * Add a retry event callback
   * @param {Function} callback
   */
  onRetry(callback) {
    this._onRetry = callback;
  }

  /**
   * Emit a progress event to the `UploadStore`
   * @private
   */
  emitProgress()
  {
    if(this._onProgress)
      this._onProgress();
  }

  emitRetry(e) {
    if(this._onRetry) {
      this._onRetry(e);
    }
  }
}

class UploadRequest
{

  /**
   * @private
   */
  currentAttempt = 1;

  /**
   * @private
   */
  maxAttempts = 10;

  /**
   * @private
   */
  retryDelay = 6000;

  /**
   * @private
   */
  staleTimeout = 60000;

  /**
   * @private
   */
  _staleTimer;

  /**
   * @private
   */
   _retryTimer;

  /**
   * @private
   * @type {String}
   */
  uploadUrl;

  /**
   * @private
   * @type {String}
   */
  authorizationHeader;

  /**
   * @private
   * @type {UploadFile}
   */
  uploadFile;

  /**
   * @private
   * @type {Number}
   */
  _total;

  /**
   * @private
   * @type {Number}
   */
  _loaded;

  /**
   * @private
   * @type {Function}
   */
  _onProgress;

  /**
   * @private
   * @type {Function}
   */
  _onRefreshToken;

  /**
   * @private
   * @type {Function}
   */
  _onRetry;

  /**
   * @private
   */
  _cancelled = false;

  /**
   * @private
   * @type {XMLHttpRequest}
   */
  _xhr = new XMLHttpRequest();

  /**
   * @private
   */
  resolve;

  /**
   * @private
   */
  reject;

  /**
   * @private
   * @type {Map<String, String>}
   */
  params = new Map();

  /**
   * Create a new UploadRequiest
   * @param {URL} url
   * @param {UploadFile} file
   */
  constructor(url, file)
  {
    this.uploadFile = file;
    this.uploadUrl = url;
  }

  /**
   * Set the authorization header
   * @param {String} authorizationHeader
   */
  setAuthorizationHeader(authorizationHeader)
  {
    this.authorizationHeader = authorizationHeader;
  }

  /**
   * Set post params
   * @param {Map<String, String>} params
   */
  setParams(params)
  {
    this.params = params;
  }

  /**
   * Create an upload promise, that will resolve when the request is complete, and rejects on an error (after retries)
   * @return {Promise}
   */
  upload()
  {
    return new Promise((resolve, reject) =>
    {
      this.resolve = resolve;
      this.reject = reject;

      this._upload();
    });
  }

  /**
   * Cancel the upload, also clears the progress event handler, stops the stale timer and aborts the request
   */
  cancel()
  {
    this._cancelled = true;
    this.cancelStaleTimer();
    this.cancelRetryTimer();
    this.onProgress(null);
    this._xhr.abort();
  }

  /**
   * Callback progress event
   * @param {Function} callback
   */
  onProgress(callback)
  {
    this._onProgress = callback;
  }

  /**
   * Callback refresh token event
   * @param {Function} callback
   */
  onRefreshToken(callback)
  {
    this._onRefreshToken = callback;
  }

  onRetry(callback) {
    this._onRetry = callback;
  }

  /**
   * 
   */
  isSkipped()
  {
    return this.uploadFile.state === this.uploadFile.STATE_SKIPPED;
  }

  /**
   * Does the real uploading
   * @private
   */
  _upload()
  {
    if(this.isSkipped())
      return;

    this.removeAllEventListeners();

    this._xhr.open("POST", this.uploadUrl);
    this._xhr.timeout = 0;

    if(this.authorizationHeader)
      this._xhr.setRequestHeader("Authorization", this.authorizationHeader);

    this._xhr.upload.addEventListener("progress", this._onProgressEvent);
    this._xhr.addEventListener("loadend", this._onLoadEndEvent);
    this._xhr.addEventListener("timeout", this._onTimeoutEvent);
    this._xhr.addEventListener("error", this._onErrorEvent);

    const file = new FormData();
    file.append('Filedata', this.uploadFile.file);
    this.params.forEach((value, key) =>
    {
      file.append(key, value);
    })

    this.startStaleTimer();
    this._xhr.send(file);
  }

  removeAllEventListeners()
  {
    this._xhr.upload.removeEventListener("progress", this._onProgressEvent);
    this._xhr.removeEventListener("loadend", this._onLoadEndEvent);
    this._xhr.removeEventListener("timeout", this._onTimeoutEvent);
    this._xhr.removeEventListener("error", this._onErrorEvent);
  }

  _onProgressEvent = event =>
  {
    this._total = event.total;
    this._loaded = event.loaded;

    this.cancelStaleTimer();
    if(event.loaded < event.total)
      this.startStaleTimer();

    this.emitProgress();
  }

  _onTimeoutEvent = event =>
  {
    this.retry(event);
  }

  _onErrorEvent = event =>
  {
    this.retry(event);
  }

  _onLoadEndEvent = () =>
  {
    this.cancelStaleTimer();

    if(this._xhr.readyState === this._xhr.DONE)
    {
      if(this._xhr.status === 200)
      {
        this._loaded = this.uploadFile.getSize();
        this.emitProgress();

        this.resolve();
      }
      else if (this._xhr.status > 0 && !this._cancelled) // Only trigger a retry when a status code is present. Other events should be handled by stale, error and timeout
      {
        // Pass a fake event object here, because the real event has not the data we need. So we fetch it from the XHR request
        this.retry({type:"loadend", target: {status: this._xhr.status}});
      }
    }
  }

  /**
   * Try to retry the request based on the incoming event
   */
  retry(event)
  {
    this._xhr.abort();
    this.cancelStaleTimer();
    this.removeAllEventListeners();

    let refreshToken = false;
    let shouldRetry = (this.currentAttempt <= this.maxAttempts); // Retry when below max attempts
    if(event.hasOwnProperty("target") && event.target.hasOwnProperty("status"))
    {
      shouldRetry = shouldRetry && event.target.status >= 500 && event.target.status <= 599; // Retry 500-599 status codes

      if(event.target.status === 401)
      {
        shouldRetry = true;
        refreshToken = true;
      }
    }

    console.debug(`[Upload] Retry: ${shouldRetry} reason: ${event.type} delay: ${this.retryDelay}`);

    if(this._onRetry) {
      this._onRetry({shouldRetry, refreshToken, type: event.type, delay: this.retryDelay, attempt: this.currentAttempt, maxAttempts: this.maxAttempts});
    }

    if(!shouldRetry)
    {
      this.cancel();
      this.reject(event, this.uploadFile);
    }
    else if (shouldRetry && refreshToken)
    {
      this._onRefreshToken(refreshResult =>
      {
        this.setAuthorizationHeader(`Bearer ${refreshResult.token}`);
        this._retry();
      });
    }
    else if(shouldRetry)
    {
      this._retry();
    }
  }

  _retry()
  {
    this.cancelRetryTimer();

    this._retryTimer = setTimeout(() =>
    {
      console.debug(`[Upload] Retry attempt ${this.currentAttempt}/${this.maxAttempts}`);

      this.currentAttempt++;
      this._loaded = 0;
      this.emitProgress();
      this._upload();
    }, this.retryDelay);
  }

  cancelRetryTimer()
  {
    if(this._retryTimer)
      clearTimeout(this._retryTimer);
  }

  /**
   * Emit a progress event to the `UploadFile`
   * @private
   */
  emitProgress()
  {
    if(this._onProgress)
      this._onProgress(this._loaded, this._total);
  }

  /**
   * Cancels the stale timer
   * @private
   */
  cancelStaleTimer()
  {
    if(this._staleTimer)
      clearTimeout(this._staleTimer)
  }

  /**
   * Stale upload detection, retries when timer ends. Timer is reset after each progress event
   * @private
   */
  startStaleTimer()
  {
    this._staleTimer = setTimeout(() =>
    {
      this.retry({type: "stale"});
    }, this.staleTimeout);
  }
}