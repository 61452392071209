import { UploadErrorState } from "./state";

export default class UploadError extends Error {
  error: UploadErrorState;

  constructor(error: UploadErrorState) {
    super(error);

    this.error = error;
    this.name = "UploadError";
  }
}