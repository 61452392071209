import convert from 'color-convert';

export default class Color
{
  /**
   * 
   * @param {String} hexColor 
   */
  static isDark(color) {
    if(!color) {
      return false;
    }

    color = color[0]==='#' ? color.substring(1) : color;
    let rgba = Array.isArray(color) ? color : Color.hexToRgba(color);
    let brightness = (rgba[0]*299 + rgba[1]*587 + rgba[2]*114)/1000;
    let isDark = (brightness<200);
    return isDark;
  }

  /**
   * 
   * @param {String} hexColor 
   */
  static hexToRgba(hexColor) {
    if(!hexColor)
      return [0,0,0, 1];
    hexColor = hexColor.replace("#", "");
    if(hexColor.length != 6)
      return [0,0,0, 1];

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
      1
    ];
  }

  static toRgba(color) {
    if(typeof color === "string") {
      if(color.startsWith("rgb")) {
        return color.match(/([0-9]{1,3})/ig).map(i => parseInt(i, 10));
      } else {
        return this.hexToRgba(color);
      }
    } else {
      return color;
    }
  }

  static rgbToHex(color)
  {
    return convert.rgb.hex(color);
  }

  static hslToRgb(color)
  {
    return convert.hsl.rgb(color);
  }

  static rgbToHsl(color)
  {
    return convert.rgb.hsl(color);
  }

  // Blends forecolor with alpha over backgroundColor
  static blend(foregroundColor, alpha, backgroundColor)
  {
    var red = foregroundColor[0];
    var green = foregroundColor[1];
    var blue = foregroundColor[2];

    var red2 = backgroundColor[0];
    var green2 = backgroundColor[1];
    var blue2 = backgroundColor[2];

    var blend = [
      Math.round(red2 + (red-red2) * alpha),
      Math.round(green2 + (green-green2) * alpha),
      Math.round(blue2 + (blue-blue2) * alpha)
    ];

    return blend;
  }
}