import { useRef } from "react";
import { UserStateContext } from "./context";
import UserStore from "./store";

export function UserProvider({user, children }) {
  const store = useRef(new UserStore(user)).current;

  return (
    <UserStateContext.Provider value={store}>
      {children}
    </UserStateContext.Provider>
  );
}