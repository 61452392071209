import { useEffect, useState } from "react"

class Store {
  stack = {};

  add(type, onFocusChange) {
    if(this.stack[type]===undefined)
      this.stack[type] = [];

    const stack = this.stack[type];
    if(stack.length>0)
      stack[stack.length-1].setFocus(false);

    const child = new StoreChild(this, type, onFocusChange);
    stack.push(child);
    onFocusChange(true);

    return child;
  }

  remove(ref) {
    this.stack[ref.type] = this.stack[ref.type].filter(item => ref!==item);

    if(this.stack[ref.type].length>0)
      this.stack[ref.type][this.stack[ref.type].length-1].setFocus(true);
  }
}

class StoreChild {
  constructor(store, type, onFocusChange) {
    this.store = store;
    this.type = type;
    this.onFocusChange = onFocusChange;
  }

  destroy() {
    this.store.remove(this);
  }

  setFocus(state) {
    this.onFocusChange(state);
  }
}

const store = new Store();


export default function useIsFrontMost(type='ui') {
  const [isFrontMost, setIsFrontMost] = useState(true);

  useEffect(() => {
    const me = store.add(type, (hasFocus) => {
      setIsFrontMost(hasFocus);    
    });

    return () => {
      me.destroy();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isFrontMost
}

