import { createContext, useContext } from 'react';
import AppStore from "./store";

const isSSR = (typeof window === "undefined")
const AppStateContext = createContext();

// Alleen op client een rootStore aanmaken
// op server maken we bij elk request een nieuwe store aan
const rootStore = !isSSR ? new AppStore() : null;

export function AppProvider({ children })
{
  const store = rootStore ? rootStore : new AppStore();

  return (
    <AppStateContext.Provider value={store}>
      {children}
    </AppStateContext.Provider>
  );
}

/**
 * @returns {AppStore}
 */
export function useApp() {
  const app = useContext(AppStateContext);
  if (app === undefined) {
    throw new Error('useApp must be used within a AppContextProvider');
  }

  return app;
}