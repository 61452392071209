class Events {
  constructor(root)
  {
    root._ons = {};
    root.on = function(eventName, callback, once)
    {
      if(once===undefined)
        once = false;

      let eventData = eventName.split(".");
      let type = eventData[0];
      let uid = eventData[1];

      if(this._ons[type]===undefined)
        this._ons[type] = [];

      this._ons[type].push({callback: callback, uid: uid, once: once});
    }

    root.one = function(type, callback)
    {
      this.on(type, callback, true);
    }

    root.off = function(eventName, callback)
    {
      let eventData = eventName.split(".");
      let type = eventData[0];
      let uid = eventData[1];

      if(this._ons[type]===undefined || this._ons[type].length===0)
        return;

      if(callback===undefined && uid===undefined)
      {
        this._ons[type] = [];
      }else
      {
        let i = 0;
        while(this._ons[type][i])
        {
          if((callback===undefined || (callback && this._ons[type][i].callback === callback)) && (uid===undefined || (uid && this._ons[type][i].uid === uid)))
          {
            this._ons[type].splice(i, 1);

            i--;
            if(i<0)
              i = 0;
          }

          i++;
        }
      }
    }

    root.trigger = function(type)
    {
      if(this._ons[type]===undefined || this._ons[type].length===0)
        return;

      let myarguments = [];
      for(let i=1; i<arguments.length; i++)
        myarguments.push(arguments[i]);

      let max = this._ons[type].length;
      for(let i=0; i<max; i++)
      {
        let listener = this._ons[type][i];
        if(listener && listener.hasOwnProperty('callback'))
          listener.callback.apply(null, myarguments);

        if(listener && listener.once)
        {
          this._ons[type].splice(i, 1);
          if(i < max-1)
            i--;
        }
      }
    }
  }

}

export default Events;