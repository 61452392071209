import axios from "axios";
import axiosRetry from 'axios-retry';
const https = require('https');
const http = require('http');

const isSSR = (typeof window === 'undefined');
axiosRetry(axios, { retries: 3 });


let httpsAgent;
let httpAgent;
if (isSSR) {
  httpAgent = new http.Agent({
    keepAlive: true,
    keepAliveMsecs: 1000,
    maxSockets: 50,
  });
  httpsAgent = new https.Agent({
    keepAlive: true,
    keepAliveMsecs: 1000,
    maxSockets: 50,
  });
}

const axiosClient = axios.create({
  httpAgent,
  httpsAgent,
});
axiosRetry(axiosClient, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

type FetchReturnValue = [
  Promise<any>,
  () => void,
]

function FetchJSON(url, _config?:any): FetchReturnValue {
  const config = {
    autoRefreshToken: true,
    ..._config,
  }
  const controller = config.abortController ? config.abortController : new AbortController();
  const promise = new Promise((resolve, reject) => {

    const headers = {...config.headers};
    if(config.token)
      headers['Authorization'] = `Bearer ${config.token.token}`;
    
    const signal = config.signal ? config.signal : controller.signal;
    const req = axiosClient(url, {
      signal,
      method: config.method,
      data: config.data,
      withCredentials: config.withCredentials,
      headers,
    });

    req.then(response => {
      // @ts-ignore
      response.statusCode = response.status;
      resolve(response);
    })
    .catch(e => {
      if(e?.response?.status===401 && config.onTokenExpired)
      {
        config.onTokenExpired()
        .then((token) => {
          const [promise] = FetchJSON(url, {
            ...config,
            autoRefreshToken: false,
            token: token,
            signal,
          });
          promise.then(response => {
            resolve(response);
          })
          .catch(e => {
            reject(e);
          })

        })
        .catch(e => {
          reject(e);
        });
      }else
      {
        if(e.constructor.name === 'AbortError' || e.constructor.name === 'CanceledError')
        {
          // ignore aborts and cancels
          return;
        }

        reject(e);
      }
    });
    
  });

  return [promise, () => {
    controller.abort();
  }];
}

export {
  axiosClient,
  FetchJSON,
};