import { DefaultSeo } from "next-seo";
import translationStore from "stores/translation-store";

function Seo() {
  return (
    <DefaultSeo
      title="MyAlbum"
      description={translationStore.translate("base.meta-description")}
      openGraph={{
        type: 'website',
        siteName: translationStore.translate('album.meta.og.site-name'),
      }}
      facebook={{
        appId: process.env.SHARE_FACEBOOKAPPID,
      }}
      twitter={{
        site: "@JoinMyAlbum",
        cardType: 'summary_large_image',
      }}
    />
  )
}

export default Seo;