import Modal from "./Modal.js"
import ButtonModal from "./ButtonModal"

function ButtonPrompt(options) {

  return new Promise((resolve, reject) =>
  {
    const modal = <ButtonModal
      stack={true}
      allowClose={false}
      onCancel={reject}
      onClose={() => {
        Modal.remove(modal);
        resolve();
      }}
      modalStyle={{width: 500}}
      {...options}
    />;

    Modal.add(modal);
  });
}

export default ButtonPrompt;