import { Observer } from "mobx-react";
import ThemeStore from "components/UI";
import isDev from "utils/isDev";

export default function MyStyles()
{
  return (
    <Observer>
    {
      () => {
        const styles = [];
        Object.entries(ThemeStore.theme).forEach(([key, value]) =>
        {
          if((Array.isArray(value) && (value.length === 3 || value.length === 4)) || (typeof value === 'number'))
            styles.push(`--${key}: ${value};`);
          else if (isDev)
            console.warn(`Invalid theme color '${key}' with value ${value} (${typeof value}), expected rgb array of length 3 or number`);
        });

        return (
          <style global jsx>{`
          :root
          {
            ${styles.sort().join("")}

            --font-size-1: 14px;
            --font-size-2: 17px;
            --font-size-3: 19px;
            --font-size-4: 21px;
          }

          @media (max-width: 1024px) {
            :root {
              --font-size-2: 15px;
              --font-size-3: 17px;
              --font-size-4: 19px;
            }
          }
          `}</style>
        );
      }
    }
    </Observer>
  );
}