import { forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";

import cssstyle, { icon } from "./style";

const Select = forwardRef(({label, children, style, error, ...rest}, forwardedRef) =>
{
  const [hasFocus, setFocus] = useState(false);

  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  const classes = [
    "ui-select",
    hasFocus ? "active" : "",
    error ? "error" : ""
  ]

  return (
    <div className={classes.join(" ")} style={{...{marginTop: 2}, ...style}}>
      {label && <label>{label}</label>}
      <select
        ref={forwardedRef}
        style={{maxWidth: "inherit"}}
        onFocus={onFocus}
        onBlur={onBlur}
        onBlurCapture={onBlur}
        {...rest}
      >
        {children}
      </select>
      <div className="ui-select-arrow">
        <FontAwesomeIcon className={["ui-select-icon", icon.className].join(" ")} icon={faCaretDown} />
      </div>
      <style jsx>{cssstyle}</style>
      {icon.styles}
    </div>
  );
});
Select.displayName = "Select";

export default Select;