import { action, observable, makeObservable } from "mobx";
import isDev from "utils/isDev";

class ThemeStore
{
  name = "light"
  theme = {}

  constructor()
  {
    makeObservable(this, {
      theme: observable,
      setTheme: action,
    });

    this.setTheme('light');
  }

  setTheme(theme)
  {
    if(typeof theme === "string")
    {
      this.name = theme;
      switch(theme)
      {
        case 'light':
          theme = {
            backgroundColor: [255, 255, 255],
            backgroundContrastColor: [0, 0, 0],
            overlayColor: [113, 83, 138],
            overlayOpacity: 0.05,
            textColor: [0, 0, 0],
            errorColor: [255, 0, 28],
            warningColor: [255, 190, 0],
            notificationColor: [254, 237, 197],
            successColor: [28, 190, 0],
            okColor: [9, 220, 94],
            accentColor: [136, 0, 254],
            accentColorDark: [113, 83, 138],
            accentColorMedium: [196, 174, 214],
            accentColorLight: [241, 224, 255],
            accentColorLightest: [250, 245, 255],
            accentColorDarkContrastColor: [255, 255, 255],
            redColor: [238,62,114],
            greenColor: [9,145,48],
            greenColorDark: [5, 79, 26],

            scrollBarBg: [0, 0, 0, 0.15],
            scrollBarHoverBg: [0, 0, 0, 0.35],
            scrollBarActiveBg: [0,0,0, 0.6],

            menuBg: [255,255,255],
            menuColor: [113, 83, 138],
            menuHoverBg: [136, 0, 254],
            menuHoverColor: [255,255,255],

            toggleBg: [250, 245, 255],
            toggleLine: [196, 174, 214],
            toggleActive: [136, 0, 254],
            toggleKnob: [255,255,255],

            zoomBg: [255,255,255],
            zoomColor: [113, 83, 138],
            zoomHoverBg: [113, 83, 138],
            zoomHoverColor: [255,255,255],

            modalCloseBg: [250, 245, 255],
            modalCloseColor: [113, 83, 138],

            tooltipBg: [28,28,28],
            tooltipColor: [228,228,228],
            tooltipTextOpacity: 1,

            toastBg: [28,28,28],
            toastColor: [228,228,228],

            iconBtnActiveBg: [136, 0, 254],
            iconBtnActiveColor: [255,255,255],

            inputBg: [255,255,255],
            inputColor: [0,0,0],
            inputBorder: [208,196,219],
            inputActiveBorder: [136, 0, 254],

            inputTagBg: [0,0,0],
            inputTagColor: [255,255,255],
            inputTagActiveBg: [113, 83, 138],
            inputTagActiveColor: [255,255,255],

            buttonBg: [255,255,255],
            buttonColor: [0,0,0],
            buttonBorder: [196, 174, 214],
            buttonActiveBg: [113, 83, 138],
            buttonActiveColor: [255,255,255],

            pane1: [255,255,255],
            pane2: [240,240,240],
            color1: [82,82,82],
            paneColor: [113,83,138],
            border1: [241, 224, 255],

            modalTitleColor: [113, 83, 138],

            loaderColor: [136, 0, 254],

            balloonColor: [255,255,255],
            balloonBg: [136, 0, 254],

            streamBg: [250, 245, 255],
            streamBorder: [239,222,255],
            streamBg2: [253, 250, 255],

            stackTitleColor: [113, 83, 138],

          }
        break;
        case 'dark':
          theme = {
            backgroundColor: [20, 20, 20],
            backgroundContrastColor: [255, 255, 255],
            overlayColor: [113, 83, 138],
            overlayOpacity: 0.05,
            textColor: [230, 230, 230],
            errorColor: [255, 0, 28],
            warningColor: [255, 220, 0],
            notificationColor: [254, 237, 197],
            successColor: [28, 220, 0],
            okColor: [9, 220, 94],
            accentColor: [193, 147, 254],
            accentColorDark: [113, 83, 138],
            accentColorMedium: [74, 61, 90],
            accentColorLight: [50, 50, 50],
            accentColorLightest: [30, 30, 30],
            accentColorDarkContrastColor: [0, 0, 0],
            redColor: [238,62,114],
            greenColor: [9,145,48],
            greenColorDark: [5, 79, 26],

            scrollBarBg: [255, 255, 255, 0.15],
            scrollBarHoverBg: [255, 255, 255, 0.35],
            scrollBarActiveBg: [255,255,255, 0.6],

            menuBg: [37,40,43],
            menuColor: [228,228,228],
            menuHoverBg: [193, 147, 254],
            menuHoverColor: [0,0,0],

            toggleBg: [71,74,76],
            toggleLine: [61,63,65],
            toggleActive: [193, 147, 254],
            toggleKnob: [255,255,255],

            zoomBg: [37,40,43],
            zoomColor: [228, 228, 228],
            zoomHoverBg: [74, 61, 90],
            zoomHoverColor: [255,255,255],

            modalCloseBg: [19,20,21],
            modalCloseColor: [228,228,228],

            tooltipBg: [228,228,228],
            tooltipColor: [28,28,28],
            tooltipTextOpacity: 1,

            toastBg: [228,228,228],
            toastColor: [28,28,28],

            iconBtnActiveBg: [193, 147, 254],
            iconBtnActiveColor: [0,0,0],

            inputBg: [71,74,76],
            inputColor: [255,255,255],
            inputBorder: [71,74,76],
            inputActiveBorder: [255,255,255],
            inputMenuHoverBg: [193, 147, 254],
            inputMenuHoverColor: [0,0,0],

            inputTagBg: [255,255,255],
            inputTagColor: [0,0,0],
            inputTagActiveBg: [193, 147, 254],
            inputTagActiveColor: [0,0,0],

            buttonBg: [37,40,43],
            buttonColor: [255,255,255],
            buttonBorder: [228,228,228],
            buttonActiveColor: [0,0,0],
            buttonActiveBg: [113, 83, 138],

            pane1: [37,40,43],
            pane2: [19,20,21],
            color1: [228,228,228],
            border1: [61, 63, 65],
            border2: [44,47,48],

            modalTitleColor: [225, 225, 225],

            loaderColor: [255,255,255],

            balloonColor: [0,0,0],
            balloonBg: [193, 147, 254],

            stackTitleColor: [193, 147, 254],
          }
        break;
      }
    }

    this.theme = Object.assign({}, this.theme, theme);
  }
}

const themeStore = new ThemeStore();
if(typeof window !== 'undefined' && isDev)
{
  window.themeStore = themeStore;
}

export default themeStore;