import AlbumMoreModal from "components/Album/MoreModal/AlbumMoreModal";
import FolderMoreModal from "components/Folder/MoreModal/FolderMoreModal";
import StreamMoreModal from "components/Stream/MoreModal/StreamMoreModal";
import { reaction } from "mobx";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "utils/user/lib";
import NotificationStore from "../notification-store";
const isSSR = (typeof window === "undefined")

export const NotificationContext = createContext();

/**
 * @type {NotificationStore}
 */
let store;
export function NotificationsProvider({ children }) {
  const user = useUser();
  const router = useRouter();
  const [notification, setNotification] = useState();

  if(!store || isSSR)
    store = new NotificationStore(user);
  
  useEffect(() => {    
    store.on('handleNotification', (notification) => {
      store.markNotificationAsRead(notification);
      const [subjectType, ...subjectKeyParts] = notification.props.subject.split('-');
      const subjectKey = subjectKeyParts.join('-');

      switch(notification.type) {
        case 'access-granted':   
        case 'contribution-added':       
          switch(subjectType)
          {
            case "album":
              router.push(`/album/${subjectKey}`);
            break;
            case "folder":
              router.push(`/folder/${subjectKey}`);
            break;
            case "stream":
              router.push(`/stream/${subjectKey}`);
            break;
            default:
              console.debug("Unknow subjectType in handleNotification: "+subjectType);
            break;
          }
  
        break;
        case 'access-request':
        case 'contribution-request':
          setNotification(notification);
        break;
        default:
          console.log(notification.type);
        break;
      }
    });

    const disposeReaction = reaction(
      () => user.isLoggedIn,
      (loginState) => {
        if(loginState)
          store.startPolling();
        else
          store.stopPolling();
      },
      {
        fireImmediately: true
      }
    );

    return () => {
      disposeReaction();
      store.stopPolling();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  let notificationContent = null;
  if(notification) {
    const [subjectType, ...subjectKeyParts] = notification.props.subject.split('-');
    const subjectKey = subjectKeyParts.join('-');

    switch(notification.type) {
      case 'access-request':
        switch(subjectType) {
          case 'album':
            notificationContent = (
              <AlbumMoreModal
                album={subjectKey}
                initialStack={[{id: 'overview'}, {id: 'requests'}]}
                onClose={() => {
                  setNotification(undefined);
                }}
              />
            )
          break;
          case 'folder':
            notificationContent = (
              <FolderMoreModal
                folder={subjectKey}
                initialStack={[{id: 'overview'}, {id: 'requests'}]}
                onClose={() => {
                  setNotification(undefined);
                }}
              />
            );
          break;
          case 'stream':
            notificationContent = (
              <StreamMoreModal
                streamKey={subjectKey}
                initialStack={[{id: 'overview'}, {id: 'requests'}]}
                onClose={() => {
                  setNotification(undefined);
                }}
              />
            );
          break;
          default:
            console.debug("Unknow subjectType in access-request: "+subjectType);
        }
      break;

      case 'contribution-request':

        switch(subjectType) {
          case 'album':
            notificationContent = (
              <AlbumMoreModal
                album={subjectKey}
                initialStack={[{id: 'overview'}, {id: 'contributions'}, {id: 'contribution-details', props: {contribution: notification.props.uploadKey}}]}
                onClose={() => {
                  setNotification(undefined);
                }}
              />
            )
          break;
        }

      break;
    }
  }
  
  
  return (
    <NotificationContext.Provider value={store}>
      {children}
      {notificationContent}
    </NotificationContext.Provider>
  );
}

/**
 * @returns {NotificationStore}
 */
export function useNotifications() {
  const store = useContext(NotificationContext);
  if (store === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }

  return store;
}