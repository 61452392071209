import styles from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as cancelIcon } from "@fortawesome/pro-light-svg-icons/faTimes";

type ProgressbarProps = {
  percentage: number,
  indeterminate?: boolean,
  onCancel?: () => void,
}

function ProgressBar(props: ProgressbarProps) {
  const percentage = props.percentage > 100 ? 100 : props.percentage < 0 ? 0 : props.percentage;
  const barStyle = {
    width: percentage + '%'
  }

  return (
    <div className="progress-bar">
      <div className="wrapper">
        <div className={`bar${props.indeterminate ? " indeterminate" : ""}`} style={barStyle}></div>
      </div>

      {props.onCancel!==undefined && <div className="cancel" onClick={props.onCancel}><FontAwesomeIcon icon={cancelIcon} /></div>}

      <style jsx>{styles}</style>
    </div>
  )
}

ProgressBar.defaultProps = {
  indeterminate: false,
}

export default ProgressBar;