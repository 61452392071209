import { action, observable, reaction, runInAction, makeObservable } from "mobx";
import countries from "utils/countries";
import { propsToModel } from "utils/models";
import AddressStateStore from "stores/address-state-store";

export default class AddressModel
{
  firstname = "";
  lastname = "";
  company = "";
  address1 = "";
  address2 = "";
  zipcode = "";
  city = "";
  country = "";
  state = "";
  phonenumber = "";

  isFilled = false;

  constructor(data)
  {
    makeObservable(this, {
      firstname: observable,
      lastname: observable,
      company: observable,
      address1: observable,
      address2: observable,
      zipcode: observable,
      city: observable,
      country: observable,
      state: observable,
      phonenumber: observable,

      isFilled: observable,

      setData: action,
      clear: action
    });

    this.setData(data);

    reaction(
      () => this.country,
      country =>
      {
        if(AddressStateStore.hasStates(country))
          this.state = Object.entries(AddressStateStore.getStates(country))[0][0];
        else
          this.state = "";
      }
    );
  }

  setData(data)
  {
    if(data)
    {
      propsToModel(data, this);
      this.isFilled = true;
    }
    else
      this.clear();
  }

  getData()
  {
    return {
      firstname: this.firstname,
      lastname: this.lastname,
      company: this.company,
      address1: this.address1,
      address2: this.address2,
      zipcode: this.zipcode,
      city: this.city,
      country: this.country,
      state: this.state,
      phonenumber: this.phonenumber,
    }
  }

  getFormatted()
  {
    return new Promise(resolve =>
    {
      countries.byIso(this.country)
      .then(country =>
      {
        import('i18n-postal-address').then((importResult) => {
          runInAction(() =>
          {
            const PostalAddress = importResult.default;
            const address = new PostalAddress();

            address.setFirstName(this.firstname);
            address.setLastName(this.lastname);
            address.setCompanyName(this.company);
            address.setAddress1(this.address1);
            address.setAddress2(this.address2);
            address.setPostalCode(this.zipcode);
            address.setCity(this.city);
            address.setCountry(country.name);
            address.setState(AddressStateStore.getState(this.country, this.state));
            address.setFormat({country: country.iso2});

            const lines = [...address.output("array"), [this.phonenumber]];

            resolve(lines);
          });
        });
      });
    });
  }

  clear()
  {
    this.firstname = "";
    this.lastname = "";
    this.company = "";
    this.address1 = "";
    this.address2 = "";
    this.zipcode = "";
    this.city = "";
    this.country = "";
    this.state = "";
    this.phonenumber = "";

    this.isFilled = false;
  }
}