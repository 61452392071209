import { useEffect } from "react";
import Script from "next/script";

export function GoogleAnalyticsServer() {
  function getUA() {
    return process.env.GA_MEASUREMENT_ID;
  }

  const js = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${getUA()}', {'anonymize_ip': true, 'send_page_view': false});
`;

  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${getUA()}`} />
      <Script
        id="gtag-script"
        dangerouslySetInnerHTML={{
          __html: js
        }}
      />
    </>
  )
}

export default function GoogleAnalyticsClient() {
  useEffect(() => {
    const handleRouteChange = () => {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }

    handleRouteChange();
  })

  return null;
}