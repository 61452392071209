import Modal from "./Modal.js"
import { ProgressBar } from 'components/UI'
import { makeAutoObservable } from "mobx";
import { Observer } from "mobx-react";

function ProgressModal({modalStyle, ...options}) {
  const store = new ProgressModalStore();
  const modal = (
    <Modal
      modalStyle={{
        minHeight: 100,
        ...modalStyle
      }}
      onClose={onCancel}
      {...options}
    >
      <div key="header"></div>
      <div key="content">
        <Observer>
        {
          () => (
            <div className="progress-modal">
              <div className="title">{store.title}</div>
              <ProgressBar indeterminate={store.indeterminate} percentage={store.percentage} onCancel={onCancel} />
              <style jsx>{`
                .progress-modal
                {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                }

                .title
                {
                  margin-bottom: 10px;
                }
              `}</style>
            </div>
          )
        }
        </Observer>
      </div>
    </Modal>
  );

  function onCancel()
  {
    if(options.onCancel) {
      options.onCancel().then(() => {
        Modal.remove(modal);
      }).catch(() => {});
    } else {
      Modal.remove(modal);
    }
  }

  Modal.add(modal);

  return {
    setTitle: title => store.setTitle(title),
    setIndeterminate: state => store.setIndeterminate(state),
    setPercentage: percentage => store.setPercentage(percentage),
    close: () => Modal.remove(modal),
  }
}

class ProgressModalStore
{
  title = "";
  indeterminate = false;
  percentage = 0;

  constructor()
  {
    makeAutoObservable(this);
  }

  setTitle(title)
  {
    this.title = title;
  }

  setIndeterminate(state)
  {
    this.indeterminate = state;
  }

  setPercentage(percentage)
  {
    this.percentage = percentage;
  }
}

export default ProgressModal;