import { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";

import useComponentSize from 'hooks/useComponentSize';
import ThemeStore from 'components/UI';

import styles from "./style";
import { Observer } from "mobx-react";
import isDev from "utils/isDev";

export default function StickyTooltip(props)
{
  if(isDev)
  {
    const nodePosition = getComputedStyle(props.node).getPropertyValue("position");
    if(nodePosition && nodePosition !== "relative")
      console.warn(`Tooltip parent needs to be position relative, current value; ${position}`, props.node);
  }

  const arrowSize = 6;
  const spacing = 4;

  const ref = useRef();
  const size = useComponentSize(ref);

  const delay = props.node.hasAttribute("data-tip-delay") ? parseInt(props.node.getAttribute("data-tip-delay")) : 350;
  const align = props.node.hasAttribute('data-tip-align') ? props.node.getAttribute('data-tip-align') : 'left';

  const [visible, setVisible] = useState(delay === 0);
  const [position, setPosition] = useState({});
  const [arrowPosition, setArrowPosition] = useState({});


  const designName = props.node.hasAttribute('data-tip-design') ? props.node.getAttribute('data-tip-design') : 'default';
  const design = {
    textColor: props.textColor || ThemeStore.theme.accentColorDark,
    textOpacity: props.textOpacity || 1,
    backgroundColor: props.backgroundColor || ThemeStore.theme.accentColorLight,
  }

  switch(designName) {
    case "dark":
      design.textColor = '255,255,255';
      design.textOpacity = 1;
      design.backgroundColor = '32,32,32';
    break;
  }

  if(props.node.hasAttribute('data-tip-text-color'))
    design.textColor = props.node.getAttribute('data-tip-text-color');

  if(props.node.hasAttribute('data-tip-text-opacity'))
    design.textOpacity = parseFloat(props.node.getAttribute('data-tip-text-opacity'));

  if(props.node.hasAttribute('data-tip-background-color'))
    design.backgroundColor = props.node.getAttribute('data-tip-background-color');

  useEffect(() =>
  {
    let timer;
    if(delay > 0)
    {
      timer = setTimeout(() =>
      {
        setVisible(true);
      }, delay);
    }

    return () =>
    {
      if(timer)
        clearTimeout(timer);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>
  {
    switch(align)
    {
      case 'left':
        setPosition({
          ...position,
          top: Math.round((size.height - props.node.offsetHeight) / 2),
          left: -size.width - ((spacing + (arrowSize * 2)) * 2)
        })

        setArrowPosition({
          right: 0,
          marginRight: -6,
          top: '50%',
          marginTop: -7
        })
      break;
      case 'right':
        setPosition({
          ...position,
          top: Math.round((size.height - props.node.offsetHeight) / 2),
          left: props.node.offsetWidth + spacing + arrowSize
        })

        setArrowPosition({
          left: 0,
          marginLeft: -6,
          top: '50%',
          marginTop: -7
        })
      break;
      case 'bottom':
        setPosition({
          ...position,
          top: props.node.offsetHeight + spacing + arrowSize,
          left: Math.round((props.node.offsetWidth - size.width) / 2)
        })

        setArrowPosition({
          top: 0,
          marginTop: -6,
          left: '50%',
          marginLeft: -6,
        })
      break;
      case 'top':
        setPosition({
          ...position,
          top: -size.height - spacing - (arrowSize * 2),
          left: Math.round((props.node.offsetWidth - size.width) / 2)
        })

        setArrowPosition({
          top: size.height * 2 - arrowSize,
          left: "50%",
          marginLeft: -6
        })
      break;
    }
  }, [size]) // eslint-disable-line react-hooks/exhaustive-deps

  let opacity = {
    opacity: (visible && size.width > 0) ? 1 : 0
  };

  return ReactDOM.createPortal(
    <Observer>
    {
      () => {
        const colors = {
          "--tooltipColor": design.textColor,
          "--tooltipTextOpacity": design.textOpacity,
          "--tooltipBg": design.backgroundColor
        }

        return (
          <div ref={ref} className={"ui-tooltip" + " ui-tooltip-align-"+align + " ui-tooltip-sticky"} style={{...colors, ...position, ...opacity}}>
            <div className="arrow" style={arrowPosition}></div>
            {props.node.getAttribute("data-tip")}
            <style jsx>{styles}</style>
          </div>
        );
      }
    }
    </Observer>,
    props.node
  );
}