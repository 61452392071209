import { forwardRef } from 'react';
import Modal from "../Modal"
import Button from "../../Button/Button";
import buttonModalStyle from "./style"
import { useCallback } from 'react';
import { useImperativeHandle } from 'react';

type Button = {
  text: string,
  className?: string,
  onClick?: () => void,
  onClose?: () => void,
  default?: boolean,
  [key: string]: any,
}

type ButtonModalProps = {
  title: string,
  description?: string,
  onClose?: () => void,
  onCancel?: () => void,
  onKey?: (event: KeyboardEvent) => void,
  modalStyle?: React.CSSProperties,
  buttons: Array<Button>
}

const ButtonModal = forwardRef<any, ButtonModalProps>((props, ref) => {
  const { buttons, onClose, ...rest } = props;

  const onKey = useCallback((event) =>
  {
    if(event.key.toLowerCase() === "enter")
    {
      props.buttons.forEach(button =>
      {
        if(button.default)
        {
          button.onClick();
          onClose();
        }
      });
    }
  }, [onClose, props.buttons]);

  useImperativeHandle(ref, () => ({
    onKey,
  }));

  return (
    // @ts-ignore
    <Modal
      className="ui-button-modal small"
      onClose={onClose}
      {...rest}
    >
      <div key="header">
        <div className="title">{props.title}</div>
        <div className="description">{props.description}</div>
      </div>

      <div key="content">
        <div className="ui-button-buttons">
          {
            buttons.map(({onClick, text, className, ...props}, index) =>
            {
              const onButtonClick = () =>
              {
                if(onClick) {
                  onClick();
                }
                
                onClose();
              }

              return <Button key={index} className={["large", className].filter(x=>x).join(" ")} {...props} onClick={() => onButtonClick()}>{text}</Button>
            })
          }
        </div>
        <style jsx>{buttonModalStyle}</style>
      </div>
    </Modal>
  );
});
ButtonModal.displayName = "ButtonModal";
export default ButtonModal;
