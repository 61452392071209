import { useEffect } from "react";
import { reaction } from "mobx";
import { Modal } from "components/UI";
import AuthenticationModal from "./Modal";
import translationStore from "stores/translation-store";

/**
 * @param {{ message: string, screen: "login" | "register" }} props
 */
const showAuthentication = (props = {}) =>
{
  if(!props.message) {
    props.message = translationStore.translate("authentication.welcome");
  }

  Modal.add(
    <AuthenticationModal
      screen={props.screen}
      disableBackdrop={true} // Disables the extra backdrop of the legacy Modal component
      {...props}
      onClose={() => {
        hideAuthentication();
        
        if(props.onClose) {
          props.onClose();
        }
      }}
    />,
    "authentication"
  );
}

const hideAuthentication = () =>
{
  Modal.remove("authentication");
}

/**
 * @param {import('models/member-model').default} user
 * @param {{ message: string, screen: "login" | "register" }} props
 */
const whenAuthenticated = (user, options = {}) =>
{
  return new Promise((resolve, reject) =>
  {
    if(user.isLoggedIn)
      return resolve();

    const disposeReaction = reaction(
      () => user.isLoggedIn,
      (isLoggedIn, prevValue, _reaction) => {
        hideAuthentication();

        if(isLoggedIn)
          resolve();
        else
          reject();

        _reaction.dispose();
      }
    );


    let optionsOnClose = undefined;
    if(options.onClose)
      optionsOnClose = options.onClose;

    if(options.allowClose === undefined)
      options.allowClose = true;

    options.onClose = () =>
    {
      disposeReaction();

      if(optionsOnClose)
        optionsOnClose();

      if(user.isLoggedIn)
        resolve();
      else
        reject({error: "popup_closed_by_user"});
    }

    showAuthentication(options);
  });
}

function MyAuthenticationModal(props) {
  useEffect(() => {
    showAuthentication(props);

    return () => {
      hideAuthentication();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export {
  MyAuthenticationModal,
  showAuthentication,
  hideAuthentication,
  whenAuthenticated
}