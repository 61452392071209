import { createRef } from 'react';
import Modal from "./Modal.js"
import ButtonModal from "./ButtonModal"
import translationStore from 'stores/translation-store'

/**
 * @param {object} options
 * @param {string} options.title
 * @param {string} options.description
 * @param {string} options.cancelButtonText
 * @param {string} options.okButtonText
 * @return {Promise}
 */

type ConfirmOptions = {
  title: string,
  description?: string,
  cancelButtonText?: string,
  okButtonText?: string,
  destructive?: boolean,
}

function Confirm(options: ConfirmOptions) {

  return new Promise<void>((resolve, reject) =>
  {
    const buttons = [
      {
        className: options.destructive ? "red" : "accent",
        text: options.okButtonText || translationStore.translate("base.ok"),
        default: true,
        onClick: () => resolve()
      },
      {
        text: options.cancelButtonText || translationStore.translate("base.cancel"),
        onClick: () => reject()
      }
    ]

    const modalRef = createRef<React.ElementRef<typeof ButtonModal>>();
    const modal = <ButtonModal
      ref={modalRef}
      onCancel={reject}
      onKey={e => {
        if(modalRef.current && modalRef.current.onKey!==undefined)
          modalRef.current.onKey(e);
      }}
      onClose={onClose}
      modalStyle={{maxWidth: 500}}
      buttons={buttons}
      {...options}
    />;

    function onClose() {
      // Promise wordt maar 1x geresolved dus kan hier zonder problemen een reject geven
      // dit is een fallback voor als iemand deze dialog sluit zonder iets te kiezen
      reject();

      // @ts-ignore
      Modal.remove(modal);
    }

    // @ts-ignore
    Modal.add(modal);
  });
}

export default Confirm;