import css from "styled-jsx/css";

export default css`
  .ui-select {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(var(--color1), 1);
    background-color: rgba(var(--pane1), 1);
    border: 1px solid rgba(var(--inputBorder), 1);
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .ui-select label {
    color: rgba(var(--textColor), 1);
  }

  .ui-select.active {
    outline: none;
    box-shadow: 0 0px 0px 2px rgba(var(--accentColorMedium), 1);
    border-color: rgba(var(--accentColor), 1);
  }

  .ui-select.error {
    border-color: rgba(var(--errorColor), 0.5);
  }

  .ui-select.error.active {
    box-shadow: 0 0px 0px 2px rgba(var(--errorColor), 0.5);
      border-color: rgba(var(--errorColor), 1);
  }

  .ui-select select {
    min-width: 0;
    padding: 0px;
    margin: 0px;
    border: 0;
    padding-left: 10px;
    padding-right: 30px;
    -webkit-appearance: none;
    background-color: transparent;
    color: rgba(var(--textColor), 1);
    height: 35px;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-family: inherit;
    width: 100%;
  }

  .ui-select select :global(option, optgroup) {
    background-color: rgba(var(--pane1), 1);
    color: inherit;
  }

  .ui-select select:disabled {
    color: rgba(var(--accentColorLight), 1);
  }

  .ui-select select:focus {
    outline: none;
    box-shadow: none;
    border: 0;
  }

  .ui-select select.small {
    height: 26px;
  }

  .ui-select select.large {
    height: 40px;
  }

  .ui-select .ui-select-arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20px;
    padding-left: 5px;
    background-color: rgba(var(--pane1), 1);
    pointer-events: none;
  }

  .ui-select label+select {
    margin-left: 5px;
  }
`

export const icon = css.resolve`
  .ui-select-icon
  {
    color: inherit;
    pointer-events: none;
  }
`