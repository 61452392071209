export enum UploadState {
  WAITING = "WAITING",
  UPLOADING = "UPLOADING",
  PAUSED = "PAUSED",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
}

export enum UploadErrorState {
  INIT_FAILED = "INIT_FAILED",
  ALL_ERRORED = "ALL_ERRORED",
  STALE = "STALE",
  OTHER = "OTHER",
}

export enum FileState {
  WAITING = "WAITING",
  SKIPPED = "SKIPPED",
  UPLOADING = "UPLOADING",
  PAUSED = "PAUSED",
  DONE = "DONE",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
  STALE = "STALE",
}

export enum FileError {
  ZERO_BYTE = 2,
  UNKNOWN_FILE_TYPE = 10,
  UNRESOLVED_FILE_TYPE = 11,
  UNSUPPORTED_MIME_TYPE = 12,
  NO_FILE_DIMENSIONS = 15,
  IMAGE_RESOLUTION_TOO_SMALL = 40,
  IMAGE_RESOLUTION_TOO_LARGE = 41,
  FILE_SIZE_TOO_LARGE = 42,
  VIDEO_DURATION_TOO_LONG = 43,
}

export enum FileErrorGroup {
  UNSUPPORTED_MIME_TYPE = "UNSUPPORTED_MIME_TYPE",
  IMAGE_RESOLUTION_TOO_SMALL = "IMAGE_RESOLUTION_TOO_SMALL",
  IMAGE_RESOLUTION_TOO_LARGE = "IMAGE_RESOLUTION_TOO_LARGE",
  IMAGE_FILESIZE_TOO_LARGE = "IMAGE_FILESIZE_TOO_LARGE",
  VIDEO_DURATION_TOO_LONG = "VIDEO_DURATION_TOO_LONG",
  FILE_CORRUPT = "FILE_CORRUPT",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export enum StatusFileResponseState {
  UNKNOWN = "unknown",
  PROCESSING = "processing",
  COMPLETE = "complete",
  ERROR = "error",
}