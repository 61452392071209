import Modal from "./Modal"
import translationStore from 'stores/translation-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react'
import {Button} from 'components/UI';
import css from 'styled-jsx/css'

import { faWhatsapp as whatsappIcon } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { faCommentsAlt as chatIcon } from "@fortawesome/pro-light-svg-icons/faCommentsAlt";
import { faQuestionCircle as helpIcon } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { faEnvelope as mailIcon } from "@fortawesome/pro-light-svg-icons/faEnvelope";

const styles = css`
  .options {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    border-top: 1px solid rgba(var(--border1));
    margin-left: -1px;
  }

  .option {
    background-color: rgba(var(--accentColorLightest), 1);
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    text-align: left;
    align-items: center;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(var(--accentColorLightest), 1);
  }

  .option.disabled>* {
    opacity: 0.5;
    pointer-events: none;
  }

  .option.disabled :global(button) {
    opacity: 0.3;
    cursor: default;
  }

  .option.disabled :global(svg) {
    opacity: 0.5;
  }

  .option :global(svg) {
    color: rgba(var(--buttonBorder), 1);
    margin-bottom: 2rem;
  }

  .option h2 {
    font-family: "Source Sans Pro";
    font-weight: 300;
    margin-bottom: 0.5rem;
  }

  .option p {
    font-size: 1.4rem;
  }

  .option .stretch {
    flex: 1;
    min-height: 3rem;
  }

  @media (max-width: 590px) {
    .options {
      width: calc(100vw);
    }
    .options .option {
      max-width: 100%;
      min-width: 50%;
    }
  }

  @media (min-width: 590px) and (max-width: 1029px) {
    .options {
      width: 500px;
    }
    .options .option {
      min-width: calc(50%);
      border-top: 1px solid rgba(var(--border1));
      margin-top: -1px;
    }
  }

  @media (min-width: 1030px) {
    .options {
      width: 600px;
    }
  }
`;

function HelpContent(props) {
  return (
    <Observer>
    {
      () => {
        const items = [
          {
            key: 'whatsapp',
            icon: whatsappIcon,
            title: 'modal.help.whatsapp.title',
            description: 'modal.help.whatsapp.description',
            button: 'modal.help.whatsapp.button',
            onClick: () => {
              window.open('https://wa.me/31622838727');
            }
          },
          {
            key: 'faq',
            icon: helpIcon,
            title: 'modal.help.faq.title',
            description: 'modal.help.faq.description',
            button: 'modal.help.faq.button',
            onClick: () => {
              window.open(translationStore.translate('urls.faq'));
            }
          },
          {
            key: 'email',
            icon: mailIcon,
            title: 'modal.help.email.title',
            description: 'modal.help.email.description',
            button: 'modal.help.email.button',
            onClick: () => {
              window.location.href = 'mailto:support@myalbum.com';
            }
          },
        ];

        return (
          <Modal
            {...props}
          >
            <div key="content">
              <div className="options">
              {
                items.map(item => {
                  if(item.disabled)
                    return;

                  return <div key={item.key} className={"option" + (item.className!==undefined ? ' '+item.className : '')}>
                    <FontAwesomeIcon icon={item.icon} size="2x" />
                    <h2>{translationStore.translate(item.title)}</h2>
                    <p>{translationStore.translate(item.description)}</p>
                    <div className="stretch"></div>
                    <Button onClick={item.onClick} className="small">{translationStore.translate(item.button)}</Button>
                  </div>
                })
              }
              </div>

              <style jsx>{styles}</style>
            </div>
          </Modal>
        )
      }
    }
    </Observer>
  );
}

function Help() {
  const modal = <HelpContent
    onClose={() => {
      Modal.remove(modal);
    }}
    contentStyle={{
      padding: 0
    }}
  />

  Modal.add(modal);
}

export default Help;