import { action, observable, makeObservable } from "mobx";

class TooltipStore
{
  stickyTooltips = [];

  constructor() {
    makeObservable(this, {
      stickyTooltips: observable,

      addSticky: action,
    });
  }

  addSticky(node)
  {
    if(!this.stickyTooltips.includes(node))
      this.stickyTooltips.push(node);
  }

  hide()
  {
    this.stickyTooltips = this.stickyTooltips.filter(node =>
    {
      return node.hasAttribute("data-tip-sticky");
    });
  }
}

export default TooltipStore;