import { Children } from 'react';
import translationStore from '../stores/translation-store';

// Zorgt ervaar dat je veilig recursief data kan updaten van een object
// Als prop niet bestaat dan wordt deze aangemaakt
function addProp(target, path, value) {
  if(typeof target === "undefined" || target === null)
    return;

  const last = path.splice(-1, 1)[0];
  let mytarget = target;

  for(let pathItem of path) {
    if(mytarget[pathItem]===undefined)
      mytarget[pathItem] = {};

    mytarget = mytarget[pathItem];
  }

  mytarget[last] = value;
}

function getProp(target, path) {
  let mytarget = target;
  if(mytarget === undefined)
    return undefined;

  for(let pathItem of path) {
    if(mytarget[pathItem]===undefined)
    {
      return undefined;
    }else
    {
      mytarget = mytarget[pathItem];
    }
  }

  return mytarget;
}

function arrayUnique(array)
{
  const unique = new Set();
  array.forEach(a=>unique.add(JSON.stringify(a)))

  return Array.from(unique).map(a => JSON.parse(a));
}

function didReactChildrenChange(prev, next)
{
  // Let op, deze functie crashte met 1 kind, temp fix gemaakt maar deze hele functie lijkt niet nodig?
  const flatten = (children, flat = []) => {
    flat = [ ...flat, ...Children.toArray(children) ]

    if (children.props && children.props.children) {
      return flatten(children.props.children, flat)
    }

    return flat
  }

  const simplify = children => {
    const flat = flatten(children)

    return flat.map(
        ({
            key,
            ref,
            type,
            props: {
                children, // eslint-disable-line no-unused-vars
                ...props
            }
        }) => ({
            key, ref, type, props
        })
    )
  }

  if(Array.isArray(prev.children) && Array.isArray(next.children))
  {
    return (JSON.stringify(simplify(prev.children)) !== JSON.stringify(simplify(next.children)));
  }else
  {
    return prev.children!==next.children;
  }
}

function removeEmptyValues(obj)
{
  for(let key in obj)
    if(obj[key] === null || obj[key] === undefined || obj[key] === "")
      delete obj[key];

  return obj;
}

function formatBytes(bytes, decimals = 2)
{
  const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  return format(bytes, decimals, suffixes);
}

function formatBitrate(bytes, decimals = 2)
{
  const suffixes = ['bps', 'Kbps', 'Mbps', 'Gbps'];
  return format(bytes, decimals, suffixes);
}

function format(input, decimals, suffixes)
{
  if (input === 0) return '0 ' + suffixes[0];

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(input) / Math.log(k));

  return parseFloat((input / Math.pow(k, i)).toFixed(dm)) + ' ' + suffixes[i];
}

function getBoundingBoxFromFrame(frame, degrees=0) {
  let points = [
    {x: frame.x, y: frame.y},
    {x: frame.x + frame.width, y: frame.y},
    {x: frame.x, y: frame.y+frame.height},
    {x: frame.x + frame.width, y: frame.y+frame.height},
  ];

  return getBoundingBox(points, degrees);
}

function getBoundingBox(points, degrees=0) {
  let minX = Math.min(...points.map(point => point.x));
  let minY = Math.min(...points.map(point => point.y));
  let maxX = Math.max(...points.map(point => point.x));
  let maxY = Math.max(...points.map(point => point.y));
  let pivot = {
    x: maxX - ((maxX - minX) / 2),
    y: maxY - ((maxY - minY) / 2)
  };
  let radians = degrees * (Math.PI / 180);
  let cos = Math.cos(radians);
  let sin = Math.sin(radians);

  function rotatePoint(pivot, point, cos, sin) {
    return {
      x: (cos * (point.x - pivot.x)) - (sin * (point.y - pivot.y)) + pivot.x,
      y: (sin * (point.x - pivot.x)) + (cos * (point.y - pivot.y)) + pivot.y
    };
  }

  let boundingBox = {
    x1: Number.POSITIVE_INFINITY,
    y1: Number.POSITIVE_INFINITY,
    x2: Number.NEGATIVE_INFINITY,
    y2: Number.NEGATIVE_INFINITY,
  };

  points.forEach((point) => {
    let rotatedPoint = rotatePoint(pivot, point, cos, sin);

    boundingBox.x1 = Math.min(boundingBox.x1, rotatedPoint.x);
    boundingBox.y1 = Math.min(boundingBox.y1, rotatedPoint.y);
    boundingBox.x2 = Math.max(boundingBox.x2, rotatedPoint.x);
    boundingBox.y2 = Math.max(boundingBox.y2, rotatedPoint.y);
  });

  return boundingBox;
}

function timeSince(date) {
  const formatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);


  if((seconds / 86400) > 3)
  {
    // Bij meer dan 3 dagen oud datum weergeven
    return new Date(date).toLocaleDateString(translationStore.language, formatOptions);
  }

  let interval = seconds / 86400;
  if (interval > 1) {
    return translationStore.translate('timesince-days', {time: Math.floor(interval)});
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return translationStore.translate('timesince-hours', {time: Math.floor(interval)});
  }

  interval = Math.max(1, seconds / 60);
  return translationStore.translate('timesince-minutes', {time: Math.floor(interval)});
}

function downloadFile(url)
{
  const downloadElement = document.createElement("a")
  if("download" in downloadElement)
  {
    downloadElement.href = url;
    downloadElement.download = url.split("?")[0].split("/").pop();

    downloadElement.click();
  }
  else
  {
    const win = window.open(url);
    if(!win)
      window.location.href = url;
  }
}

/**
 * @param {object} config
 * @param {number} config.length
 * @param {boolean} config.readable
 * @param {'lowercase'|'uppercase'} config.capitalization
 * @param {'alpha'|'numeric'|'alphanumeric'} config.charset
 */
function randomstring(config = {})
{
  let charset;
  switch(config.charset)
  {
    case 'alpha':
    case 'alphanumeric':
      charset = "abcdefghijklmnopqrstuvwxyz";
      if(config.readable)
        charset = "abcdefghjkmnpqrstuvwxyz";

      switch(config.capitalization)
      {
        case 'uppercase':
          charset = charset.toUpperCase();
        break;
        case 'lowercase':
          charset = charset.toLowerCase();
        break;
        default:
          charset = charset.toLowerCase() + charset.toUpperCase();
      }

      if(config.charset === "alphanumeric")
      {
        if(config.readable)
          charset += "123456789";
        else
          charset += "0123456789";
      }
    break;
    case 'numeric':
      charset = "0123456789";
      if(config.readable)
        charset = "123456789";
    break;
  }

  let randomstring = "";
  while(randomstring.length < config.length)
  {
    randomstring += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return randomstring;
}

function selectText(el) {
  if (window.getSelection) {
    const range = document.createRange();
    range.selectNode(el);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

function getBestSize(sizes, width, height)
{
  let found = null;
  for (let i = 0; i < sizes.length; i++)
  {
    let size = sizes[i];
    if (size.width / width > 0.75 && size.height / height > 0.75)
    {
      found = size;
      break;
    
    }
  }

  if (!found)
    {found = sizes[sizes.length - 1];}

  return found;
}

export {
  addProp,
  getProp,
  arrayUnique,
  didReactChildrenChange,
  downloadFile,
  formatBytes,
  formatBitrate,
  removeEmptyValues,
  getBoundingBox,
  getBoundingBoxFromFrame,
  timeSince,
  randomstring,
  selectText,
  getBestSize
};