import { useRouter } from "next/router";
import { useMemo } from "react";
import translationStore from "stores/translation-store";

function TranslationProvider(props:any) {
  const router = useRouter();

  useMemo(() => {
    if(!props.translations?.language || !props.translations?.translations)
      return;
    
    translationStore.hydrate(props.translations.language, props.translations.translations);
  }, [props.translations?.language, props.translations?.translations]);

  if(!props.translations?.language || !props.translations?.translations) {
    console.error("Missing translations", router.route);
    return null;
  }
  
  return props.children;
}

export default TranslationProvider;