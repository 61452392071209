import { action, computed, observable, makeObservable } from "mobx";

class AddressStateStore
{
  states = {};

  constructor() {
    makeObservable(this, {
      states: observable,
      hydrate: action
    });
  }

  hydrate(states)
  {
    this.states = states;
  }

  hasStates(countryCode)
  {
    return computed(() =>
    {
      return this.states.hasOwnProperty(countryCode);
    }).get();
  }

  getStates(countryCode)
  {
    return computed(() =>
    {
      return this.states[countryCode];
    }).get();
  }

  getState(countryCode, stateCode)
  {
    return computed(() =>
    {
      if(countryCode && stateCode)
      try {
        return this.states[countryCode][stateCode];
      } catch(e) {} // eslint-disable-line no-empty
      return null;
    }).get();
  }
}

const AddressStateStoreInstance = new AddressStateStore();
export default AddressStateStoreInstance;