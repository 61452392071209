export function colorArrayToString(color)
{
  const colorArray = Object.assign([], colorToArray(color));

  while(colorArray.length < 4)
    colorArray.push(1)

  return "rgba(" + colorArray.join(", ") + ")";
}

export function colorToArray(color)
{
  if(typeof color === "string")
    color = color.replace("#", "").trim().match(/.{1,2}/g).map(hex => parseInt("0x"+hex, 16));
  else if (!Array.isArray(color))
    throw new Error("Invalid color, expected a string or array, got a " + typeof color);

  return color;
}

export function getDataProps(props)
{
  let myProps = {};
  for(let key in props) {
    if(key.indexOf('data-')===0)
    {
      myProps[key] = props[key];
    }
  }

  return myProps;
}