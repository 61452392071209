import { Observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import StickyTooltip from "./StickyTooltip";
import ToolTip from "./ToolTip";
import TooltipStore from "./store";

const store = new TooltipStore();

function ToolTipRoot() {
  const collectTooltips = useCallback(() => {
    document.querySelectorAll("[data-tip][data-tip-sticky]").forEach(node =>
    {
      store.addSticky(node);
    });
  }, []);

  useEffect(() =>
  {
    collectTooltips();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Observer>
    {
      () => (
        <>
          {
            store.stickyTooltips.map((node, index) =>
            {
              return <StickyTooltip key={`tooltip-${index}`} node={node} />
            })
          }
          <ToolTip />
        </>
      )
    }
    </Observer>
  );
}

export default ToolTipRoot;