import { observable, makeObservable } from "mobx";
import { propsToModel } from "utils/models";

export default class PhotobookProductModel
{
  key = "";
  type = "photobook";
  size = null;
  booktype = null;
  orientation = null;

  constructor(data)
  {
    makeObservable(this, {
      key: observable,
      size: observable,
      booktype: observable
    });

    propsToModel(data, this);
  }

  getData()
  {
    return {
      key: this.key,
      type: this.type,
      size: this.size,
      booktype: this.booktype
    }
  }
}