import { getAvatarFromMemberKey } from "utils/avatar";

const { createContext, useContext } = require("react");

const isSSR = (typeof window === "undefined")
const AvatarContext = createContext();

class AvatarStore {
  /**
   * @private
   */
  _cache = new Map();

  /**
   * @private
   */
  _loadCache = new Map();

  isLoaded(memberKey) {
    return this._cache.has(memberKey);
  }

  addAvatar(memberKey, data) {
    this._cache.set(memberKey, data);
  }

  getAvatar(memberKey) {
    return this._cache.get(memberKey);
  }

  loadAvatarFromMemberKey(memberKey) {
    if(this.isLoaded(memberKey)) {
      return Promise.resolve(this.getAvatar(memberKey));
    }

    if(!this._loadCache.has(memberKey)) {
      this._loadCache.set(memberKey, new Promise((resolve, reject) => {
        getAvatarFromMemberKey(memberKey).then(data => {
          this.addAvatar(memberKey, data)
          resolve(data);
        }).catch(reject);
      }));
    }

    return this._loadCache.get(memberKey);
  }
}

const avatarStore = !isSSR ? new AvatarStore() : null;

export function AvatarProvider({ children })
{
  const store = avatarStore ? avatarStore : new AvatarStore();

  return (
    <AvatarContext.Provider value={store}>
      {children}
    </AvatarContext.Provider>
  );
}

/**
 * @returns {AvatarStore}
 */
export function useAvatars() {
  const store = useContext(AvatarContext);
  if (store === undefined) {
    throw new Error('useAvatars must be used within a AvatarContext');
  }

  return store;
}