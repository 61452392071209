import Modal from "./Modal.js"
import { Loader } from 'components/UI'

function LoaderModal(options) {

  const modal = (
    <Modal
      modalStyle={{
        height: 100,
      }}
      draggable={false}
      className="center middle small"
      allowClose={false}
    >
      <div key="header">
        <Loader size="large" />
        <div style={{fontSize: 10, marginTop: 10, whiteSpace: 'nowrap'}}>{options.message}</div>
      </div>
    </Modal>
  );

  Modal.add(modal);

  return {
    close: () => {
      Modal.remove(modal);
    }
  }
}

export default LoaderModal;