import Autocomplete from "./Autocomplete/Autocomplete";
import Button from "./Button/Button";
import CopyButton from "./CopyButton/CopyButton";
import CopyInput from "./CopyInput/CopyInput";
import CheckBox from "./Checkbox/CheckBox";
import FileSelectButton from "./FileSelectButton/FileSelectButton";
import HorizontalScroller from "./HorizontalScroller/HorizontalScroller";
import IconButton from "./IconButton/IconButton";
import IconTagSearch from "./IconTagSearch/IconTagSearch";
import Label from "./Label/label";
import Loader from "./Loader";
import MenuBar from "./MenuBar/MenuBar";
import Menu from "./Menu/Menu";
import MessageBox from "./MessageBox/MessageBox";
import Modal from "./Modal/Modal";
import NumberInput from "./NumberInput/NumberInput";
import OverscrollContain from "./OverscrollContain/OverscrollContain";
import OverscrollHandler from './OverscrollContain/OverscrollHandler';
import RadioButton from "./RadioButton/RadioButton";
import Select from "./Select/select";
import Slider from "./Slider/slider";
import SVGImage from "./SVGImage/svg";
import SwitchButton from "./SwitchButton/SwitchButton";
import { Table, TableHeader, TableData, TableRow }  from "./Table/table"
import { ToastContainer } from "./Toast/toast";
import ToggleButton from "./ToggleButton/ToggleButton";
import ZoomControl from "./ZoomControl/ZoomControl";
import ColorPicker from "./ColorPicker/ColorPicker";
import SearchInput from "./SearchInput/SearchInput";
import LoadingBar from './LoadingBar/LoadingBar';
import ProgressBar from './ProgressBar/ProgressBar';
import Floater from './Floater/Floater';
import ToggleButtonDark from './ToggleButtonDark/ToggleButtonDark';
import Tag from "./Tag/Tag";
import AutoGrowTextArea from "./AutoGrowTextArea/AutoGrowTextArea";

import {toJS} from 'mobx';

import store from "./store";
const setTheme = (theme) => store.setTheme(theme);
const getTheme = () => {return toJS(store.theme)};

export {
  setTheme,
  getTheme,

  Autocomplete,
  AutoGrowTextArea,
  Button,
  ColorPicker,
  CopyButton,
  CopyInput,
  CheckBox,
  FileSelectButton,
  Floater,
  HorizontalScroller,
  IconButton,
  IconTagSearch,
  Label,
  Loader,
  LoadingBar,
  MenuBar,
  Menu,
  MessageBox,
  Modal,
  NumberInput,
  OverscrollHandler,
  OverscrollContain,
  ProgressBar,
  RadioButton,
  SearchInput,
  Select,
  Tag,
  ToggleButtonDark,
  Slider,
  SVGImage,
  SwitchButton,
  Table, TableHeader, TableData, TableRow,
  ToastContainer,
  ToggleButton,
  ZoomControl,
}

export default store;