import Model from "./model";
import { observable, makeObservable, runInAction } from "mobx";

class ToastStore
{
  toasts = [];

  /**
  * @param {Object} props
  * @param {String} props.message
  * @param {Boolean} [props.autoHide=true]
  * @param {Number} [props.autoHideDelay=3000]
  * @param {Array} [props.buttons]
  * @param {String} [props.className]
  */
  add = props =>
  {
    return new Promise(resolve =>
    {
      runInAction(() => {
        this.toasts.push(new Model(props, this, resolve));
      });
    });
  }

  remove = key =>
  {
    let index = this.toasts.findIndex(toast => toast.key === key);
    runInAction(() => {
      this.toasts.splice(index, 1);
    });
  }

  constructor() {
    makeObservable(this, {
      toasts: observable
    });
  }
}

export default ToastStore;