import { computed, observable, runInAction, makeObservable } from "mobx";
import CartModel from "models/shop/cart-model";

export default class ShopStore
{
  /** @private */
  _isLoadingCart = false;

  cart = new CartModel();

  constructor(cartData)
  {
    makeObservable(this, {
      cart: observable,
      productCount: computed
    });

    if(cartData)
      this.cart.hydrate(cartData);
  }

  resetCart()
  {
    this.cart = new CartModel();
  }

  loadCartFromUserWhenNeeded(cartKey, user)
  {
    if(this.cart.key === "") // Cart is not already loaded
    {
      if(!this._isLoadingCart && (user.isLoggedIn || cartKey !== "")) // Only load one at a time. Also, check if we have a cart key or the user is logged in so we can get a cart
      {
        this._isLoadingCart = true;
        const [promise] = user.FetchJSON(`${process.env.API_URL}/shop/cart/${cartKey}`);
        promise.then((res) =>
        {
          this._isLoadingCart = false;
          runInAction(() => this.cart.hydrate(res.data)); // Hydrate cart
        });
      }
    }
  }

  get productCount()
  {
    return this.cart.productCount;
  }
}