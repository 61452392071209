import { useEffect } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { configure } from "mobx"
import { enableStaticRendering } from 'mobx-react'
import { config } from '@fortawesome/fontawesome-svg-core'
import { useRouter } from 'next/router'

import { ToastContainer } from 'components/UI';
import { hideAuthentication } from 'components/Authentication';
import GoogleAnalyticsClient, { GoogleAnalyticsServer } from 'components/GoogleAnalytics';
import { NotificationsProvider } from 'components/Notifications/hooks/useNotifications';
import StickyNotification from 'components/Notifications/StickyNotification/StickyNotification';

import MyStyles from './_styles';
import appStyles from 'css/app';

import { ShopProvider } from 'stores/shop/shop';
import { AppProvider } from 'stores/app/context';
import { AvatarProvider } from 'stores/avatar-store';

import "@myalbum/react-rte/lib/Draft.global.css";
import "@myalbum/react-rte/lib/RichTextEditor.scss";
import "@myalbum/react-rte/lib/ui/ui.scss";
import Head from 'next/head';
import { AppProps } from 'next/app';
import { NextPage } from 'next';
import { UserProvider } from 'utils/user/provider';
import TranslationProvider from 'utils/translations/provider';
import ModalRoot from 'components/UI/Modal/Root';
import Seo from 'components/Seo';
import ToolTipRoot from 'components/UI/ToolTip/root';

const isServerSide = typeof window === 'undefined';
config.autoAddCss = false

enableStaticRendering(isServerSide)
configure({
  computedRequiresReaction: !isServerSide,
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};


export default function App(props:AppProps) {
  const Component:NextPageWithLayout = props.Component;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      hideAuthentication();
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppProvider>
      <TranslationProvider translations={props.pageProps.translations}>
        <AvatarProvider>
          <UserProvider user={props.pageProps.user}>
            <NotificationsProvider>
              <ShopProvider cart={props.pageProps.cart}>

                <Head>
                  <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover" />
                </Head>
                <Seo />

                {
                  getLayout(<Component {...props.pageProps} />)
                }

                {
                  !props.pageProps.gaDisabled && (
                    <>
                       <GoogleAnalyticsServer />
                       <GoogleAnalyticsClient />
                    </>
                  )
                }
               
                <ToastContainer />
                <ModalRoot />
                <MyStyles />
                <StickyNotification />
                <ToolTipRoot />
                

                <style jsx>{appStyles}</style>
              </ShopProvider>
            </NotificationsProvider>
          </UserProvider>
        </AvatarProvider>
      </TranslationProvider>
    </AppProvider>
  )
}