import { AbortController } from '@aws-sdk/abort-controller';
import { CompletedPart, CompleteMultipartUploadCommand, CreateMultipartUploadCommand, CreateMultipartUploadCommandOutput, PutObjectCommand, PutObjectCommandInput, S3Client, UploadPartCommand } from '@aws-sdk/client-s3';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import EventEmitter from 'events';
import PQueue from 'p-queue';
import { FileState } from './state';
import { BodyDataTypes, UploaderOptions } from './types';

export default class Uploader extends EventEmitter {
  private queue: PQueue;
  private abortController: AbortController;

  private client: S3Client;
  private params: PutObjectCommandInput;
  private partSize: number;

  private uploadId: string;
  private uploadedParts: Array<CompletedPart> = [];

  private state: FileState = FileState.WAITING;

  private doneResolve: () => void;
  private doneReject: (error: Error) => void;

  constructor(options: UploaderOptions) {
    super();

    if(options.client) {
      this.setClient(options.client);
    }
    if(options.params) {
      this.setParams(options.params);
    }

    this.partSize = options.partSize;
    this.abortController = new AbortController();;
    this.queue = new PQueue({
      concurrency: options.queueSize,
      autoStart: false,
    });
  }

  setClient(S3Client: UploaderOptions['client']) {
    this.client = S3Client;
  }

  setParams(params: UploaderOptions['params']) {
    this.params = params;
  }

  setBucket(bucket: string) {
    this.params.Bucket = bucket;
  }

  setUploadToken(uploadToken: string) {
    this.params.Metadata.uploadToken = uploadToken;
  }

  get chunks() {
    return this.getChunkSizes();
  }

  done() {
    return new Promise<void>((resolve, reject) => {
      this.doneResolve = resolve;
      this.doneReject = reject;
      
      this.state = FileState.UPLOADING;
      try {
        const chunksCount = this.getChunkCount(this.params.Body);
        if(chunksCount > 1) {
          this.uploadMultipart(chunksCount);
        } else {
          this.uploadSingle();
        }
      } catch(error) {
        this.state = FileState.ERROR;
        this.doneReject(error);
      }
    });
  }

  pause() {
    this.state = FileState.PAUSED;
    this.queue.pause();
  }

  resume() {
    this.state = FileState.UPLOADING;
    this.queue.start();
  }

  cancel() {
    try {
      this.state = FileState.CANCELLED;
      this.abortController.abort();
      this.client.config.requestHandler.destroy();
      this.queue.clear();
    } catch(error) {}
  }

  private getChunkCount(data: BodyDataTypes): number {
    // We currently only support uploading from a File (Blob)
    if(data instanceof Blob) {
      return Math.ceil(data.size / this.partSize);
    }

    throw new Error("Unsupported data type");
  }

  private getChunkSizes(): Array<number> {
    const chunksCount = this.getChunkCount(this.params.Body);
    const chunkSizes = new Array(chunksCount).fill(this.partSize);

    if(this.params.Body instanceof Blob) {
      const lastChunkSize = this.params.Body.size % this.partSize;
      if(lastChunkSize) {
        chunkSizes[chunkSizes.length - 1] = lastChunkSize;
      }
    }

    return chunkSizes;
  }

  private getChunkSize(partNumber: number) {
    return this.chunks[partNumber - 1];
  }

  private uploadMultipart(chunksCount: number) {
    this.createMultiPartUpload().then(response => {
      this.uploadId = response.UploadId;
      for(let i = 0; i < chunksCount; i++) {
        this.queue.add(() => {
          return this.uploadPart(i + 1, this.params.Body);
        });
      }

      this.queue.start()
      .addListener("error", error => {
        this.queue.removeAllListeners().clear();
        this.doneReject(error);
      })
      .addListener("idle", () => {
        this.completeMultiPartUpload();
      });
    })
  }

  private uploadSingle() {
    this.queue.add(() => {
      this.client.config.requestHandler = new XhrHttpHandler({});
      if(this.client.config.requestHandler instanceof EventEmitter) {
        this.client.config.requestHandler.on("xhr.upload.progress", (progress: ProgressEvent) => {
          this.emit("httpUploadProgress", {
            key: this.params.Key,
            part: 1,
            loaded: progress.loaded,
            total: progress.total,
          });
        });
      }

      return new Promise((resolve, reject) => {
        this.client.send(new PutObjectCommand(this.params))
        .then(result => {
          this.emit("httpUploadProgress", {
            key: this.params.Key,
            part: 1,
            loaded: (this.params.Body as Blob).size,
            total: (this.params.Body as Blob).size,
          });

          resolve(result);
        })
        .catch(reject)
      });
    });

    this.queue.start()
    .addListener("error", error => {
      if(this.client.config.requestHandler instanceof EventEmitter) {
        this.client.config.requestHandler.removeAllListeners("xhr.upload.progress");
      }
      this.queue.removeAllListeners().clear();
      this.state = FileState.ERROR;
      this.doneReject(error);
    })
    .addListener("idle", () => {
      if(this.client.config.requestHandler instanceof EventEmitter) {
        this.client.config.requestHandler.removeAllListeners("xhr.upload.progress");
      }
      this.state = FileState.DONE;
      this.doneResolve();
    });
  }

  private createMultiPartUpload(): Promise<CreateMultipartUploadCommandOutput> {
    return this.client.send(new CreateMultipartUploadCommand(this.params));
  }

  private uploadPart(partNumber: number, data: BodyDataTypes) {
    if(this.state === FileState.CANCELLED) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      this.client.config.requestHandler = new XhrHttpHandler({});
      if(this.client.config.requestHandler instanceof EventEmitter) {
        this.client.config.requestHandler.on("xhr.upload.progress", (progress: ProgressEvent) => {
          this.emit("httpUploadProgress", {
            key: this.params.Key,
            part: partNumber,
            loaded: progress.loaded,
            total: progress.total,
          });
        });
      }

      this.client.send(new UploadPartCommand({
        ...this.params,
        UploadId: this.uploadId,
        PartNumber: partNumber,
        Body: this.getPartData(data, partNumber),
      }))
      .then(response => {
        if(this.client.config.requestHandler instanceof EventEmitter) {
          this.client.config.requestHandler.removeAllListeners("xhr.upload.progress");
        }

        this.emit("httpUploadProgress", {
          key: this.params.Key,
          part: partNumber,
          loaded: this.getChunkSize(partNumber),
          total: this.getChunkSize(partNumber),
        });

        this.uploadedParts.push({
          PartNumber: partNumber,
          ETag: response.ETag,
          ...(response.ChecksumCRC32 && { ChecksumCRC32: response.ChecksumCRC32 }),
          ...(response.ChecksumCRC32C && { ChecksumCRC32C: response.ChecksumCRC32C }),
          ...(response.ChecksumSHA1 && { ChecksumSHA1: response.ChecksumSHA1 }),
          ...(response.ChecksumSHA256 && { ChecksumSHA256: response.ChecksumSHA256 }),
        });
        resolve();
      })
      .catch(error => {
        if(this.client.config.requestHandler instanceof EventEmitter) {
          this.client.config.requestHandler.removeAllListeners("xhr.upload.progress");
        }
        reject(error);
      });
    });
  }

  private completeMultiPartUpload() {
    if(this.state === FileState.CANCELLED) {
      return this.doneResolve();;
    }

    this.uploadedParts.sort((a, b) => a.PartNumber! - b.PartNumber!);

    this.client.send(new CompleteMultipartUploadCommand({
      ...this.params,
      UploadId: this.uploadId,
      MultipartUpload: {
        Parts: this.uploadedParts,
      },
    }))
    .then(() => {
      this.state = FileState.DONE;
      this.doneResolve();
    })
    .catch(error => {
      this.doneReject(error);
    });
  }

  private getPartData(data: BodyDataTypes, partNumber: number) {
    if(data instanceof Blob) {
      return data.slice((partNumber - 1) * this.partSize, partNumber * this.partSize)
    }

    return null;
  }
}