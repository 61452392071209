import dynamic from "next/dynamic";
import FixedLoader from 'components/FixedLoader';

function AlbumMoreModal(props) {
  const version = props.album.length === 12 ? 'legacy' : 'v4';

  if(version==='v4')
    return <NewAlbumMoreModal {...props}/>
  else
    return <LegacyAlbumMoreModal {...props} />
}

const NewAlbumMoreModal = dynamic(() => import("./NewAlbum"), {
  ssr: false,
  loading: () => <FixedLoader />,
});
const LegacyAlbumMoreModal = dynamic(() => import("./LegacyAlbum"), {
  ssr: false,
  loading: () => <FixedLoader />,
});

export default AlbumMoreModal;