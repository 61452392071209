let index = 0;

export default class Model
{
  constructor(props, store, resolve)
  {
    this.key = "Toast-" + index++;
    this.store = store;
    this.resolve = resolve;
    this.props = this.initWithDefaults(props);

    if(this.props.autoHide)
    {
      this.hideTimeRemaining = this.props.autoHideDelay;
      this.startTimer(this.props.autoHideDelay);
    }
  }

  initWithDefaults(props)
  {
    return Object.assign({
      message: "",
      autoHide: true,
      autoHideDelay: 5000,
      buttons: [],
      className: "",
      closeButton: true,
    }, props);
  }

  close()
  {
    this.store.remove(this.key);
  }

  startTimer(time)
  {
    this.hideTimeStart = new Date().getTime();
    this.hideTimer = setTimeout(() =>
    {
      this.close();
    }, time);
  }

  pauseTimer()
  {
    if(this.props.autoHide)
    {
      clearTimeout(this.hideTimer);

      let currentTime = new Date().getTime();
      this.hideTimeRemaining = this.hideTimeStart + this.hideTimeRemaining - currentTime;
    }
  }

  resumeTimer()
  {
    if(this.props.autoHide && this.hideTimeRemaining > 0)
      this.startTimer(this.hideTimeRemaining);
  }

  get message()
  {
    return this.props.message;
  }

  get icon()
  {
    return this.props.icon;
  }

  get buttons()
  {
    return this.props.buttons;
  }

  get className()
  {
    return this.props.className;
  }
}