import { v4 as uuidv4 } from 'uuid';
import { AbortController } from '@aws-sdk/abort-controller';
import type { S3Client } from "@aws-sdk/client-s3";
import { FileError, FileState, UploadErrorState } from "./state";
import { FileHistoryState, UploadPartProgress, UploadProgress } from './types';
import { CHUNCK_CONCURRENCY, MAX_CHUNKS, MB, STALE_RETRIES, STALE_RETRY_DELAY, STALE_TIMEOUT } from './constants';
import Uploader from './Uploader';
import { Signals } from 'hooks/useSignals';
import UploadError from './UploadError';

export default class UploadFile {
  _file: File;
  key: string;
  error: FileError;
  
  private progressParts: Record<number, {loaded: number, total: number}> = {};

  private staleTimer: NodeJS.Timer;
  private staleCount: number = 0;

  private states: Array<FileHistoryState> = [];
  private uploadResolve: () => void;
  private uploadReject: (error: Error) => void;

  private abortController = new AbortController();
  
  private uploader: Uploader;

  constructor(file: File) {
    this.key = uuidv4();
    this._file = file;

    this.setState(FileState.WAITING);
    this.uploader = new Uploader({
      params: {
        Bucket: "", // Will be overridden in `upload` method
        Key: this.key,
        Metadata: {
          filename: JSON.stringify(this._file.name),
          lastModified: String(this._file.lastModified),
          timezoneOffset: String(new Date().getTimezoneOffset()),
        },
        Body: this._file
      },
      queueSize: CHUNCK_CONCURRENCY,
      partSize: this.chunkSize,
      abortController: this.abortController,
    });
  }

  get size() {
    return this._file.size;
  }

  get name() {
    return this._file.name;
  }

  get progress(): UploadProgress {
    const loadedPercentParts: number = Object.values(this.progressParts).reduce((acc, part) => {
      acc += (part.loaded / part.total);
      return acc;
    }, 0);
    const partsCount = this.chunkCount;
    const loadedPercent = loadedPercentParts / partsCount;

    return {
      loaded: this.size * loadedPercent,
      total: this.size,
    };
  }

  get chunkCount() {
    return Math.max(1, Math.ceil(this.size / this.chunkSize));
  }

  get chunkSize() {
    return Math.max(5 * MB, Math.ceil(this.size / MAX_CHUNKS));
  }

  get state() {
    return this.states[this.states.length - 1].state;
  }

  set state(state: FileState) {
    this.states.push({
      date: new Date(),
      state,
    });
  }

  get statesHistory() {
    return this.states;
  }

  upload(s3Client: S3Client, bucket: string, uploadToken: string) {
    this.uploader.setClient(s3Client);
    this.uploader.setBucket(bucket);
    this.uploader.setUploadToken(uploadToken);

    return new Promise<void>((resolve, reject) => {
      this.uploadResolve = resolve;
      this.uploadReject = reject;
      this.doUpload();

      this.state = FileState.UPLOADING;
    });
  }

  pause() {
    this.state = FileState.PAUSED;
    this.uploader.pause();
    this.stopStaleTimer();
  }

  resume() {
    this.state = FileState.UPLOADING;
    this.uploader.resume();
    this.startStaleTimer();
  }

  stopUpload() {
    this.uploader.removeAllListeners("httpUploadProgress");
    this.stopStaleTimer();
    this.abortController.abort();
    this.uploader.cancel();
  }

  setError(error: FileError) {
    this.state = FileState.ERROR;
    this.error = error;
  }

  setState(state: FileState) {
    this.state = state;
  }

  private stopStaleTimer() {
    if(this.staleTimer) {
      clearTimeout(this.staleTimer);
    }
  }

  private startStaleTimer() {
    this.stopStaleTimer();

    if(this.state === FileState.PAUSED) {
      return;
    }

    this.staleTimer = setTimeout(() => {
      this.abortController.abort();
      this.state = FileState.STALE;
      setTimeout(() => {
        if(this.staleCount < STALE_RETRIES) {
          this.staleCount++;

          console.debug(`Retry after stale upload, attempt ${this.staleCount + 1} of ${STALE_RETRIES}`);
          this.doUpload();
        } else {
          this.uploadReject(new UploadError(UploadErrorState.STALE));
        }
      }, STALE_RETRY_DELAY)
    }, STALE_TIMEOUT);
  }

  private doUpload() {
    this.uploader.chunks.forEach((chunkSize: number, index: number) => {
      const part = index + 1;
      this.progressParts[part] = {
        loaded: 0,
        total: chunkSize,
      }
    });

    this.uploader.on("httpUploadProgress", (progress: UploadPartProgress) => {
      this.startStaleTimer();
      this.progressParts[progress.part] = {
        loaded: progress.loaded,
        total: progress.total,
      };

      Signals.emit(`upload-part-progress`);
    });

    this.uploader.done()
    .then(() => {
      if(this.state !== FileState.CANCELLED) {
        this.state = FileState.DONE;
      }
      this.stopStaleTimer();
      this.uploadResolve();
    })
    .catch(error => {
      if(this.state !== FileState.CANCELLED) {
        this.state = FileState.ERROR;
      }
      this.stopStaleTimer();
      this.uploadReject(error);
    });

    this.startStaleTimer();
  }
}