import FileResult from "./FileResult";
import { UploadErrorState, UploadState } from "./state";
import { UploadResultProps } from "./types";

export default class UploadResult {
  state: UploadState;
  errorState: UploadErrorState;
  errorString: string;

  uploadTimeMs: number;
  processTimeMs: number;

  files: Array<FileResult>;

  constructor(data: UploadResultProps) {
    this.state = data.state;
    this.errorState = data.errorState;
    this.errorString = data.errorString;

    this.uploadTimeMs = isNaN(data.uploadTimeMs) ? 0 : data.uploadTimeMs;
    this.processTimeMs = isNaN(data.processTimeMs) ? 0 : data.processTimeMs;

    this.files = data.files;
  }
}