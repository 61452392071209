import Events from './EventsOld';
import { throttle } from 'throttle-debounce';
const isServerSide = typeof window === 'undefined';

class DragService {
  sources = [];
  targets = [];

  isDragging = false;
  isFrozen = false;
  dragSource = undefined;
  dragOver = undefined;
  dragData = {
    sourceData: {},
    targetData: {},
  };
  config = {
    minDragDistance: 1,
    freezeTime: 900,
    dragDelay: 500,
  }

  constructor() {
    new Events(this);

    if(isServerSide)
      return;

    this.cancel = this.cancel.bind(this);
    this.endMove = this.endMove.bind(this);
    this.move = throttle(25, this.move.bind(this));
    this.endDrag = this.endDrag.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.keyUp = this.keyUp.bind(this);
    this.canDrop = this.canDrop.bind(this);
    this.scroll = this.scroll.bind(this);
    this.click = this.click.bind(this);
    this.preventScrollMove = this.preventScrollMove.bind(this);

    window.addEventListener('keydown', this.keyDown);
    window.addEventListener('keyup', this.keyUp);
    window.addEventListener('scroll', this.scroll, { passive: true });
    window.addEventListener('click', this.click, { passive: false });

    switch(this.inputType) {
      case "pointer":
        window.addEventListener('pointermove', this.move, { passive: true });
        window.addEventListener('pointerup', this.endMove, { passive: true });
        window.addEventListener('touchmove', this.preventScrollMove, { passive: false });
      break;
      case "touch":
        window.addEventListener('touchmove', this.move, { passive: true });
        window.addEventListener('touchend', this.endMove, { passive: true });
      break;
      default:
        window.addEventListener('mouseup', this.endMove);
        window.addEventListener('mousemove', this.move);
      break;
    }

    this.dragUpdate = throttle(25, this.dragUpdate.bind(this));
    this.throttleTrigger = throttle(25, this.trigger.bind(this));
    this.on('dragMove', (data) => {
      if(this.isDragging)
      {
        let dragResult = this.dragSource.config.dragMove(data, (config) => {
          if(config!==undefined)
          {
            if(this.dragData.sourceData.config===undefined)
              this.dragData.sourceData.config = {};

            this.dragData.sourceData = {...this.sourceData, ...config};
          }
        });

        if(dragResult!==undefined)
          this.dragData.sourceData = {...this.sourceData, ...dragResult};
      }
    });
  }

  get inputType() {
    if(window.PointerEvent)
      return 'pointer';
    else if('ontouchstart' in window || navigator.msMaxTouchPoints)
      return 'touch';
    else
      return 'mouse';
  }

  get offset() {
    return this.dragData.current;
  }

  get sourceData() {
    return (this.dragData.sourceData===undefined) ? {} : this.dragData.sourceData;
  }

  get moveData() {
    return {
      offset: {...this.offset},
      beginOffset: {...this.beginOffset},
      keyDown: this.currentKeyDown,
      canDrop: this.canDrop.bind(this),
      sourceData: this.sourceData,
      overData: this.dragOver ? this.dragOver.config.data : {},
      action: this.action,
    }
  }

  setAction(action) {
    this.forceAction = action;
  }

  get action() {

    const defaultAction = this.forceAction!==undefined ? this.forceAction : 'move';
    const forceSwitch = (this.currentKeyDown===18 || this.currentKeyDown==='ctrl' || this.isFrozen);
    const action = forceSwitch ? 'copy' : defaultAction;

    return action;
  }

  addSource(type, domNode, data) {
    const found = this.findSourceByTypeAndNode(type, domNode);
    if(found.length>0)
      return found;

    const dragSource = new DragSource(this, type, domNode, data);
    this.sources.push(dragSource);

    return {
      destroy: dragSource.destroy.bind(dragSource)
    }
  }

  removeSource(dragSource) {
    this.sources = [...this.sources].filter(source => {
      let keep = dragSource!==source;
      return keep;
    });
  }

  addTarget(type, domNode, data) {
    const found = this.findTargetByTypeAndNode(type, domNode);
    if(found.length>0)
      return found;

    const dragTarget = new DragTarget(this, type, domNode, data);
    this.targets.push(dragTarget);

    return {
      destroy: dragTarget.destroy.bind(dragTarget)
    }
  }

  removeTarget(dragTarget) {
    if(this.dragTarget && this.dragTarget===dragTarget)
      this.dragTarget = undefined;

    this.targets = [...this.targets].filter(target => {
      let keep = dragTarget!==target;
      return keep;
    });
  }

  findSourceByTypeAndNode(type, domNode) {
    return this.sources.filter( source => {
      return source.domNode===domNode && source.type===type;
    });
  }
  findTargetByTypeAndNode(type, domNode) {
    return this.targets.filter( target => {
      return target.domNode===domNode && target.type===type;
    });
  }

  isNotOver(source) {
    if(this.dragOver===source)
    {
      this.dragOver = undefined;
      this.setAction(undefined);
    }
  }

  isOver(source) {
    if(source.type===this.dragSource.type)
    {
      this.dragOver = source;
      const result = source.config.isOver(true, {
        ...this.moveData
      });

      if(result!==undefined)
        this.setAction(result.action);

      this.dragOver = source;
    }
  }

  disAllowDrag() {
    if(this.dragDelayTimer)
      clearTimeout(this.dragDelayTimer);
  }

  allowDrag(source, delay) {
    if(this.dragDelayTimer)
      clearTimeout(this.dragDelayTimer);

    if(delay)
    {
      this.dragData.delay = {...this.offset};
      this.dragDelayTimer = setTimeout(() => {
        this._allowDrag(source);
        this.startDrag();
      }, this.config.dragDelay);
    }else
    {
      this._allowDrag(source);
    }
  }

  _allowDrag(source) {
    this.dragData.start = {...this.offset};
    this.dragSource = source;
  }

  allowDrop(target) {
    this.dragTarget = target;
    this.endDrag();
  }

  endMove() {
    this.endDrag();
  }

  cancel() {
    if(this.dragDelayTimer)
      clearTimeout(this.dragDelayTimer);

    this.endDrag(true);
  }

  getOffset(e) {
    return {
      x: e.clientX,
      y: e.clientY
    };
  }

  click(e) {
    if(this.wasDraggingRecently)
    {
      // eerste click direct na slepen negeren voorkomt triggeren van ongewenste click na sleepactie
      e.target.style.pointerEvents = 'none';
      e.preventDefault();

      requestAnimationFrame(() => {
        e.target.style.pointerEvents = '';
      });
    }
  }

  preventScrollMove(e) {
    if(this.isDragging)
      e.preventDefault();
  }

  move(e) {
    this.dragData.current = this.getOffset(e);
    const dragDelayDiff = this.getDiff(this.dragData.delay, this.dragData.current);
    const minDragDistance = this.getMinDragDistance();
    if(this.dragDelayTimer && (Math.abs(dragDelayDiff.x)>minDragDistance || Math.abs(dragDelayDiff.y)>minDragDistance))
    {
      // Too much movement since freezeTimer started
      this.disAllowDrag();
      this.cancel();
      return;
    }

    if(!this.dragSource)
      return; // Nothing to drag

    const freezeDiff = this.getDiff(this.dragData.freeze, this.dragData.current);
    if(Math.abs(freezeDiff.x)>minDragDistance || Math.abs(freezeDiff.y)>minDragDistance)
    {
      // Too much movement since freezeTimer started
      this.stopFreezeTimer();
    }

    const diff = this.getDiff(this.dragData.start, this.dragData.current);
    if(Math.abs(diff.x)>minDragDistance || Math.abs(diff.y)>minDragDistance)
    {
      this.startDrag();
      this.autoScrollIfNeeded();
      this.throttleTrigger('dragMove', this.moveData);
    }

    this.startFreezeTimer();
  }

  autoScrollIfNeeded() {
    if(!this.isDragging || (this.dragData.sourceData.autoScroll===undefined || !this.dragData.sourceData.autoScroll))
      return;

    const extra = {
      top: 65,
      bottom: 0,
    }

    if(this.dragData.sourceData.autoScroll.top)
      extra.top = this.dragData.sourceData.autoScroll.top;

    if(this.dragData.sourceData.autoScroll.bottom)
      extra.bottom = this.dragData.sourceData.autoScroll.bottom;

    const scrollArea = 50;
    const y = this.moveData.offset.y;
    if(y<scrollArea + extra.top)
      this.autoScroll('up');
    else if(y>window.innerHeight - scrollArea - extra.bottom)
      this.autoScroll('down');
    else
      this.autoScroll(false);
  }

  autoScroll(direction) {
    if(this._autoScrollDisposer)
    {
      if(this._autoScrollDirection===direction)
        return;

      this._autoScrollDisposer();
    }

    this._autoScrollDirection = direction;
    this._autoScrollDisposer = autoScrollTo(direction);
  }

  onWindowScroll = () => {
    window.scrollTo(this.windowScrollLeft, this.windowScrollTop);
  }

  preventScroll() {
    this.windowScrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    this.windowScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    window.addEventListener('scroll', this.onWindowScroll, { passive: false });
  }

  allowScroll() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  startDrag() {
    if(!this.isDragging)
    {
      this.dragDelayTimer = undefined;
      this.isDragging = true;
      this.wasDraggingRecently = true;
      this.beginOffset = {
        ...this.dragData.start
      }

      this.dragData.sourceData = this.dragSource.config.beginDrag({
        offset: {...this.dragData.start},
        keyDown: this.currentKeyDown,
      });

      if(this.dragData.sourceData.autoScroll === undefined) {
        this.preventScroll();
      }

      this.trigger('dragStart', {
        offset: {...this.dragData.current},
        type: this.dragSource.type,
        sourceData: {...this.sourceData}
      });
    }
  }

  scroll() {
    if(this.isDragging && !this.freezeTimer)
    {
      this.startFreezeTimer();
    }

    if(!this.isDragging)
      this.disAllowDrag();
  }

  startFreezeTimer = () => {
    if(!this.freezeTimer)
    {
      this.dragData.freeze = {...this.dragData.current};
      this.freezeTimer = setTimeout(() => {
        if(this.isFrozen)
          return;

        this.isFrozen = true;
        this.dragUpdate();
      }, this.config.freezeTime);
    }
  }

  stopFreezeTimer = () => {
    this.isFrozen = false;

    if(this.freezeTimer)
    {
      clearTimeout(this.freezeTimer);
      this.freezeTimer = undefined;

      this.dragUpdate();
    }
  }

  dragUpdate() {
    if(this.isDragging) {
      this.trigger('dragMove', this.moveData);
    }
  }

  canDrop() {
    return this.dragOver && this.dragOver.config.canDrop({
      offset: this.dragData.current,
      sourceData: this.sourceData,
      keyDown: this.currentKeyDown,
      action: this.action
    })
  }

  getDiff(first, second) {
    if(first===undefined || second===undefined)
      return {x: 0, y: 0};

    return {
      x: (first && second) ? second.x - first.x : 0,
      y: (first && second) ? second.y - first.y : 0,
    };
  }

  getMinDragDistance()
  {
    if(this.dragSource)
      return this.dragSource.config.minDragDistance ?? this.config.minDragDistance;
  }

  endDrag(cancelled) {
    const result = {
      didDrop: false,
      offset: this.dragData.current,
      sourceData: this.sourceData,
      target: this.dragTarget,
      keyDown: this.currentKeyDown,
      action: this.action,
      didCancel: cancelled,
    };

    const diff = this.getDiff(this.dragData.start, this.dragData.current);
    const minDragDistance = this.getMinDragDistance();
    if(!cancelled && (Math.abs(diff.x)>minDragDistance || Math.abs(diff.y)>minDragDistance))
    {
      // Ole! We dragged at least the minimum distance
      if(this.dragTarget)
      {
        result.targetData = this.dragTarget.config.drop({
          ...result
        });

        result.didDrop = true;
      }
    }

    this.dragSource && this.dragSource.config.endDrag(result);
    this.dragOver && this.dragOver.config.isOver(false);

    this.trigger('dragEnd', result);
    this.reset();
  }

  keyDown(e) {
    this.currentKeyDown = e.keyCode;
    if(e.keyCode===27)
    {
      this.cancel();
    }

    if(e.ctrlKey)
      this.currentKeyDown = 'ctrl';

    this.dragUpdate();
  }

  keyUp() {
    this.currentKeyDown = undefined;

    this.dragUpdate();
  }

  reset() {
    this.dragData = {
      sourceData: {},
      targetData: {},
    };
    this.dragSource = undefined;
    this.dragTarget = undefined;
    this.dragOver = undefined;
    this.isDragging = false;
    this.forceAction = undefined;

    if(this._autoScrollDisposer)
    {
      this._autoScrollDisposer();
      this._autoScrollDisposer = undefined;
    }

    requestAnimationFrame(() => {
      this.wasDraggingRecently = false;
    });

    this.stopFreezeTimer();

    this.allowScroll();
  }
}

class DragSource {
  constructor(service, type, domNode, config) {
    this.service = service;
    this.type = type;
    this.domNode = domNode;

    const minConfig = {
      canDrag: () => {},
      beginDrag: () => {},
      dragMove: () => {},
      endDrag: () => {},

      data: {},

      minDragDistance: 1,
    }
    this.config = {...minConfig, ...config};

    this.initDrag = this.initDrag.bind(this);
    this.cancelDrag = this.cancelDrag.bind(this);
    this.addEvents();
  }

  destroy() {
    this.domNode.removeEventListener('mousedown', this.initDrag);
    this.domNode.removeEventListener('pointerdown', this.initDrag);
    this.domNode.removeEventListener('touchstart', this.initDrag);
    this.domNode.removeEventListener('pointerup', this.cancelDrag);
    this.service.removeSource(this);
  }

  addEvents() {
    switch(this.service.inputType)
    {
      case "pointer":
        this.domNode.addEventListener('pointerdown', this.initDrag);
        this.domNode.addEventListener('pointerup', this.cancelDrag);
      break;
      case "touch":
        this.domNode.addEventListener('touchstart', this.initDrag);
      break;
      default:
        this.domNode.addEventListener('mousedown', this.initDrag);
      break;
    }
  }

  cancelDrag() {
    this.service.disAllowDrag(this);
  }

  initDrag(e) {
    if("pointerId" in e)
    {
      if(e.pointerType!=='mouse')
        document.body.setPointerCapture(e.pointerId); // anders kan safari ios niet meer slepen

      this.domNode.releasePointerCapture(e.pointerId);
    }

    let isRightMouseButton = false;
    if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMouseButton = e.which === 3;
    else if ("button" in e)  // IE, Opera 
      isRightMouseButton = e.button === 2;

    if(isRightMouseButton)
      return;

    this.service.move(e);
    if(this.config.canDrag({
      offset: this.service.offset,
      keyDown: this.service.currentKeyDown,
      event: e,
    }))
    {
      //e.preventDefault(); niet doen, hierdoor krijg je geen enkele mouseup event meer in je hele app
      const delayAllowDrag = (("pointerType" in e && e.pointerType!=='mouse') || this.service.inputType==='touch');
      this.service.allowDrag(this, delayAllowDrag);
    }
  }
}

class DragTarget {
  constructor(service, type, domNode, config) {
    this.service = service;
    this.type = type;
    this.domNode = domNode;
    this.data = {};

    const minConfig = {
      canDrop: () => {},
      isOver: () => {},
      drop: () => {}
    }
    this.config = {...minConfig, ...config};
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onLeave = this.onLeave.bind(this);

    this.addEvents();
  }

  destroy() {
    this.domNode.removeEventListener('pointerup', this.onMouseUp);
    this.domNode.removeEventListener('pointermove', this.onMove);
    this.domNode.removeEventListener('pointerleave', this.onLeave);

    this.domNode.removeEventListener('touchend', this.onMouseUp);
    this.domNode.removeEventListener('touchmove', this.onMove);
    this.domNode.removeEventListener('touchleave', this.onLeave);

    this.domNode.removeEventListener('mouseup', this.onMouseUp);
    this.domNode.removeEventListener('mousemove', this.onMove);
    this.domNode.removeEventListener('mouseleave', this.onLeave);

    this.service.removeTarget(this);
  }

  addEvents() {
    switch(this.service.inputType)
    {
      case "pointer":
        this.domNode.addEventListener('pointerup', this.onMouseUp);
        this.domNode.addEventListener('pointermove', this.onMove);
        this.domNode.addEventListener('pointerleave', this.onLeave);
      break;
      case "touch":
        this.domNode.addEventListener('touchend', this.onMouseUp);
        this.domNode.addEventListener('touchmove', this.onMove);
        this.domNode.addEventListener('touchleave', this.onLeave);
      break;
      default:
        this.domNode.addEventListener('mouseup', this.onMouseUp);
        this.domNode.addEventListener('mousemove', this.onMove);
        this.domNode.addEventListener('mouseleave', this.onLeave);
      break;
    }
  }

  onMove(e) {
    if(!this.service.isDragging)
      return;

    this.service.move(e);
    this.service.isOver(this);
  }

  onLeave() {    
    if(!this.service.isDragging)
      return;

    this.config.isOver(false);
    this.service.isNotOver(this);
  }

  onMouseUp(e) {
    if(!this.service.isDragging)
      return;

    this.service.move(e);
    this.config.isOver(false);
    e.preventDefault();

    if(this.service.canDrop())
    {
      this.service.allowDrop(this);
    }
  }
}

function autoScrollTo(direction) {
  let lastRun = null;
  let run = true;

  if(direction!==false)
    scroll();

  function stop() {
    run = false;
  }

  function scroll() {
    const now = new Date().getTime();
    if(lastRun!==null)
    {
      const duration = now - lastRun;
      let speed = duration/3;
      if(direction==='down')
      {
        window.scrollBy(0, speed);
      }else
      {
        window.scrollBy(0, 0 - speed);
      }
    }

    requestAnimationFrame(() => {
      if(run)
        scroll();
    });

    lastRun = new Date().getTime();
  }


  return () => {
    stop();
  }
}

const service = new DragService();
class DragInterface {
  addSource = service.addSource.bind(service);
  addTarget = service.addTarget.bind(service);
  on = service.on.bind(service);
  off = service.off.bind(service);
  one = service.one.bind(service);
  isDragging = () => { return service.wasDraggingRecently};
}

const dragInterface = new DragInterface();
export default dragInterface;