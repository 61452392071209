import FixedLoader from 'components/FixedLoader';
import useSignals from 'hooks/useSignals';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useUser } from 'utils/user/lib';

const StreamMoreModalContent = dynamic(() => import("./Modal"), {
  ssr: false,
  loading: () => <FixedLoader />,
});

type StreamMoreModalProps = {
  streamKey: string;
  initialStack?: InitialStack;
  onClose: () => void;
}

type StreamData = {
  key: string;
  access: AccessData;
  me: MeData;
}

export default function StreamMoreModal({streamKey, initialStack, onClose}: StreamMoreModalProps) {
  const user = useUser();
  const signal = useSignals();

  const [stream, setStream] = useState<StreamData>();
  const [error, setError] = useState();

  useEffect(() => {
    const promises = [];
    promises.push(user.FetchJSON(`${process.env.API_URL}/access/stream-${streamKey}/accessData`)[0]);
    promises.push(user.FetchJSON(`${process.env.API_URL}/stream/${streamKey}/me`)[0]);

    Promise.all(promises)
    .then(([{data: access}, {data: me}]) => {
      setStream({
        key: streamKey,
        access,
        me,
      });
    })
    .catch(e => {
      console.error(e);
      setError(e);
    })

  }, [streamKey, user]);

  useEffect(() => {
    return signal.on(`stream-${streamKey}-title-updated`, (title: string) => {
      setStream(current => {
        return {
          ...current,
          access: {
            ...current.access,
            subject: {
              ...current.access.subject,
              title
            }
          }
        };
      });
    });
  }, [signal, streamKey]);

  
  if(error) {
    return null;
  }

  if(!stream) {
    return <FixedLoader />
  }

  return <StreamMoreModalContent stream={stream} initialStack={initialStack} onClose={onClose} />;
}