import { v4 as uuidv4 } from 'uuid';
import UploadSession from './S3Upload/UploadSession';

export default class UploadStore {
  private uploads: Array<UploadSession> = [];

  get(key?: string) {
    if(key) { // Try to get upload session by key
      const foundUpload = this.uploads.find(upload => upload.key === key);
      if (foundUpload) {
        return foundUpload;
      }
    } else if (this.uploads.length > 0) {
      return this.uploads[this.uploads.length - 1]; // Return last upload session
    } else {
      key = uuidv4();
    }

    const newUpload = new UploadSession(key);
    this.uploads.push(newUpload);
    return newUpload;
  }
}