import { Loader } from "components/UI";
import ClientOnlyPortal from "./ClientOnlyPortal";

function FixedLoader() {
  return (
    <ClientOnlyPortal
      selector="body"
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999999,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: 20,
            borderRadius: 100,
            boxShadow: '0 4.1px 8px rgba(0, 0, 0, 0.08), 0 4.3px 7.6px rgba(0, 0, 0, 0.028), 0 0.5px 12.6px rgba(0, 0, 0, 0.035), 0 0.9px 25.2px rgba(0, 0, 0, 0.042), 0 1.7px 47px rgba(0, 0, 0, 0.05), 0 4px 80px rgba(0, 0, 0, 0.07)'
          }}
        >
          <Loader />
        </div>
      </div>
    </ClientOnlyPortal>
  )
}

export default FixedLoader;