import React, { useEffect } from "react";
import ThemeStore from '../store';
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dnd from 'utils/DnD';
import styles from './style';
import modalStore from "./store";
import {OverscrollContain} from 'components/UI';
import Confirm from './Confirm';
import Alert from './Alert';
import ButtonPrompt from './ButtonPrompt';
import Prompt from './Prompt';
import Loader from './Loader';
import Progress from './Progress';
import Help from './Help';
import useIsFrontMost from "hooks/useIsFrontMost";

/**
 * @param {Object} [props]
 * @param {Boolean} [props.allowClose]
 * @param {Boolean} [props.allowBodyScroll]
 * @param {Boolean} [props.draggable]
 * @param {Function} [props.onClose]
 * @param {Function} [props.onKey]
*/
const Modal = React.forwardRef((props, ref) => {
  const isMounted = React.useRef(false);
  const scrollRef = React.useRef();
  const savedRootRef = React.useRef();
  const rootRef = React.useRef();
  const dragRef = React.useRef();
  const dialogRef = React.useRef();
  const [position, setPosition] = React.useState(null);
  const [size, setSize] = React.useState(null);
  const [viewportHeight, setViewportHeight] = React.useState(null);
  const [viewportTop, setViewportTop] = React.useState(null);
  const [classNames, setClassNames] = React.useState(['ui-modal']);
  const theme = {
    overlayColor: props.overlayColor || ThemeStore.theme.overlayColor,
    overlayOpacity: props.overlayOpacity || ThemeStore.theme.overlayOpacity,
    backgroundColor: props.backgroundColor || ThemeStore.theme.pane1,
    textColor: props.textColor || ThemeStore.theme.color1,
    accentColorDark: props.accentColorDark || ThemeStore.theme.accentColorDark,
    accentColorMedium: props.accentColorMedium || ThemeStore.theme.accentColorMedium,
    accentColorLight: props.accentColorLight || ThemeStore.theme.accentColorLight,
    iconClose: props.closeIcon || faTimes
  }
  useIsFrontMost();

  useEffect(() => {
    isMounted.current = true;

    if(props.onOpen) {
      props.onOpen();
    }

    return () => {
      isMounted.current = false;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function dialogClick(e) {
    e.stopPropagation();
  }

  function backgroundClick() {
    if(props.allowClose && !props.floating) {
      onClose();
    }
  }

  function onClose(data) {
    if(isMounted.current)
    {
      document.activeElement.blur();
      props.onClose(data);
    }
  }

  function getChildByKey(key) {
    let children = props.children ?  Array.isArray(props.children) ? props.children : [props.children] : [];

    for(let child of children) {
      if(child.key===key)
      {
        return child.props.children;
      }
    }
  }

  function getOnlyChild()
  {
    if(!Array.isArray(props.children))
      return props.children;
    return null;
  }

  function allowTouch(e) {
    e.stopPropagation();
  }

  function touchStart(e) {
    preventBodyScroll(e);

    backgroundClick(e);
  }

  function preventBodyScroll(e) {
    if(!props.allowBodyScroll)
    {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function scrollTo(x, y) {
    if(!scrollRef.current)
      return;

    scrollRef.current.scrollTo(x, y)
  }

  let resizeFrame;
  function resizeViewport(event)
  {
    if(resizeFrame)
      cancelAnimationFrame(resizeFrame);

    resizeFrame = requestAnimationFrame(() =>
    {
      if(!isMounted.current || !rootRef.current)
        return;

      const maxHeight = event.target.height;
      if(maxHeight < rootRef.current.offsetHeight)
        setViewportHeight(maxHeight);
      else
        setViewportHeight(null);
    })
  }

  let scrollFrame;
  function scrollViewport(event)
  {
    if(scrollFrame)
      cancelAnimationFrame(scrollFrame);

    scrollFrame = requestAnimationFrame(() =>
    {
      if(!isMounted.current)
        return;

      setViewportTop(event.target.offsetTop);
    })
  }

  function getMaxViewportHeight()
  {
    let maxHeight = getViewportHeight();
    let viewportTop = getViewportTop();
    if(maxHeight && viewportTop)
      maxHeight += viewportTop;

    if(maxHeight !== null)
      maxHeight = Math.floor(maxHeight);

    return maxHeight
  }

  function getViewportHeight()
  {
    let height = null;
    if(viewportHeight && viewportHeight > 0)
      height = viewportHeight;

    return height;
  }

  function getViewportTop()
  {
    let top = null;
    if(viewportTop && viewportTop > 0)
      top = viewportTop;

    return top;
  }

  React.useImperativeHandle(ref, () => ({
    scrollTo,
  }));

  React.useEffect(() => {
    savedRootRef.current = rootRef.current;

    // Voorkom scrollen op body
    dialogRef.current.addEventListener('touchstart', allowTouch);
    rootRef.current.addEventListener('touchstart', touchStart);
    rootRef.current.addEventListener('wheel', preventBodyScroll);

    // iOS viewport fix for soft keyboard
    if(typeof window !== "undefined" && "visualViewport" in window)
    {
      window.visualViewport.addEventListener("resize", resizeViewport);
      window.visualViewport.addEventListener("scroll", scrollViewport);
    }

    // DND setup
    const dragSource = dnd.addSource("modal", dragRef.current, {
      canDrag: (data) => {
        data.event.preventDefault();
        return true;
      },
      beginDrag: (event) =>
      {
        // IVM performance, eenmalig de body opmeten en de size van de dialog
        // onthoud de startpositie
        const rect = dialogRef.current.getBoundingClientRect();
        const body = document.body.getBoundingClientRect();
        const startDragPosition = {
          x: event.offset.x,
          y: event.offset.y,
        }

        // Stel size van dialog hard in, voorkomt eventuele resize tijdens slepen
        setSize({
          width: rect.width,
          height: rect.height,
        })

        return {
          body: {width: body.width, height: body.height},
          startDialog: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          },
          startDragPosition,
        };
      },
      dragMove: (data) =>
      {
        // We weten de startpositie en kunnen dus berekenen hoeveel pixels we moeten verschuiven
        // Deze verschuiving passen we toe op de startPosition
        const body = data.sourceData.body;
        const startDialog = data.sourceData.startDialog;
        const startDragPosition = data.sourceData.startDragPosition;
        let x = startDialog.x + (data.offset.x - startDragPosition.x);
        let y = startDialog.y + (data.offset.y - startDragPosition.y);

        // Minimale x en y hanteren om te voorkomen dat men dialog buiten beeld sleept
        x = Math.min(Math.max(0-(startDialog.width-100), x), body.width - Math.min(100, startDialog.width));
        y = Math.min(Math.max(65, y), body.height - Math.min(100, startDialog.height));

        setPosition({x, y});
      },
      endDrag: () =>
      {
        // Width en height laten we nu weer over aan de content want we slepen niet meer
        setSize(null);
      }
    });

    const currentDialog = dialogRef.current;
    return function() {
      if(currentDialog)
        currentDialog.removeEventListener('touchstart', allowTouch);

      savedRootRef.current.removeEventListener('touchstart', touchStart);
      savedRootRef.current.removeEventListener('wheel', preventBodyScroll);

      if(dragSource)
        dragSource.destroy();

      if(typeof window !== "undefined" && "visualViewport" in window)
      {
        window.visualViewport.removeEventListener("resize", resizeViewport);
        window.visualViewport.removeEventListener("scroll", scrollViewport);
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() =>
  {
    if(rootRef)
    {
      let timeout = setTimeout(() =>
      {
        const _cssClasses = ['ui-modal', "visible"];
        if(props.stack)
          _cssClasses.push('stack');

        setClassNames(_cssClasses);
      }, 25);

      return () =>
      {
        clearTimeout(timeout);
      }
    }
  }, [rootRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const contentStyle = {
    overflowY: 'auto',
    position: 'relative',
    ...props.contentStyle,
  };
  const modalStyle = {
    maxHeight: getViewportHeight(),
    top: getViewportTop(),
    ...props.modalStyle,
  };
  if(position!==null) {
    modalStyle.position = "absolute";
    modalStyle.top = position.y;
    modalStyle.left = position.x;
  }
  if(size!==null) {
    modalStyle.width = size.width;
    modalStyle.height = size.height;
  }

  const header = getChildByKey('header');
  const footer = getChildByKey('footer');
  let content = getChildByKey('content');
  if(!header && !content) {
    content = getOnlyChild();
  }

  return (
    <div
      ref={rootRef}
      className={classNames.join(" ") + (props.className ? ` ${props.className}` : '') + (props.floating ? ' floating' : '')}
      style={{
        maxHeight: getMaxViewportHeight(),
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
      }}
    >
      <div key="dialog" onClick={dialogClick} className="dialog" ref={dialogRef} style={modalStyle}>
        <div key="drag-handle" className="drag-handle" style={!props.draggable ? {display: 'none'} : {}} ref={dragRef}></div>
        {
          props.allowClose &&
          <div
            key="close-button"
            onClick={() => {
              onClose();
            }}
            className="close"
          >
            <FontAwesomeIcon icon={theme.iconClose} />
          </div>
        }

        { header && <div className="header">{header}</div> }

        { content && (<OverscrollContain
            style={contentStyle}
            direction={"vertical"}
            className="modal-content"
            ref={scrollRef}
          >
            {content}
          </OverscrollContain>)
        }

        { footer && <div className="footer">{footer}</div> }
      </div>

      <style jsx>{styles}</style>
    </div>
  );
});

Modal.defaultProps = {
  showBackdrop: true,
  allowClose: true,
  onClose: () => {},
  allowBodyScroll: false,
  draggable: true,
  floating: false,
}
Modal.add = (component, key) => {
  modalStore.add(component, key);
}
Modal.remove = (component) => {
  if(typeof component === "string")
    modalStore.removeByKey(component);
  else
    modalStore.remove(component);
}
Modal.Confirm = Confirm;
Modal.Alert = Alert;
Modal.ButtonPrompt = ButtonPrompt;
Modal.Prompt = Prompt;
Modal.Loader = Loader;
Modal.Progress = Progress;
Modal.Help = Help;


const Inline = React.forwardRef(function InlineModal(props, ref) {
  useEffect(() => {
    const modal = <Modal ref={ref} {...props}>{props.children}</Modal>;
    Modal.add(modal);

    return () => {
      Modal.remove(modal);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
});
Modal.Inline = Inline;


Modal.displayName = "Modal";

export default Modal;