import { faClose } from "@fortawesome/pro-light-svg-icons/faClose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "components/Avatar/Avatar";
import { reaction } from "mobx";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useUser } from "utils/user/lib";
import { getAvatarFromMemberKey } from "utils/avatar";
import { useNotifications } from "../hooks/useNotifications";
import style from "./style";

const STICKYVISIBLETIME = 10000; // aantal ms dat sticky notification in beeld moet blijven

function StickyNotification() {
  const user = useUser();
  const notifications = useNotifications();
  const [notification, setNotification] = useState();
  const [avatar, setAvatar] = useState({
    initials: "",
    color: [255,255,255],
    style: {
      border: '1px solid #EFDEFF',
      boxSizing: 'border-box',
    }
  });

  useEffect(() => {
    if(!user.isLoggedIn)
      return;
    
    reaction(
      () => notifications.local,
      (notification) => {
        if(notification.avatar)
          setAvatar(notification.avatar);

        if(notification.sender)
        {
          const avatar = getAvatarFromMemberKey(notification.sender);
          avatar.then(setAvatar);
        }
        
        setNotification(notification);
      }
    );
  }, [user.isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  if(!notification)
    return null;

  return <Notification notification={notification} avatar={avatar} />;
}


function Notification(props) {
  const {notification, avatar} = props;
  const notifications = useNotifications();
  const [visible, setVisible] = useState(false);

  const onClose = useCallback((e) => {
    e.stopPropagation();
    setVisible(false);
  }, []);

  const handleNotification = useCallback(() => {
    notifications.handleNotification(notification);
  }, [notification, notification?.key]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if(!notification)
      return;
    
    const timerId = requestAnimationFrame(() => {
      setVisible(true);
      
      const timer = setTimeout(() => {
        notifications.displayed(notification);
        setVisible(false);
      }, STICKYVISIBLETIME);
      
      return () => {
        clearTimeout(timer);
      }
    });

    return () => {
      cancelAnimationFrame(timerId);
    }
  }, [notification]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = ['sticky-notification'];
  if(visible)
    classes.push('visible');
  
  return createPortal((
    <div className={classes.join(' ')} onClick={handleNotification}>
      <div className="avatar"><Avatar size={40} {...avatar} /></div>

      <div className="content">
        <h2>{notification.title}</h2>
        <div className="text">{notification.body}</div>
      </div>

      <div className="close" onClick={onClose}>
        <FontAwesomeIcon icon={faClose} />
      </div>

      <style jsx>{style}</style>
    </div>
  ), document.body);
}

export default StickyNotification;