import { useState, useRef, useEffect } from 'react';
import Modal from "./Modal.js"
import translationStore from 'stores/translation-store'
import Button from "../Button/Button";
import { Loader, Select } from '../index.js';

function Prompt(options) {
  return new Promise((resolve, reject) =>
  {
    const modal = <PromptModal
      onClose={(data) => {
        if(data!==undefined)
          resolve(data);
        else
          reject();

        Modal.remove(modal);
      }}
      {...options}
    />;

    Modal.add(modal);
  });
}

function PromptModal(props) {
  const [values, setValues] = useState({});
  const [isValidating, setValidating] = useState(false);
  const refs = useRef({}).current;
  const autoFocus = useRef(null);

  function setValue(name, value) {
    const myValues = {...values};
    myValues[name] = value;
    setValues(myValues);
  }
  function getValue(name) {
    if(values[name]!==undefined)
      return values[name];
    else
      return "";
  }

  function onClose(data) {
    if(data && props.onValidate)
    {
      setValidating(true);
      props.onValidate(data, refs)
      .then(() =>
      {
        props.onClose(data);
      })
      .catch(() =>
      {
        setValidating(false);
      });
    }
    else
      props.onClose(data);
  }

  useEffect(() => {
    let myValues = {};
    for(let input of props.inputs) {
      myValues[input.name] = input.value;
    }

    if(autoFocus.current && refs[autoFocus.current]) {
      refs[autoFocus.current].focus();
    }

    setValues(myValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      stack={true}
      onClose={() => {
        onClose();
      }}
      modalStyle={{
        width: 520,
        ...props.modalStyle
      }}
      contentStyle={{
        flexDirection: 'column',
        ...props.contentStyle
      }}
    >
      <div key="header">
        <div className="title">{props.title}</div>
        <div className="description">{props.description}</div>
      </div>

      <div key="content">
        <div className="ui-prompt-inputs">
          {
            props.inputs.map(({label, okOnEnter, ...input}) =>
            {
              switch(input.type)
              {
                case 'select':
                  let value = getValue(input.name)
                  if(typeof value === "undefined" || value.length === 0)
                  {
                    if(input.options && Object.keys(input.options).length > 0)
                      setValue(input.name, input.options.find(x=>x).value);
                    else if (input.groups && input.groups.length > 0)
                      setValue(input.name, input.groups[0].options.find(x=>x).value);
                  }

                  let selectProps = {};
                  if(okOnEnter)
                  {
                    selectProps.onKeyUp = e =>
                    {
                      if(e.keyCode === 13)
                        onClose(values);
                    }
                  }

                  return (
                    <Select
                      {...selectProps}
                      key={input.name}
                      name={input.name}
                      value={value}
                      style={input.style}
                      onChange={e => setValue(input.name, e.target.value)}
                    >
                      {
                        input.options &&
                        <Options options={input.options} />
                      }
                      {
                        input.groups &&
                        input.groups.map((group, index) => {
                          return (
                            <optgroup key={index} label={group.label}>
                              <Options options={group.options} />
                            </optgroup>
                          );
                        })
                      }
                    </Select>
                  );
                case 'number':
                case 'text':
                case 'password':
                default:
                  let inputProps = {};
                  if(okOnEnter)
                  {
                    inputProps.onKeyUp = e =>
                    {
                      if(e.keyCode === 13)
                        onClose(values);
                    }
                  }

                  if(input.autoFocus)
                  {
                    autoFocus.current = null;
                    autoFocus.current = input.name;
                    delete input.autoFocus;
                  }

                  return (
                    <div key={`${input.type}-${input.name}`} className="ui-prompt-input">
                      {label && <label>{label}</label>}
                      <input ref={ref => refs[input.name] = ref} {...input} {...inputProps} type={input.type} value={getValue(input.name)} onChange={e => setValue(input.name, e.target.value)} />
                    </div>
                  );
              }
            })
          }
        </div>
        <div className="ui-prompt-buttons" style={{flex: 1, display: 'flex', flexDirection: 'row', marginTop: 30}}>
          <Button
            className="accent large"
            onClick={() =>
            {
              onClose(values);
            }}
          >
            {
              props.onValidate && isValidating ?
              <Loader foregroundColor={"rgba(var(--accentColorDarkContrastColor), 1)"} backgroundColor={"rgba(var(--accentColorDarkContrastColor), 0.6)"} /> :
              props.okButtonText || translationStore.translate("base.ok")
            }
          </Button>

          <Button
            className="large"
            onClick={() =>
            {
              onClose();
            }}
          >
            {props.cancelButtonText || translationStore.translate("base.cancel")}
          </Button>
        </div>

        <style jsx>
        {`
          .ui-prompt-inputs {
            display: flex;
            flex-direction: 'column';
            flex: 1;
            width: 100%;
          }
          .ui-prompt-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;
            flex: 1;
          }
          .ui-prompt-input > label {
            margin-right: 20px;
          }
          .ui-prompt-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
          }
        `}
        </style>
      </div>
    </Modal>
  )
}

function Options(props)
{
  return props.options.map(option => <option key={option.value} value={option.value}>{option.label}</option>);
}

export default Prompt;