import { FetchJSON } from "./Fetch";

class AvatarLoader {
  /** @private */
  cacheEnabled = true;
  
  /** @private */
  promises = new Map();
  
  /** @private */
  cache = new Map();
  
  /** @private */
  queue = new Set();

  /** @private */
  resolves = new Map();

  /** @private */
  isRunning = false;

  constructor(cacheEnabled) {
    this.cacheEnabled = cacheEnabled;
  }

  get(key) {
    if(!this.promises.has(key)) {
      this.promises.set(key, new Promise(resolve => {
        if(this.cacheEnabled) {
          if(this.cache.has(key)) {
            return resolve(this.cache.get(key));
          }
        }

        this.resolves.set(key, resolve);
        this.addTask(key);
      }));
    }

    return this.promises.get(key);
  }

  /**
   * @private
   */
  addTask(key) {
    this.queue.add(key);

    this.startBatch();
  }

  /**
   * @private
   */
  startBatch() {
    if(this.isRunning || this.queue.size === 0) {
      return;
    }

    this.isRunning = true;
    setTimeout(() => {
      const keys = Array.from(this.queue.keys());
      keys.forEach(key => this.queue.delete(key));

      const [promise] = FetchJSON(`${process.env.API_URL}/member/${keys.join(",")}/avatar`);
      promise.then(({data}) => {
        keys.forEach((key, index) => {
          const avatarData = {
            initials: data.avatars[index].initials,
            images: data.avatars[index].image,
            color: data.avatars[index].color,
          }

          if(this.cacheEnabled) {
            this.cache.set(key, avatarData);
          }
          const resolve = this.resolves.get(key);
          resolve(avatarData);
        });

        this.isRunning = false;
        this.startBatch(); // Start the next batch, only runs again if there is something in the queue
      })
    }, 200);
  }
}

const isServerSide = (typeof window === "undefined");
const avatarLoader = new AvatarLoader(!isServerSide);

function getAvatarFromMemberKey(key) {
  return new Promise((resolve, reject) => {
    avatarLoader.get(key).then(resolve).catch(reject);
  });
}

export {
  getAvatarFromMemberKey
}
