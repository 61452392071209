import { S3Client } from "@aws-sdk/client-s3";
import { Signals } from "hooks/useSignals";
import PQueue from "p-queue";
import { CHECK_PROCESS_INTERVAL, GB, QUEUE_CONCURRENCY, URL_INIT, URL_STATUS } from "./constants";
import FileResult from "./FileResult";
import { FileError, FileState, StatusFileResponseState, UploadErrorState, UploadState } from "./state";
import { InitUploadResponse, S3Config, StatusResponse, UploadResultProps } from "./types";
import UploadError from "./UploadError";
import UploadFile from "./UploadFile";
import UploadResult from "./UploadResult";
import type UserStore from "utils/user/store";

export default class UploadSession {
  key: string;
  state: UploadState = UploadState.WAITING;

  private files: Array<UploadFile> = [];
  private queue = new PQueue({concurrency: QUEUE_CONCURRENCY, autoStart: false});
  private disposers: Array<Function> = [];

  private s3Client?: S3Client;
  private s3Config?: S3Config;
  private uploadToken?: string;
  private bucket?: string;

  private uploadStartTime?: number;
  private uploadTimeMs?: number;

  private processStartTime?: number;
  private processTimeMs?: number;

  private processTimer?: NodeJS.Timer;

  private processResolve?: (response: StatusResponse) => void;
  private doneResolve?: (result: UploadResult) => void;

  constructor(key: string) {
    this.key = key;
  }

  addFiles(files: Array<File> | FileList) {
    Array.from(files).forEach(file => this.files.push(new UploadFile(file)));
    Signals.emit(`upload-${this.key}-file-queue`, this.files);
  }

  addFile(file: File) {
    this.files.push(new UploadFile(file));
    Signals.emit(`upload-${this.key}-file-queue`, this.files);
  }

  start(user: UserStore) {
    return new Promise<UploadResult>((resolve, reject) => {
      this.doneResolve = resolve;

      (async () => {
        try {
          await this.checkFiles();
          await this.init(user.fetch.bind(user));
          await this.initSignals();
          await this.createClient();
          await this.queueFiles();
          await this.startQueue();
          const processResult: StatusResponse = await this.process(user.fetch.bind(user));
          
          if(this.state !== UploadState.CANCELLED) {
            this.setState(UploadState.DONE);
            resolve(this.getUploadResult(processResult));
          }
        } catch (error) {
          this.stopUpload();
          if(this.state !== UploadState.CANCELLED) {
            this.setState(UploadState.ERROR);
            reject(this.getUploadResult(null, error));
          }
        }
      })();
    });
  }

  pause() {
    this.setState(UploadState.PAUSED);
    this.files.forEach(file => file.pause());
    this.queue.pause();
  }

  resume() {
    this.setState(UploadState.UPLOADING);
    this.files.forEach(file => file.resume());
    this.queue.start();
  }

  cancel() {
    this.stopUpload();
    this.setState(UploadState.CANCELLED);
    this.files.forEach(file => file.setState(FileState.CANCELLED));
  }

  stopUpload() {
    this.uploadTimeMs = new Date().getTime() - this.uploadStartTime;
    this.files.forEach(file => file.stopUpload());
  }

  clear() {
    this.files = [];
    this.queue.clear();
    Signals.emit(`upload-${this.key}-file-queue`, this.files);
  }

  dispose() {
    this.clear();
    this.disposers.forEach(disposer => disposer());
  }

  private async initSignals() {
    this.disposers.push(
      Signals.on(`upload-part-progress`, () => {
        this.emitProgress();
      })
    );
  }

  private checkFiles() {
    return new Promise<void>((resolve, reject) => {
      let errorCount: number = 0;

      this.files.forEach((file) => {
        if(file.size > GB) {
          file.state = FileState.SKIPPED;
          file.error = FileError.FILE_SIZE_TOO_LARGE;
          errorCount++;
        } else if (file.size === 0) {
          file.state = FileState.SKIPPED;
          file.error = FileError.ZERO_BYTE;
          errorCount++;
        }
      });

      if(errorCount === this.files.length) {
        return reject(new UploadError(UploadErrorState.ALL_ERRORED));
      }

      resolve();
    });
  }

  private init(FetchFunc: FetchFunction) {
    return new Promise<void>((resolve, reject) => {
      FetchFunc(URL_INIT, {
        method: "POST",
        data: {
          currentTime: new Date().getTime(),
        },
      })
      .then((response: {data: InitUploadResponse}) => {
        this.s3Config = response.data.s3Config;
        this.uploadToken = response.data.uploadToken;
        this.bucket = response.data.bucket;

        resolve();
      })
      .catch((error: any) => {
        console.error(error);
        reject(new UploadError(UploadErrorState.INIT_FAILED));
      });
    })
  }

  private async createClient() {
    this.s3Client = new S3Client({
      region: this.s3Config?.region,
      useAccelerateEndpoint: this.s3Config?.useAccelerateEndpoint,
      systemClockOffset: this.s3Config?.systemClockOffset,
      credentials: {
        accessKeyId: this.s3Config?.credentials.accessKeyId,
        secretAccessKey: this.s3Config?.credentials.secretAccessKey,
        sessionToken: this.s3Config?.credentials.sessionToken,
        expiration: this.s3Config?.credentials.expiration,
      },
    });
  }

  private async queueFiles() {
    this.files
    .sort(sortByFilesize)
    .forEach(file => {
      if(file.state === FileState.WAITING) {
        this.queue.add(() => {
          return file.upload(this.s3Client, this.bucket, this.uploadToken);
        });
      }
    });
  }

  private startQueue() {
    this.setState(UploadState.UPLOADING);
    this.uploadStartTime = new Date().getTime();
    return new Promise<void>((resolve, reject) => {
      this.queue.start()
      .addListener("error", (e) => {
        this.uploadTimeMs = new Date().getTime() - this.uploadStartTime;
        this.stopUpload();
        reject(e);
      })
      .addListener("idle", () => {
        this.uploadTimeMs = new Date().getTime() - this.uploadStartTime;
        resolve();
      });
    });
  }

  private process(FetchFunc: FetchFunction) {
    this.processStartTime = new Date().getTime();

    if(this.state === UploadState.CANCELLED) {
      this.processTimeMs = new Date().getTime() - this.processStartTime;
      return Promise.resolve({files: []});
    }

    return new Promise<StatusResponse>((resolve) => {
      this.processResolve = resolve;
      this.setState(UploadState.PROCESSING);

      this.checkProcessStatus(FetchFunc);
      this.startCheckProcessStatusTimer(FetchFunc);
    });
  }

  private startCheckProcessStatusTimer(FetchFunc: FetchFunction) {
    if(this.processTimer) {
      clearTimeout(this.processTimer);
    }

    this.processTimer = setTimeout(() => {
      this.checkProcessStatus(FetchFunc);
    }, CHECK_PROCESS_INTERVAL);
  }

  private checkProcessStatus(FetchFunc: FetchFunction) {
    if(this.state === UploadState.CANCELLED) {
      this.processTimeMs = new Date().getTime() - this.processStartTime;
      return this.processResolve({files: []});
    }

    FetchFunc(URL_STATUS, {
      method: "POST",
      data: {
        keys: this.files.map(file => file.key),
      },
    })
    .then(({data}: {data: StatusResponse}) => {
      const statusKeys = data.files.map(file => file.key);
      const hasProcessingStarted = this.files.some(file => {
        return statusKeys.includes(file.key);
      });

      if(hasProcessingStarted) {
        const processingCompleteCount = data.files.filter(file => {
          return [StatusFileResponseState.COMPLETE, StatusFileResponseState.ERROR].includes(file.status);
        }).length;
        const skippedFileCount = this.files.filter(file => {
          return file.state === FileState.SKIPPED;
        }).length;

        const totalProcessedCount = processingCompleteCount + skippedFileCount;

        // This makes the process progress look more active when there are only 1 or 2 files
        let _totalProcessCount = this.files.length;
        let _totalProcessedCount = totalProcessedCount;
        if(this.files.length < 3) {
          if(this.files.length === 2) {
            _totalProcessCount = 3;
            _totalProcessedCount = totalProcessedCount + 1;
          } else if (this.files.length === 1) {
            _totalProcessCount = 2;
            _totalProcessedCount = totalProcessedCount + 1;
          }
        }

        Signals.emit(`upload-${this.key}-process`, _totalProcessedCount / _totalProcessCount * 100);
  
        if(totalProcessedCount < this.files.length) {
          this.startCheckProcessStatusTimer(FetchFunc);
        } else {
          Signals.emit(`upload-${this.key}-process`, 100);
          this.processTimeMs = new Date().getTime() - this.processStartTime;
          this.processResolve(data);
        }
      } else {
        Signals.emit(`upload-${this.key}-process`, 0);
        this.startCheckProcessStatusTimer(FetchFunc);
      }
    });
  }

  private setState(state: UploadState) {
    this.state = state;
    Signals.emit(`upload-${this.key}-state`, state);
  }

  private getUploadResult(processResult?: StatusResponse, error?: any) {
    const uploadResultProps: UploadResultProps = {
      uploadTimeMs: this.uploadTimeMs,
      processTimeMs: this.processTimeMs,
      state: this.state,
      files: this.files.map(file => new FileResult(file, processResult?.files.find(f => f.key === file.key)))
    }

    if(error) {
      if(error instanceof UploadError) {
        uploadResultProps.errorState = error.error;
        uploadResultProps.errorString = String(error);
      } else {
        uploadResultProps.errorState = UploadErrorState.OTHER;
        uploadResultProps.errorString = String(error);
      }
    }

    this.clear();

    return new UploadResult(uploadResultProps);
  }

  private emitProgress() {
    const progress = this.files.reduce((acc, file) => {
      if(file.state === FileState.SKIPPED) {
        return acc;
      }

      acc.loaded += file.progress.loaded;
      acc.total += file.progress.total;
      return acc;
    }, {loaded: 0, total: 0});

    Signals.emit(`upload-${this.key}-progress`, progress.loaded / progress.total * 100);
  }
}

function sortByFilesize(a: UploadFile, b: UploadFile) {
  return b.size - a.size;
}