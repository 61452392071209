import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import Events from "utils/EventsOld";
import { v4 as uuidv4 } from 'uuid';

const POLL_DELAY = 3000;
const NOTIFICATIONS_AMOUNT = 3;
const isSSR = (typeof window === "undefined")

class NotificationStore {
  didLoad = false;
  items = [];
  localItems = [];
  user = undefined;
  totals = {};
  hasMore = false;
  _localNotification = undefined;

  constructor(user) {
    new Events(this);

    makeObservable(this, {
      items: observable,
      localItems: observable,
      totals: observable,
      hasMore: observable,
      
      list: computed,
      balloonCount: computed,
      local: computed,

      addNotification: action,
      deleteNotification: action,
      load: action,
    });

    this.user = user;
  }

  handleNotification = (notification) => {
    this.trigger('handleNotification', toJS(notification));
  }

  deleteNotification = (notification) => {
    this.items.replace(this.items.filter(item => item.key !== notification.key));
    this.localItems.replace(this.localItems.filter(item => item.key !== notification.key));
  }

  startPolling() {
    // 1 poller tegelijk
    // geen serverside polling
    if(this.isPolling || isSSR)
      return;
    
    this.allowPolling = true;
    const poll = () => {
      if(!this.allowPolling)
        return;

      this.isPolling = true;
      this.pollTimer = setTimeout(() => {
        if(!this.allowPolling)
          return;

        this.load().then(() => {
          poll();
        });
      }, POLL_DELAY);
    }

    this.load().then(() => {
      poll();
    });
  }

  stopPolling() {
    this.allowPolling = false;
    if(this.pollTimer)
      clearTimeout(this.pollTimer);

    this.isPolling = false;
  }

  load() {
    return new Promise(resolve => {
      if(this._cancelLoad)
      {
        this._cancelLoad();
        delete this._cancelLoad;
      }

      const [promise, cancel] = this.user.FetchJSON(`${process.env.API_URL}/notifications/list?limit=${NOTIFICATIONS_AMOUNT}`);
      this._cancelLoad = cancel;

      promise
      .then(req => {
        runInAction(() => {
          this.totals = req.data.totals;
          this.items = req.data.notifications.map(notification => {
            const created = new Date(notification.created);
            const age = Math.abs(new Date() - created)/1000/60;
            return {
              ...notification,
              created,
              isNew: age<5
            };
          });

          this.hasMore = !!req.data.links?.next;
          this.didLoad = true;
          resolve();
        });
      })
      .catch(() => {
        resolve();
      })
    });
  }

  displayed(notification) {
    window.sessionStorage.setItem('sticky-notification-displayed-'+notification.key, 1);

    if(this._localNotification?.key===notification.key)
    {
      this._localNotification = null;
    }
  }

  get local() {
    if(isSSR)
      return null;
    
    if(!this._localNotification)
    {
      const list = [...this.list].reverse();
      for(const notification of list)
      {
        const isDisplayed = window.sessionStorage.getItem('sticky-notification-displayed-'+notification.key, 1);
        if(notification.isNew && !isDisplayed)
        {
          this._localNotification = notification;
          break;
        }
      }
    }

    if(this._localNotification)
      return this._localNotification;

    return null;
  }

  get list() {
    const items = [...this.items, ...this.localItems];
    items.sort((a, b) => {
      return b.created.getTime() - a.created.getTime()
    });

    return items;
  }

  get balloonCount() {
    const total = Object.entries(this.totals).reduce((count, [key, total]) => {
      switch(key) {
        case 'access-request':
        case 'contribution-request':
          return count + total.read + total.unread;
        default:
          return count + total.unread;
      }
    }, 0);

    const localTotal = this.localItems.reduce((count, item) => {
      if(!item.isRead)
        return count + 1;
      else
        return count;
    }, 0);

    return total + localTotal;
  }

  getTotal(key) {
    try {
      const counts = this.totals[key];
      return counts.unread + counts.read;
    } catch(e) {
      return 0;
    }
  }

  markNotificationAsRead = (notification) => {
    const [promise] = this.user.FetchJSON(`${process.env.API_URL}/notifications/${notification.key}/mark-as-read`, {
      method: "POST"
    });
    return promise;
  }

  markAllAsRead = () => {
    
    const [promise] = this.user.FetchJSON(`${process.env.API_URL}/notifications/all/mark-as-read`, {
      method: "POST"
    })
    promise
    .then(() => {
      this.load();
    })
    .catch(() => {});
  }

  /**
   * 
   * Add local notifications, gone after page refresh
   * 
   * @param {Object} data 
   * @param {String} unique 
   * 
   */
  addNotification = (data, unique) => {
    const {props, ...rest} = data;
    
    const localItems = this.localItems.filter(item => {
      return (item.unique===undefined || item.unique!==unique);
    })

    const notification = {
      key: uuidv4(),
      type: "contribution-added-to-album",
      title: "",
      body: "",
      isRead: false,
      created: new Date(),

      ...rest,
      unique,
      props: {
        ...props
      },
    };

    localItems.push(notification);
    this.localItems.replace(localItems);
  }
}

export default NotificationStore;