import { action, observable, makeObservable } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import { propsToModel } from "utils/models";
import PhotobookProductModel from "./products/photobook-model";

export default class ProductModel
{
  cart;

  quantity = 1;
  price = 0;
  product;
  album;
  options = [];

  _albumKey;

  // Legacy properties
  pageCount;
  gridVersion;
  cropping;
  qrCode;
  cover;
  coverPhotoKey;
  coverIcon;

  constructor(data, cart)
  {
    makeObservable(this, {
      quantity: observable,
      price: observable,
      remove: action
    });

    this.uuid = uuidv4();

    this.cart = cart;
    propsToModel(data, this);

    if(data.albumKey)
      this._albumKey = data.albumKey;

    switch(data.product.type)
    {
      case 'photobook':
        this.product = new PhotobookProductModel(data.product);
    }
  }

  get key()
  {
    return `product-${this.uuid}`;
  }

  get albumKey()
  {
    if(this.album)
      return this.album.key;
    else
      return this._albumKey;
  }

  get orientation()
  {
    if(this.product && this.product.orientation)
      return this.product.orientation;

    return "landscape";
  }

  remove()
  {
    this.cart.removeProduct(this);
  }

  getData()
  {
    let data =  {
      quantity: this.quantity,
      product: this.product.getData(),
      albumKey: this.albumKey,
      options: this.options
    }

    // Legacy album
    if(this.albumKey.length === 12)
    {
      data = {
        ...data,
        pageCount: this.pageCount,
        gridVersion: this.gridVersion,
        cropping: this.cropping,
        qrCode: this.qrCode,
        cover: this.cover,
        coverPhotoKey: this.coverPhotoKey,
        coverIcon: this.coverIcon
      }
    }

    return data;
  }
}