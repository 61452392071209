import { useCallback, useEffect, useRef } from 'react';

function useSignals() {
  const disposers = useRef([] as Function[]);

  const on = useCallback((id:string, callback:Function) => {
    const disposer = Signals.on(id, callback);
    disposers.current.push(disposer);

    return disposer;
  }, []);

  const one = useCallback((id:string, callback:Function) => {
    const disposer = Signals.one(id, callback);
    disposers.current.push(disposer);

    return disposer;
  }, []);

  useEffect(() => {
    const _disposers = disposers.current;

    return () => {
      _disposers.forEach((dispose:Function) => dispose());
    };
  }, []);

  return { on, one, emit: Signals.emit };
}

export default useSignals;


export class Signals {
  static signals:any = {};

  static on(id: string, callback: Function) {
    if (!Signals.signals[id]) {
      Signals.signals[id] = [];
    }
    Signals.signals[id].push(callback);

    return () => {
      Signals.signals[id] = Signals.signals[id].filter((cb:Function) => cb !== callback);
    };
  }

  static one(id: string, callback: Function) {
    const disposer = Signals.on(id, (...args:any[]) => {
      callback(...args);
      disposer();
    });

    return disposer;
  }

  static emit(id: string, ...args: any[]) {
    return new Promise(resolve => {
      if (Signals.signals[id])
      {
        Signals.signals[id].forEach((callback:Function) => {
          const returnValue = callback(...args);
          if(returnValue?.then)
          {
            returnValue.then((value:any) => {
              resolve(value);
            });
          }else
          {
            if(returnValue!==undefined)
              resolve(returnValue);
          }
        });
      }
    });
  }
}