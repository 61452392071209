import { Observer } from "mobx-react";
import modalStore from "./store";

function ModalRoot() {
  return (
    <Observer>
      {() => {
        // Deze regel zorgt ervoor dat er een render actie uitgevoerd wordt zodra er iets wijzigt in de modals
        // eslint-disable-next-line
        let stackChanges = modalStore.stackChanges;
        return modalStore.visibleModals;
      }}
    </Observer>
  )
}

export default ModalRoot;