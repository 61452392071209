import { PropsWithChildren } from "react";
import styles from "./style"

type ButtonProps = {
  enabled?: boolean;
  disabled?: boolean;
  className?: string;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
}

export default function Button(props: PropsWithChildren<ButtonProps>) {
  const { children, enabled, disabled, className, href, ...rest } = props;

  const classes = ['button'];
  if (className) {
    classes.push(className);
  }
  
  if (enabled === false || disabled !== false) {
    classes.push("disabled");
    rest.onClick = () => {}
  }

  return (
    <>
      {
        href ?
        <a {...rest} href={href} className={classes.join(" ")}>{children}</a>
        :
        <button {...rest} className={classes.join(" ")}>{children}</button>
      }
      <style jsx>{styles}</style>
    </>
  );
}

Button.defaultProps = {
  enabled: true,
  disabled: false,
}