import { createRef, PureComponent } from 'react';
import OverscrollHandler from './OverscrollHandler';

class OverscrollContain extends PureComponent
{
  constructor(props) {
    super(props);

    this.ref = createRef();
  }

  componentDidMount() {
    this.handler = new OverscrollHandler(this.ref.current, {
      direction: this.props.direction,
    });
  }

  componentWillUnmount() {
    if(this.handler)
    {
      this.handler.destroy();
      this.handler = undefined;
    }
  }

  scrollTo(x, y) {
    if(this.ref.current)
      this.ref.current.scrollTo(x, y);
  }

  isVertical() {
    return this.props.direction === "vertical";
  }

  render() {
    const {children, style, ...props} = this.props;

    let _style = {
      height: "100%",
      width: "100%",
      overflow: "auto",
      overflowX: this.isVertical() ? "auto" : "scroll",
      overflowY: this.isVertical() ? "scroll" : "auto",
    };

    return (
      <div className="overscroll-container" style={{..._style, ...style}} {...props} ref={this.ref}>{children}</div>
    )
  }
}

OverscrollContain.defaultProps = {
  direction: "vertical",
}

export {OverscrollHandler};
export default OverscrollContain;