import { FileError, FileErrorGroup, FileState, StatusFileResponseState } from "./state";
import { FileHistoryState, StatusFileResponse } from "./types";
import UploadFile from "./UploadFile";

export default class FileResult {
  uploadState: FileState;
  uploadStates: Array<FileHistoryState>;
  fileKey: string;
  fileName: string;
  processState: StatusFileResponseState;
  processErrorCode?: FileError;
  processErrorGroup?: FileErrorGroup;

  constructor(file: UploadFile, processResult?: StatusFileResponse) {
    this.uploadState = file.state;
    this.uploadStates = file.statesHistory;
    this.fileKey = file.key;
    this.fileName = file.name;
    this.processState = processResult?.status ?? StatusFileResponseState.UNKNOWN;
    this.processErrorCode = processResult?.error?.code;
    this.processErrorGroup = processResult?.error?.ident;

    // Skipped zero bytes file
    if(file.state === FileState.SKIPPED && file.error === FileError.ZERO_BYTE) {
      this.processState = StatusFileResponseState.ERROR;
      this.processErrorCode = FileError.ZERO_BYTE;
      this.processErrorGroup = FileErrorGroup.FILE_CORRUPT;
    }

    // Skipped too large file
    if(file.state === FileState.SKIPPED && file.error === FileError.FILE_SIZE_TOO_LARGE) {
      this.processState = StatusFileResponseState.ERROR;
      this.processErrorCode = FileError.FILE_SIZE_TOO_LARGE;
      this.processErrorGroup = FileErrorGroup.IMAGE_FILESIZE_TOO_LARGE;
    }
  }
}