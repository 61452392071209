import { createRef, Component } from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'throttle-debounce';
import ThemeStore from '../store';

import styles from "./style";

class ToolTip extends Component
{
  delay = 350;
  ref = createRef();

  static defaultProps = {
    checked: false,
    textColor: null,
    textOpacity: null,
    backgroundColor: null
  }

  constructor(props)
  {
    super(props);
    this.state = {
      align: 'left',
      width: 10,
      height: 10,
      left: 0,
      top: 0,

      design: {
        textColor: this.props.textColor || ThemeStore.theme.accentColorDark,
        textOpacity: this.props.textOpacity || 1,
        backgroundColor: this.props.backgroundColor || ThemeStore.theme.accentColorLight,
      },

      balloonHeight: 10,
      balloonWidth: 10,
    };

    this.hover = debounce(20, this.hover.bind(this));
  }

  componentDidMount()
  {
    window.addEventListener('mouseover', this.hover, {passive: true});
    window.addEventListener('resize', this.hide);
    window.addEventListener('mousedown', this.hide, {passive: true});
    window.addEventListener('scroll', this.hide, {passive: true});
    window.addEventListener('touchmove', this.hide, {passive: true});
  }

  componentWillUnmount()
  {
    window.removeEventListener('mouseover', this.hover, {passive: true});
    window.removeEventListener('resize', this.hide);
    window.removeEventListener('mousedown', this.hide, {passive: true});
    window.removeEventListener('scroll', this.hide, {passive: true});
    window.removeEventListener('touchmove', this.hide, {passive: true});
  }

  setDesign(node) {
    const designName = node.hasAttribute('data-tip-design') ? node.getAttribute('data-tip-design') : 'default';
    const design = {
      textColor: this.props.textColor || ThemeStore.theme.accentColorDark,
      textOpacity: this.props.textOpacity || 1,
      backgroundColor: this.props.backgroundColor || ThemeStore.theme.accentColorLight,
    }

    switch(designName) {
      case "dark":
        design.textColor = '255,255,255';
        design.textOpacity = 1;
        design.backgroundColor = '32,32,32';
      break;
    }

    if(node.hasAttribute('data-tip-text-color'))
      design.textColor = node.getAttribute('data-tip-text-color');

    if(node.hasAttribute('data-tip-text-opacity'))
      design.textOpacity = parseFloat(node.getAttribute('data-tip-text-opacity'));

    if(node.hasAttribute('data-tip-background-color'))
      design.backgroundColor = node.getAttribute('data-tip-background-color');

    this.setState({design});
  }

  hover = (e) =>
  {
    if(typeof window !== "undefined")
    {
      let node = e.target;
      while(node && node!==window) {
        if(typeof node.hasAttribute === "function" && node.hasAttribute('data-tip') && !node.hasAttribute('data-tip-sticky'))
        {
          if(node!==this.node)
          {
            if(this.timer)
            {
              clearTimeout(this.timer);
              this.timer = null;
            }

            this.hide();
            const delay = node.hasAttribute('data-tip-delay') ? parseInt(node.getAttribute('data-tip-delay')) : this.delay;
            this.node = node;

            let run = function() {
              this.setDesign(node);
              this.setPosition(node);
              this.setState({
                tip: node.getAttribute('data-tip'),
                show: true
              });
            }.bind(this);

            if(delay>0)
            {
              this.timer = setTimeout(() => {
                run();
              }, delay);
            }else
              run();
          }

          return;
        }else
        {
          if(node.parentNode!==document.body)
            node = node.parentNode;
          else
            break;
        }
      }

      this.hide();
      this.node = null;
    }
  }

  hide = (e) => {
    // We got an event, that was mousedown. We have a node, that has 'data-tip-hide-on-click' === "false". So we stop here to prevent the tooltip from hiding
    if(e && e.type === "mousedown" && this.node && this.node.hasAttribute("data-tip-hide-on-click") && this.node.getAttribute("data-tip-hide-on-click") === "false")
      return;

    if(this.timer)
      clearTimeout(this.timer);

    this.timer = null;
    if(this.node)
      this.setState({show: false});
  }

  setPosition = (node) => {
    let anchor = node;
    const anchors = node.querySelectorAll('[data-tip-anchor]');
    if(anchors.length>0)
      anchor = anchors[0];

    const rect = anchor.getBoundingClientRect();
    let top = rect.top;
    let left = rect.left;

    this.setState({
      top,
      left,
      width: rect.width,
      height: rect.height,
      align: node.hasAttribute('data-tip-align') ? node.getAttribute('data-tip-align') : 'left'
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.tip!==prevState.tip)
    {
      this.forceUpdate();
      requestAnimationFrame(() => {
        if(!this.ref.current)
          return;

        this.setState({
          balloonWidth: this.ref.current.offsetWidth,
          balloonHeight: this.ref.current.offsetHeight
        });
      });
    }
  }

  render() {
    if(!this.state.show || this.state.tip === null || this.state.tip.length < 1)
      return null;

    const spacing = 10; // spacing + arrow size
    const arrowHeight = 6;
    const align = this.state.align;
    let left = this.state.left;
    let top = this.state.top;
    let arrowStyle = {};

    switch(align) {
      case 'left':
        left = this.state.left - spacing - (this.state.balloonWidth);
        top = Math.round(this.state.top + this.state.height/2) - (this.state.balloonHeight/2);

        arrowStyle = {
          right: 0,
          marginRight: -6,
          top: '50%',
          marginTop: -6
        }
      break;
      case 'right':
        left = this.state.left + this.state.width + spacing;
        top = Math.round(this.state.top + this.state.height/2) - (this.state.balloonHeight/2);

        arrowStyle = {
          left: 0,
          marginLeft: -6,
          top: '50%',
          marginTop: -6
        }
      break;
      case 'bottom':
        left = Math.round(this.state.left + this.state.width / 2) - (this.state.balloonWidth/2);
        top = this.state.top + this.state.height + spacing;

        arrowStyle = {
          top: 0,
          marginTop: -6,
          left: '50%',
          marginLeft: -6,
        }
      break;
      case 'top':
        left = Math.round(this.state.left + this.state.width / 2) - (this.state.balloonWidth/2);
        top = this.state.top - spacing + arrowHeight - this.state.balloonHeight;

        arrowStyle = {
          top: -1+this.state.balloonHeight,
          marginTop: 0,
          left: '50%',
          marginLeft: -6,
        }
      break;
    }

    if(left < 2)
    {
      arrowStyle.marginLeft += left;
      left = 2;
    }
    else if (left + this.state.balloonWidth > window.innerWidth)
    {
      let newLeft = window.innerWidth - this.state.balloonWidth - 2;
      arrowStyle.marginLeft += (left - newLeft);
      left = newLeft;
    }

    let style = {
      "--textColor": this.state.design.textColor,
      "--textOpacity": this.state.design.textOpacity,
      "--backgroundColor": this.state.design.backgroundColor,
      left,
      top
    }

    return (
      <div ref={this.ref} className={"ui-tooltip" + " ui-tooltip-align-"+align} style={{...style}}>
        <div className="arrow" style={arrowStyle}></div>
        {this.state.tip}
        <style jsx>{styles}</style>
      </div>
    );
  }
}

export default observer(ToolTip);