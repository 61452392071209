import css from "styled-jsx/css"

export const containerStyle = css`
  .ui-toast-container
  {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    z-index: 9999999;
    pointer-events: none;
  }
`

export const componentStyle = css`
  .ui-toast {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: calc(100vw - 20px);
    padding-left: 16px;
    overflow: hidden;
    background: rgba(var(--toastBg), 1);
    box-shadow: 0 2px 10px rgba(var(--accentColor), 0.07);
    color: rgba(var(--toastColor), 1);
    border-radius: 6px;
    transform: scale(0.6);
    opacity: 0;
    transition: transform .1s cubic-bezier(0.2, 0.9, 0.3, 1.5), opacity .1s ease;
    pointer-events: auto;
    font-weight: 300;
  }

  .ui-toast.visible {
    opacity: 1;
    transform: scale(1);
  }

  .ui-toast.click {
    cursor: pointer;
  }

  .ui-toast.error:before {
    background-color: rgba(var(--errorColor), 1);
  }

  .ui-toast>div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ui-toast .ui-toast-content {
    min-width: 200px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }

  .ui-toast button.ui-toast-button {
    display: flex;
    height: 100%;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    border-top: 0px;
    border-right: 0px;
    color: rgba(var(--toastColor), 1);
    border-radius: 0px;
    outline: none;
    cursor: pointer;
    margin: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-weight: 300;
  }

  .ui-toast button.ui-toast-button :global(span:not(:empty) + svg) {
    margin-left: 5px;
    height: 10px;
  }

  .ui-toast button.ui-toast-button.ui-toast-close-button {
    width: 24px;
    color: rgba(var(--toastColor), 1);
    background-color: rgba(var(--toastBg), 1);
    border-color: transparent;
    box-sizing: border-box;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    border-left: 0.5px solid rgba(255, 255, 255, 0.3);
  }

  .ui-toast button.ui-toast-button.ui-toast-close-button * {
    pointer-events: none;
  }

  .ui-toast button.ui-toast-button.filled {
    background: rgba(var(--accentColorDark), 0.5);
    color: rgba(var(--backgroundColor), 1);
  }

  .ui-toast+.ui-toast {
    margin-top: 10px;
  }
`