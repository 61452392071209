import translationStore from "stores/translation-store";
import { FetchJSON } from "./Fetch";

class Countries
{
  cache = {};

  async fillCache(language)
  {
    if(!this.cache[language])
    {
      try
      {
        const [promise] = FetchJSON(`${process.env.API_URL}/countries?language=${translationStore.language}`);
        this.cache[language] =  (await promise).data;
      }
      catch(e)
      {
        console.log(e);
      }
    }
  }

  async byIso(iso, language)
  {
    if(!iso)
      return [];
    if(!language)
      language = translationStore.getLanguage()
    await this.fillCache(language);

    const country = this.cache[language].find(country =>
    {
      if(iso.length === 3)
        return country[0] === iso;
      else if(iso.length === 2)
        return country[1] === iso;
      return false;
    });

    return {
      iso3: country[0],
      iso2: country[1],
      name: country[2],
    };
  }
}

const CountriesInstance = new Countries();
export default CountriesInstance;