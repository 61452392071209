import { colorArrayToString } from '../utils';

import ThemeStore from '../store';
import styles from "./style";
import { observer } from 'mobx-react';
import css from 'styled-jsx/css';
import { useCallback, useMemo } from 'react';

type Props = {
  visible: boolean;
  foregroundColor?: string;
  backgroundColor?: string;
  size?: "small" | "medium" | "large" | "huge";
  style?: React.CSSProperties;
}

function Loader(props: Props)
{
  const foregroundColor = useMemo(() => {
    return props.foregroundColor || colorArrayToString(ThemeStore.theme.loaderColor);
  }, [props.foregroundColor]);

  const backgroundColor = useMemo(() => {
    return props.backgroundColor || colorArrayToString(ThemeStore.theme.loaderColor);
  }, [props.backgroundColor]);

  const getColorStyle = useCallback((foregroundColor, backgroundColor) => {
    return css.resolve`
      .loader
      {
        --fa-primary-color: ${foregroundColor};
        --fa-secondary-color: ${backgroundColor};
      }
    `
  }, []);

  if(!props.visible)
    return null;

  let iconSize = "";
  switch(props.size)
  {
    case 'huge':  iconSize = "3x"; break;
    case "large": iconSize = "2x"; break;
    default:      iconSize = "1x"
  }

  const colorStyle = getColorStyle(foregroundColor, backgroundColor);

  return (
    <div className={["loader", colorStyle.className].join(" ")} style={props.style}>
      <div className="lds-ring" data-size={iconSize}><div></div><div></div><div></div><div></div></div>
      
      <style jsx>{styles}</style>
      {colorStyle.styles}
    </div>
  );
}

Loader.defaultProps = {
  visible: true,
  size: "1x"
}

export default observer(Loader);