import { action, makeObservable, observable, reaction, toJS, runInAction, transaction, computed, extendObservable } from "mobx";
import { FetchJSON } from "utils/Fetch";
import isDev from "utils/isDev";
import jwtDecode from "jwt-decode";
import translationStore from "stores/translation-store";
const isSSR = (typeof window === "undefined")

const defaultPreferences:LoggedInUserDataInput['preferences'] = {
  folderSortBy: 'date',
  autoEnrich: true,
  showTrending: false,
};

class UserStore {
  isLoggedIn: boolean = false;
  isAdmin: boolean = false;
  key?: string;
  
  features: UserDataInput['features'] = {};
  account?: LoggedInUserDataInput['account'] = null;
  flags?: LoggedInUserDataInput['flags'] = {
    hasUsedTrial: false,
  };
  avatar?: LoggedInUserDataInput['avatar'] = null;
  _subscription?: LoggedInUserDataInput['subscription'] = {
    name: "Basic",
  };
  preferences = observable.map(defaultPreferences);

  private ult?: LoggedInUserDataInput['ult'];
  private _token?: Token;
  private _refreshTokenPromise?: Promise<Token>;
  private disposers: (() => void)[] = [];
  private autoSavePreferences = true;

  constructor(data:UserDataInput|LoggedInUserDataInput) {
    this.isLoggedIn = data.isLoggedIn;
    this.features = data.features;
    
    if (data.isLoggedIn) {
      this.account = data.account;

      const preferences = Object.assign({}, defaultPreferences, data.preferences);
      this.preferences.replace(preferences);
      this.flags = data.flags;
      this.avatar = data.avatar;
      this._subscription = data.subscription;
      this.ult = data.ult;
      this.token = data.token;
    }

    makeObservable(this, {
      isLoggedIn: observable,
      isAdmin: observable,
      account: observable,
      flags: observable,
      avatar: observable,
      _subscription: observable,

      setPreference: action,

      subscription: computed,
    });

    // Auto clear session storage als je uitlogt
    this.disposers.push(
      reaction(
        () => this.isLoggedIn,
        (isLoggedIn) => {          
          if(!isLoggedIn)
          {
            this.account = undefined;
            this.preferences.replace(defaultPreferences);
            this.flags = undefined;
            this.avatar = undefined;
            this._subscription = {
              name: "Basic",
            };
            this.ult = undefined;
            this.token = undefined;
            this._refreshTokenPromise = undefined;

            if(!isSSR && !isLoggedIn)
              window.sessionStorage.clear();
          }
        }
      )
    );

    // Van ingelogde gebruiker de taal opslaan
    this.disposers.push(
      reaction(
        () => translationStore.language,
        (language) => {
          if(this.isLoggedIn && !isSSR)
          {
            this.FetchJSON(`${process.env.API_URL}/member/me/language`, {
              method: "POST",
              data: {
                language,
              }
            });
          }
        }
      )
    );

    this.disposers.push(
      reaction(
        () => toJS(this.preferences),
        () => {
          if(this.autoSavePreferences)
            this.savePreferences();
        }
      )
    );
  }

  get token() {
    return this._token;
  }

  set token(token: Token) {
    this._token = token;

    if(token?.token) {
      const decodedToken:any = jwtDecode(token.token);
      runInAction(() => {
        this.isAdmin = decodedToken.isAdmin;
        this.key = decodedToken.mbrKey;
      });
    }else
    {
      runInAction(() => {
        this.isAdmin = false;
        this.key = undefined;
      });
    }
  }

  get JSON() {
    return {
      isLoggedIn: this.isLoggedIn,
      features: this.features,
      account: this.account,
      preferences: Object.fromEntries(toJS(this.preferences)),
      flags: this.flags,
      avatar: this.avatar,
      subscription: this._subscription,
    }
  }

  get subscription() {
    return {
      ...this._subscription,
      type: this._subscription?.name.toLowerCase(),
    }
  }

  get firstName()
  {
    return this.account?.firstName;
  }

  get lastName()
  {
    return this.account?.lastName;
  }

  get fullName()
  {
    return [this.account.firstName, this.account.lastName].join(" ");
  }

  refreshToken() {
    if(this._refreshTokenPromise)
      return this._refreshTokenPromise;
    
    const refreshEndpoint = process.env.API_URL + "/member/token";
    const config: any = {};
    if(this.ult)
    {
      config.data = {};
      config.data.refreshToken = this.ult;
    }
    else
      config.withCredentials = true;
    
    this._refreshTokenPromise = new Promise((resolve, reject) => {
      const [promise] = FetchJSON(refreshEndpoint, {
        method: "POST",
        ...config,
      });
      
      promise.then(({data}) => {
        this.token = data;
        resolve(data);
      })
      .catch(e => {
        reject(e);
      })
      .finally(() => {
        this._refreshTokenPromise = undefined;
      });
    });

    return this._refreshTokenPromise;
  }

  fetch(url: string, config:FetchConfig={}):Promise<any> {

    // Als we geen token hebben, proberen we een nieuwe te krijgen
    if(!this.token?.token && this.isLoggedIn) {
      return new Promise((resolve, reject) => {
        this.refreshToken()
        .then(() => {
          this.fetch(url, config)
          .then(resolve)
          .catch(reject);
        })
        .catch(() => {
          console.error(url, 'refreshToken failed');
          reject('refreshToken failed');
        })
      });
    }

    const FetchConfig:any = Object.assign({
      headers: {},
    }, config);

    if(config.legacy)
      FetchConfig.headers['MAAUTHORIZATION'] = this.token?.token ? `Bearer ${this.token.token}` : `ClientToken V5BkhO7GlUH#CjgXcxC74frqlK_vy8cp`;
    else
      FetchConfig.token = this.token;

    return FetchJSON(url, {
      ...FetchConfig,
      onTokenExpired: () => {
        return this.refreshToken();
      }
    })[0];
  }
  
  FetchJSON(url: string, config:FetchJSONConfig={}):[
    Promise<any>,
    () => void,
  ] {
    // Als we geen token hebben, proberen we een nieuwe te krijgen
    if(!this.token?.token && this.isLoggedIn) {
      const abortController = new AbortController();
      const cancel = () => {
        abortController.abort();
      };

      const promise = new Promise((resolve, reject) => {
        this.refreshToken()
        .then(() => {
          const [p] = this.FetchJSON(url, config);
          p
          .then(resolve)
          .catch(reject);
        })
        .catch(() => {
          console.error(url, 'refreshToken failed');
          reject('refreshToken failed');
        })
      });

      return [promise, cancel];
    }

    const FetchConfig:any = Object.assign({
      headers: {},
    }, config);

    if(config.legacy)
      FetchConfig.headers['MAAUTHORIZATION'] = this.token?.token ? `Bearer ${this.token.token}` : `ClientToken V5BkhO7GlUH#CjgXcxC74frqlK_vy8cp`;
    else
      FetchConfig.token = this.token;

    return FetchJSON(url, {
      ...FetchConfig,
      onTokenExpired: () => {
        return this.refreshToken();
      }
    });
  }

  savePreferences()
  {
    const preferences = Object.fromEntries(this.preferences);
    window.sessionStorage.setItem("preferences", JSON.stringify(preferences));

    if(this.isLoggedIn)
    {
      const [promise] = this.FetchJSON(`${process.env.API_URL}/member/me/preferences`, {
        method: "POST",
        data: preferences,
      });
      promise.catch(() => {})
    }
  }

  setPreference(key:string, value:number|string|boolean)
  {
    this.preferences.set(key, value);
  }

  updateMe()
  {
    return new Promise<void>((resolve, reject) =>
    {
      const [mePromise] = this.FetchJSON(process.env.API_URL + "/member/me");
      const [featurePromise] = this.FetchJSON(process.env.API_URL + "/member/features");

      Promise.all([mePromise, featurePromise])
      .then(([meResponse, featureResponse]) => {
        this.autoSavePreferences = false;

        runInAction(() => {
          transaction(() => {
            this.account = meResponse.data.account;
            this.preferences.replace(Object.assign({}, defaultPreferences, meResponse.data.preferences || {}));
            this.flags = meResponse.data.flags;
            this.avatar = meResponse.data.avatar;
            this._subscription = meResponse.data.subscription;

            this.features = featureResponse.data;
          });
        });

        this.autoSavePreferences = true;
        resolve();
      })
      .catch((e) => {
        console.error(e);
        reject();
      });
    })
  }

  login(type: "myalbum"|"facebook"|"google"|"apple", params: any)
  {    
    return new Promise<Record<string, string>>((resolve, reject) =>
    {
      let promise = null;
      switch(type)
      {
        case 'myalbum':
          promise = this.myalbumLogin(params);
        break;
        case 'facebook':
          promise = this.facebookLogin(params);
        break;
        case 'google':
          promise = this.googleLogin(params);
        break;
        case 'apple':
          promise = this.appleLogin(params);
        break;
        default:
          console.warn("Invalid login type", type);
      }

      promise.then((data: Record<string, string>) =>
      {
        this.refreshToken()
        .then(() => {
          this.updateMe()
          .then(() => {
            runInAction(() => {
              this.isLoggedIn = true;
            });

            resolve(data);
          })
          .catch(() => {
            runInAction(() => {
              this.isLoggedIn = false;
            });

            reject(data)
          });
        })
        .catch(e => {
          if(isDev && e.statusCode === 404) {
            console.warn("Failed to refresh token, is the api running?");
          }

          reject(e);
        });
      }).catch(reject);
    });
  }

  requestPassword(email)
  {
    return this.securePost("requestPassword", "/member/requestpassword", {email});
  }

  register(type, params)
  {
    return new Promise<void>((resolve, reject) =>
    {
      let promise = null;

      switch(type)
      {
        case 'myalbum':
          promise = this.myalbumRegister(params);
        break;
        case 'apple':
          promise = this.appleRegister(params);
        break;
      }

      promise.then(() =>
      {
        this.refreshToken()
        .then(() => {
          this.updateMe()
          .then(() => {
            runInAction(() => {
              this.isLoggedIn = true;
            });

            resolve();
          })
          .catch(reject)
        });
      }).catch(reject);
    })
  }

  logout()
  {
    this.securePost("logout", "/member/logout")
    .then(() =>
    {
      runInAction(() => {
        this.isLoggedIn = false;
      });
    }).catch(()=>{});
  }

  myalbumLogin(params)
  {
    return new Promise<void>((resolve, reject) =>
    {
      const [email, password] = params;
      this.securePost("login", "/member/login",
      {
        email,
        password,
        remember: "1"
      })
      .then(response =>
      {
        if(response.status)
          return resolve();
        else
          reject(response.errors);
      })
      .catch(reject);
    })
  }

  myalbumRegister(params: Array<any>)
  {
    return new Promise<void>((resolve, reject) =>
    {
      const [email, password, firstname, lastname] = params;

      this.securePost("register", "/member/register",
      {
        email,
        password,
        firstname,
        lastname
      })
      .then(response =>
      {
        if(response.status)
          return resolve();
        else
          reject(response.errors);
      })
      .catch(reject);
    });
  }

  facebookLogin(token: string)
  {
    return new Promise<void>((resolve, reject) =>
    {
      this.securePost("login", "/member/login?facebook",
      {
        token
      })
      .then(response =>
      {
        if(response.status)
          resolve();
        else
          reject(response.error);
      })
      .catch(reject);
    });
  }

  googleLogin(code: string)
  {
    return new Promise<void>((resolve, reject) =>
    {
      this.securePost("login", "/member/login?google",
      {
        code
      })
      .then(response =>
      {
        if(response.status)
          resolve();
        else
          reject(response.error);
      })
      .catch(reject);
    });
  }

  appleLogin(params: Array<any>)
  {
    return new Promise((resolve, reject) =>
    {
      const [token, user] = params;

      this.securePost("login", "/member/login?apple",
      {
        token,
        firstname: user ? user.name.firstName : "",
        lastname: user ? user.name.lastName : "",
      })
      .then(response =>
      {
        if(response.status && response.accountFound) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(e => {
        console.log({e});
        reject(e);
      });
    });
  }

  appleRegister(params: any)
  {
    return new Promise<void>((resolve, reject) =>
    {
      const {token} = params;
      
      const fd = new FormData();
      fd.append("token", token);

      fetch("/member/register/apple", {
        method: "POST",
        body: fd
      })
      .then(async response =>
      {
        const text = await response.text();

        try
        {
          const json = JSON.parse(text);

          if(json.status)
          {
            this.updateMe()
            .then(() => {
              runInAction(() => {
                this.isLoggedIn = true;
              });

              resolve();
            })
            .catch(reject);
          }
          else
            reject();
        }
        catch(e)
        {
          reject();
        }
      });
    });
  }

  securePost(type: string, url: string, params: Record<string, string> = {})
  {
    return new Promise<any>((resolve, reject) =>
    {
      fetch("/member/formkey?type=" + type)
      .then(res => res.json())
      .then(response =>
      {
        const data = new FormData();
        data.append("formkey", response.key);
        Object.entries(params).forEach(([key, value]) =>
        {
          data.append(key, value);
        });

        fetch(url, {
          method: "POST",
          body: data
        })
        .then(res => res.json())
        .then(resolve)
        .catch(reject);
      })
      .catch(reject);
    });
  }
}

export default UserStore;