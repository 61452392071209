import { action, computed, observable, makeObservable } from "mobx";
import { propsToModel } from "utils/models";
import { removeEmptyValues } from "utils/Utils";
import AddressModel from "./address-model";
import ProductModel from "./product-model";

export default class CartModel
{
  key = "";
  currency = "";
  products = [];
  discount = {};
  pricing = {};
  address = {
    shipping: new AddressModel(),
    invoice: new AddressModel()
  };
  email = "";
  reference = "";
  shippingMethod = "";
  shippingOptions = [];

  errorFields = [];
  savedData = "";

  constructor(data)
  {
    makeObservable(this, {
      key: observable,
      currency: observable,
      products: observable,
      discount: observable,
      pricing: observable,
      address: observable,
      email: observable,
      reference: observable,
      shippingMethod: observable,
      shippingOptions: observable,

      hydrate: action,
      addProduct: action,

      productCount: computed,

      errorFields: observable,
    });

    if(data)
      this.hydrate(data);
  }

  hydrate(data)
  {
    const { address, products, ...props } = data;

    propsToModel(props, this);

    if(products)
    {
      this.products = [];
      products.forEach(product =>
      {
        this.addProduct(product);
      })
    }

    if(address)
    {
      if(address.shipping)
        this.address.shipping.setData(address.shipping);
      if(address.invoice)
        this.address.invoice.setData(address.invoice);
    }
  }

  get productCount()
  {
    return this.products.reduce((count, product) =>
    {
      return count + product.quantity
    }, 0);
  }

  addProduct(product)
  {
    this.products.push(new ProductModel(product, this));
  }

  removeProduct(product)
  {
    const index = this.products.findIndex(p => p === product);
    this.products.splice(index, 1);
  }

  getData()
  {
    const data = {
      key: this.key,
      currency: this.currency,
      products: this.products.map(product => product.getData()),
      reference: this.reference,
    };

    if(this.email !== "")
      data['email'] = this.email;

    if(Object.keys(this.discount).length > 0)
      data['discount'] = this.discount;

    if(this.shippingMethod.length)
      data['shippingMethod'] = this.shippingMethod;

    if(this.address.shipping.isFilled || this.address.invoice.isFilled)
      data['address'] = {};

    if(this.address.shipping.isFilled)
      data.address['shipping'] = removeEmptyValues(this.address.shipping.getData());
    if(this.address.invoice.isFilled)
      data.address['invoice'] = removeEmptyValues(this.address.invoice.getData());

    return data;
  }

  getPricing()
  {
    let pricing = Object.entries(this.pricing).map(([key, value]) =>
    {
      let name = key;
      if(key === "shipping")
      {
        if(this.shippingMethod)
          name = `shipping.${this.shippingMethod}`;
        else
          name = `shipping.normal`;
      }

      return [
        name, value
      ]
    }).filter(x=>x);

    return pricing;
  }
}