import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { useApp } from "stores/app/context";
import { containerStyle, componentStyle } from "./style";

export function ToastContainer()
{
  const { toast } = useApp();

  return (
    <Observer>
    {
      () =>
      {
        if(toast.toasts.length > 0)
        {
          return ReactDOM.createPortal(
            <div className="ui-toast-container">
              {
                toast.toasts.map(toast =>
                {
                  return <Toast key={toast.key} toast={toast} />;
                })
              }
              <style jsx>{containerStyle}</style>
            </div>,
            document.body
          );
        }

        return null;
      }
    }
    </Observer>
  );
}

const Toast = (props) =>
{
  const { toast } = props;

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    requestAnimationFrame(() =>
    {
      setVisible(true);
    })
  }, [])

  const classes = ["ui-toast", toast.className];
  if(visible)
    classes.push("visible");
  if(toast.props.onClick!==undefined)
    classes.push("click");

  return (
    <div
      className={classes.join(" ")}
      onMouseEnter={() => toast.pauseTimer()}
      onMouseLeave={() => toast.resumeTimer()}
    >
      <div className="ui-toast-content" onClick={toast.props.onClick}>
        {toast.icon && <FontAwesomeIcon icon={toast.icon} />}
        {toast.message}
      </div>
      <div className="buttons">
        {
          toast.buttons.map(({text, icon, className, onClick, ...rest}, index) =>
          {
            return <button onClick={() => onClick(toast.close.bind(toast))} className={["ui-toast-button", className].join(" ")} key={toast.key + index} {...rest}><span>{text}</span>{icon && <FontAwesomeIcon icon={icon} fixedWidth />}</button>
          })
        }
        {
          toast.props.closeButton &&
          <button className="ui-toast-button ui-toast-close-button" onClick={() => toast.close()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        }
      </div>
      <style jsx>{componentStyle}</style>
    </div>
  );
}