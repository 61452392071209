import dynamic from "next/dynamic";
import FixedLoader from 'components/FixedLoader';
import { useEffect, useState } from "react";
import { useUser } from "utils/user/lib";

function FolderMoreModal(props) {
  const user = useUser();
  const [folder, setFolder] = useState();
  const [error, setError] = useState();

  useEffect(() => {

    const promises = [];
    promises.push(user.FetchJSON(`${process.env.API_URL}/access/folder-${props.folder}/accessData`)[0]);
    promises.push(user.FetchJSON(`${process.env.API_URL}/folder/${props.folder}/me`)[0]);
    promises.push(user.FetchJSON(`${process.env.API_URL}/folder/${props.folder}/`)[0]);

    Promise.all(promises)
    .then(([access, me, folderData]) => {      
      setFolder({
        key: props.folder,
        type: me.data.bookmark ? 'bookmark' : 'folder',
        title: access.data.subject.title,
        name: access.data.subject.title,
        isOwner: me.data.role === "owner",
        me: me.data,
        settings: folderData.data.settings,
        access: access.data,
        delete: () => {
          const [promise] = user.FetchJSON(folderData.data.links.delete, {method: "DELETE"});

          return promise;
        },
        updateName: (name) => {
          // Update state to server
          const [promise] = user.FetchJSON(folderData.data.links.update, {
            method: "POST",
            data: {
              name
            }
          });

          promise.then(() => {
            // Update internal state
            setFolder(f => {
              return {
                ...f,
                name,
                title: name,
              }
            });

            // Update external state
            props.onRename(name);
          })
        },
        updateSetting: (key, value) => {
          const settings = {};
          settings[key] = value;

          // Update state to server
          const [promise] = user.FetchJSON(folderData.data.links.update, {
            method: "POST",
            data: {
              settings
            }
          });

          promise.then(() => {
            // Update internal state
            setFolder(f => {
              return {
                ...f,
                ...{settings}
              }
            });

            // Update external state
            props.onUpdateSetting(key, value);
          });
        },
      });
    })
    .catch(e => {
      console.error(e);
      setError(e);
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(error)
  {
    return null;
  }else if(!folder)
  {
    return (<FixedLoader />);
  }else
  {    
    return <FolderMoreModalContent {...props} folder={folder} />
  }
}

FolderMoreModal.defaultProps = {
  onRename: () => {},
  onUpdateSetting: () => {}
};

const FolderMoreModalContent = dynamic(() => import("./Modal"), {
  ssr: false,
  loading: () => <FixedLoader />,
});

export default FolderMoreModal;