import Modal from "./Modal.js"
import ButtonModal from "./ButtonModal"
import translationStore from 'stores/translation-store'

function Alert(options) {

  return new Promise((resolve, reject) =>
  {
    options.buttons = [{
      className: "filled",
      text: options.okButtonText || translationStore.translate("base.ok"),
      default: true,
      onClick: () => resolve()
    }]

    const modal = <ButtonModal
      stack={true}
      allowClose={false}
      onCancel={reject}
      onClose={() => {
        Modal.remove(modal);
        resolve();
      }}
      modalStyle={{width: 500}}
      {...options}
    />;

    Modal.add(modal);
  });
}

export default Alert;