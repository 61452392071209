
import { createContext, useContext, useRef } from "react";
import ShopStore from "./store";

export const ShopContext = createContext();

export function ShopProvider({ children, cart })
{
  const store = useRef(new ShopStore(cart)).current;

  return (
    <ShopContext.Provider value={store}>
      {children}
    </ShopContext.Provider>
  );
}

/**
 * @returns {ShopStore}
 */
export function useShop()
{
  const store = useContext(ShopContext);
  if(store === undefined)
    throw new Error("useShop must be used within a ShopProvider");

  return store;
}

export async function getCartFromContextAndUser(context, user)
{
  try
  {
    const Cookies = eval("require('cookies')");
    const cookies = new Cookies(context.req, context.res);
    const shoppingcart = cookies.get('shoppingcart');

    return (await user.FetchJSON(`${process.env.API_URL}/shop/cart/${shoppingcart || ""}`)[0]).data;
  }
  catch(e)
  {
    return null;
  }
}