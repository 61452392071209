import { observable, makeObservable, action } from "mobx";
import { v4 as uuidv4 } from 'uuid'
const isSSR = (typeof window === "undefined")

class ModalStore
{
  stack = [];
  stackChanges = 0;

  constructor() {
    makeObservable(this, {
      stackChanges: observable,

      add: action,
      remove: action,
      removeByKey: action,
    });

    if(!isSSR) {
      document.body.addEventListener('keydown', this.onKeyPress);
    }
  }

  onKeyPress = (e) => {
    if(this.stack.length===0)
      return;

    if(e.target===document.body)
    {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    const topModal = this.topModal;
    const props = topModal.modal.props;
    if(props.allowClose!==false && !props.floating)
    {
      if(e.key === "Escape")
      {
        const ignoreNodes = ['input', 'textarea'];
        if(ignoreNodes.includes(e.target.nodeName.toLowerCase()))
        {
          // esc op deze nodes ignoren we, kans is groot dat er een autocomplete
          // of password manager in de weg stond die met esc weggehaald moest worden
          // we doen een blur, bij een volgende esc kan deze modal dan wel gesloten worden
          document.activeElement.blur();
        }else
        {
          this.requestClose();
        }
      }
    }

    if(props.onKey!==undefined)
      props.onKey(e);
  }

  requestClose(data) {
    if(this.stack.length===0)
      return;

    if(!isSSR) {
      document.activeElement.blur();
    }

    const topModal = this.topModal;
    const props = topModal.modal.props;
    if(props.onClose!==undefined)
      props.onClose(data);
  }

  add(modal, key=uuidv4())
  {
    this.stack.push({key, modal});
    this.stackChanges++;
  }

  remove(modal)
  {
    this.stack = this.stack.filter(item => {
      if(item.modal!==modal && item.key!==modal)
        return item;
    })

    this.stackChanges++;
  }

  removeByKey(key)
  {
    this.stack = this.stack.filter(item => {
      if(item.key!==key)
        return item;
    })

    this.stackChanges++;
  }

  onBackgroundClick = () => {
    const topModal = this.topModal;
    const props = topModal.modal.props;

    if(props.allowClose!==false && props.floating!==true) {
      this.requestClose();
    }
  }

  get visibleModals()
  {
    const backdropStyle = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      zIndex: 9999998,
      backgroundColor: 'rgba(0,0,0, 0.15)',
    }

    const returnValue = [];
    this.stack.map((item, index) => {
      const isTopMostModal = this.stack.length-1===index;
      const hasModalAfter = this.stack.length>1 && this.stack.length-2===index && this.stack[this.stack.length-1].modal.props.stack;

      if(item.modal.props.stack || isTopMostModal || hasModalAfter)
      {
        if(isTopMostModal)
        {
          if(item.modal.props.showBackdrop===false) {
            backdropStyle.backgroundColor = 'rgba(0,0,0,0)';
          }

          if(item.modal.props.disableBackdrop===true) {
            backdropStyle.pointerEvents = "none";
            backdropStyle.backgroundColor = 'rgba(0,0,0,0)';
          }

          returnValue.push(<div style={backdropStyle} key="backdrop" onClick={this.onBackgroundClick}></div>);
        }

        returnValue.push(
          <div key={item.key}>{item.modal}</div>
        );
      }
    });

    return returnValue;
  }

  get topModal() {
    if(this.stack.length===0)
      return null;

    return this.stack[this.stack.length-1];
  }

  isTopModal(modal)
  {
    return (modal === this.topModal);
  }
}


const ModalStoreInstance = new ModalStore();
export default ModalStoreInstance;