import { forwardRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'utils/Color';
import { useAvatars } from 'stores/avatar-store';

type AvatarComponentProps = {
  initials?: string;
  color?: string;
  image?: string | {url: string};
  images?: {sizes: {url: string, width: number, height: number}[]};
  icon?: any;
}

type AvatarProps = {
  style?: React.CSSProperties;
  size?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const size = 60;

const Avatar = forwardRef<HTMLDivElement, AvatarProps & AvatarComponentProps>((props, forwardedRef) =>
{
  let { color } = props;
  const rgbaColor = Color.toRgba(color);

  const initials = props.initials ? props.initials : null;
  const isDark = Color.isDark(rgbaColor);
  const style = {
    color: isDark ? "white" : "black",
    ...props.style
  }

  let imageUrl: string;
  if(props.image && typeof props.image !== "string")
  {
    imageUrl = typeof props.image === "string" ? props.image : props.image.url;
  } else if(props.images && props.images.sizes && Array.isArray(props.images.sizes)) {
    const pixelRatio = (typeof window !== "undefined") ? window.devicePixelRatio : 1;
    let bestSize = props.images.sizes[0];
    props.images.sizes.forEach(image =>
    {
      if(
        (image.width * pixelRatio >= props.size && image.height * pixelRatio >= props.size) &&
        (image.width < bestSize.width || image.height < bestSize.height)
      )
      {
        bestSize = image;
      }
    })

    if(bestSize)
      imageUrl = bestSize.url;
  }

  const backgroundStyle = props.image || props.images ? {border: 0} : {backgroundColor: `rgb(${rgbaColor.join(", ")})`};

  return (
    <div
      ref={forwardedRef}
      className="avatar"
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
        borderRadius: `${props.size}px`,
        ...style,
        ...backgroundStyle
      }}

      data-tip={props['data-tip']}
      data-tip-delay={props['data-tip-delay']}
      data-tip-align={props['data-tip-align']}
      data-tip-design={props['data-tip-design']}

      onClick={props.onClick}
    >
      {imageUrl ? <AvatarImage image={imageUrl} size={props.size} /> : initials}
      {props.icon ? <FontAwesomeIcon icon={props.icon} color={style.color} style={{fontSize: 18}} /> : null}

      <style jsx>{`
        .avatar {
          overflow: hidden;
          display: flex;
          flex-shrink: 0;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: black;
          user-select: none;
          -webkit-user-select: none;
          cursor: default;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing:grayscale;
          font-weight: lighter;
          font-size: ${props.size / 2.5}px;
        }
      `}</style>
    </div>
  )
});

type AvatarMemberProps = AvatarProps & {
  memberKey: string;
}

export const AvatarMember = forwardRef<HTMLDivElement, AvatarMemberProps>(({memberKey, ...props}, forwardedRef) => {
  const [mounted, setMounted] = useState(false);
  const avatarStore = useAvatars();

  const isAvatarDataLoaded = avatarStore.isLoaded(memberKey);
  
  const initialAvatarData = {
    initials: "",
    color: "#ffffff",
    style: {
      boxSizing: 'border-box',
    },
    ...(isAvatarDataLoaded ? avatarStore.getAvatar(memberKey) : {}),
  }

  const [avatarData, setAvatarData] = useState(initialAvatarData);

  useEffect(() => {
    setMounted(true);
    
    return () => {
      setMounted(false);
    }
  }, []);

  useEffect(() => {
    if(mounted && !isAvatarDataLoaded) {
      const avatar = avatarStore.loadAvatarFromMemberKey(memberKey);
      avatar.then(avatar => setAvatarData({
        ...avatarData,
        ...avatar
      }));
    }
  }, [memberKey, mounted, isAvatarDataLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (<Avatar {...avatarData} {...props} ref={forwardedRef} />);
});
AvatarMember.displayName = "AvatarMember";

const AvatarImage = props =>
{
  return (
    <>
      <div
        className="avatar-image"
        style={{
          backgroundImage: `url(${props.image})`,
          width: `${props.size}px`,
          height: `${props.size}px`,
          borderRadius: `${props.size}px`,
        }}
      />
      <style jsx>{`
        .avatar-image
        {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          flex-shrink: 0;
        }
      `}</style>
    </>
  );
}

Avatar.displayName = "Avatar";
Avatar.defaultProps = {
  size: size
}

AvatarImage.displayName = "AvatarImage";
AvatarImage.defaultProps = {
  size: size
}

export default Avatar;